import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../../network/networkHandler.types";
import { CodReconciliationState } from "./reconciliationSlice.types";
import AppConstants from "../../../constants";
import ReconciliationConstants from "../../../constants/CodReconciliationConstants";
import { transformDateWithEmptyValues, transformDates, enumerateDaysBetweenDates } from "../../../utils/helpers.utils";

export const fetchCodReconciliation = createAsyncThunk(
  "Dashboard/FetchCODReconciliation",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "reconcile/dashboard",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;

      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState: CodReconciliationState = {
  loading: false,
  codReconciliationCount: {
    pendingAmount: 0,
    collectedAmount: 0,
    expectedAmount: 0,
    shortage: 0,
    handedOver: 0,
    excess: 0,
  },
  amountsByPlannedDate: {},
  formattedDates: {},
  filteredDays: {},
  error: "",
};

const reconciliationSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchCodReconciliation.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchCodReconciliation.fulfilled, (state, action) => {
        const { payload } = action;
        const { meta: { arg: { params } } } = action;
        const allDates = enumerateDaysBetweenDates(params.startDate, params.endDate, AppConstants.DATE_FORMAT_BACKEND);
        state.errorCode = "";
        state.error = "";
        state.loading = false;
        state.codReconciliationCount = payload.totalAmounts;
        state.amountsByPlannedDate = payload.amountsByPlannedDate || {};
        state.filteredDays = transformDateWithEmptyValues(ReconciliationConstants.RECONCILIATION_CONSTANTS.AMOUNT_VALUES.Amount, payload.amountsByPlannedDate, allDates);
        state.formattedDates = transformDates(allDates);
      })
      .addCase(fetchCodReconciliation.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      });
  },
});

export default reconciliationSlice.reducer;
