import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import { Box, Button, Card, Grid, Typography } from "@material-ui/core";

import AppConstants from "../../constants";
import Loader from "../../common/Loader";
import CustomAllRoutesLeftSection from "./common/CustomAllRoutesLeftSection";
import CustomSnackbar from "common/CustomSnackbar";
import AssignDriverDialog from "./common/AssignDriverDialog";
import CustomDialog from "../../common/Dialog";
import MapContainer from "../../common/GoogleMapContainer";
import AssignRouteToOrdersDialog from "./common/AssignRouteToOrdersDialog";
import CustomTooltip from "../../common/Tooltip";
import RenderSubrouteAssignDriverDialog from "./common/SubRoute/RenderSubrouteAssignDriverDialog";

import { createDraftRoutePayload, createBulkDeleteRoutePayload, ROUTE_TYPE } from "../../mocks/routePlanning/response.transforms";
import { useStyles } from "./AllRoutes.styles";
import { createNewSubRoute, createRemoveOrderFromRoute, fetchAllPlannedRoutes, fetchAllUnPlannedRoutes, fetchExistingRouteToAssignToOrder } from "./redux/allRoutesSlice";
import { API_RESPONSE_STATUS } from "../../constants/CommonConstants";
import { RenderMapMarkerTitleValue } from "./utils/AllRouteViewUtils";
import { isNonEmptyArray, isNonEmptyObject, isNull, isObject, isUndefined, loadDashIsArrayAndNotEmpty } from "../../utils/helpers.utils";
import { ALL_ROUTES_TEXT_CONTANT, ALL_ROUTE_INITIAL_STATE, ALL_ROUTE_INITIAL_STATE_INTERFACE, BUTTONS_TEXT, MAP_MARKER_INTERFACE, SNACKBAR_MESSAGES, mapOptionStyles } from "./utils/AllRouteConstant";
import { AppState } from "../../config/redux/reducers";
import {
  createAndEditRoute,
  fetchAllDrivers,
  fetchAllHelpers,
  fetchAllVehicles,
  publishRoutes,
  createDraftRoute,
  loadAllSlots,
  updateConsignmentStatus,
  bulkDeleteRoute,
  resetCreateRouteSuccess,
} from "../redux/planningSlice";
import {
  designAllRoutesStateObjectUtils,
  designAllUnPlannedRoutesStateObjectUtils,
  getAllAreaTitle,
  getConsignmentRecordUtils,
  handleAreaCheckboxToggleUtils,
  handleConsignmentSearchUtils,
  handleExpandAllRecordClickMethodUtils,
  handleOpenCloseUnplannedRouteSlotUtils,
  handleOrderAccordianToggleUtils,
  handleOrderCheckboxToggleUtils,
  handleRoutAreaAccordianToggleUtils,
  handleRoutAreaSubareaAccordianToggleUtils,
  handleRouteAccordianClickedUtils,
  handleRouteCheckboxToggleUtils,
  handleRouteSlotAccordianToggleUtils,
  handleSelectAllRoutesUtils,
  handleSlotCheckboxToggleUtils,
  handleSubRouteSlotAccordianToggleUtils,
  handleSubrouteOrderAccordianToggleUtils,
  handleToggleCreateSubrouteUtils,
  handleUnPlannedAccordianToggleUtils,
  handleUnplannedAreaCheckboxToggleUtils,
  handleUnplannedInnerOrderAccordianToggleUtils,
  handleUnplannedOrderCheckboxToggleUtils,
  handleUnplannedSlotCheckboxToggleUtils,
  isActionButtonVisibleUtils,
  isAllRoutesAssignedWithDriverUtils,
  orderActionPopUpViewChangeUtils,
  removeOrderFromRouteUtils,
  selectRouteToAssigntoOrderUtils,
  updatAavailableRouteRecordUtils,
  updatAvailableRouteRecordForUnplannedOrdersUtils,
  updateRouteObjectAfterUpdateUtils,
  updateSubRouteObjectAfterUpdateUtils,
  validatePlannedRouteInfoUtils,
  validatePlannedSubRouteInfoUtils,
} from "./utils/AllRouteUtils";
import {
  createAddOrderToAnotherRoutePayload,
  createAddOrdersToRoutesPayload,
  createDraftUnplannedRoutePayload,
  createFetchRemoveOrderFromRoutesPayload,
  createGetAllDropdownsPayload,
  createGetAllPlannedRoutesPayload,
  createGetAllUnPlannedRoutesPayload,
  createNewRouteProceedPayload,
  createNewSubRouteRequestPayload,
  createPublishRoutesPayload,
  createSubRoutesUpdatePayload,
  createUpdateRoutesPayload,
  mapUnplannedOrderToDraftedRoutePayload,
} from "../../mocks/allRoutes/response.transforms";

const AllRoutes = (props: any) => {
  const { hubId, hubName, viewPublishRoute, handleChangeAllRoutesHub } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { countryCode } = useSelector((state: AppState) => state.common);
  const { driversList, helpersList, vehiclesList, publishedRoutes, slotsList, zoneList } = useSelector((state: AppState) => state.routePlanning);
  const { plannedRoutes, unPlannedRoutes, error, fetchStatus, orderStatuses } = useSelector((state: AppState) => state.allRoutes);
  const { routeConfigDetails } = useSelector((state: AppState) => state.common);

  const [showLoader, setShowLoader] = useState(false);
  const [allRouteState, setAllRouteState] = useState<ALL_ROUTE_INITIAL_STATE_INTERFACE>(ALL_ROUTE_INITIAL_STATE);

  const publishDialogPaperProps = { classes: { root: classes.publishDialogPaperPropsRoot } };
  const filterChangeRef = useRef<boolean>(false);

  const initializeRoute = (params: any) => {
    if (filterChangeRef.current) {
      handleCreateDraftRoute({ slots: params.slotValue, statusValue: params.statusValue, zoneValue: params.zoneValue });
    } else if (allRouteState.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED) {
      getAllSlots({ date: params.date, slotValue: params.slotValue, hubCode: hubId, hubName: hubName[0]?.name, statusValue: params.statusValue, zoneValue: params?.zoneValue });
    }
  };

  const getAllSlots = (params: any) => {
    const payload = createDraftRoutePayload(params);
    dispatch(loadAllSlots({ params: payload }));
  };

  const handleCreateDraftRoute = (params: any) => {
    const payload = createDraftRoutePayload({
      date: allRouteState.selectedDate,
      slotValue: params.slots,
      hubCode: hubId,
      hubName: hubName[0]?.name,
      statusValue: params.statusValue,
      zoneValue: params?.zoneValue,
    });
    dispatch(createDraftRoute({ params: payload }));
  };

  const updateAndResetFilterForRouteState = (name: any, value: any) => {
    setAllRouteState({
      ...allRouteState,
      loading: true,
      plannedConsignmentsData: {},
      unplannedConsignmentsData: {},
      selectedConsignments: [],
      statusValue: [],
      slotValue: [],
      zoneValue: [],
      showDriverAssignedPopup: false,
      driverValue: AppConstants.SELECT_NONE.value,
      helperValue: AppConstants.SELECT_NONE.value,
      vehicleValue: AppConstants.SELECT_NONE.value,
      firstMapCordinates: { lat: 0, lng: 0 },
      mapCenter: { lat: 0, lng: 0 },
      openAssignRoutePopUp: false,
      selectedConsignmentToChangeRoute: [],
      availableRouteRecord: [],
      mapPolylineMarker: [],
      polylineColor: "",
      consignmentId: "",
      [name]: value,
    });
  };

  const updateAndResetAllRouteState = (name: any, value: any) => {
    setAllRouteState({
      ...allRouteState,
      loading: true,
      expandAll: false,
      plannedConsignmentsData: {},
      unplannedConsignmentsData: {},
      selectedConsignments: [],
      selectedRoute: {},
      showDriverAssignedPopup: false,
      openUpdateConfirmDialog: false,
      driverValue: AppConstants.SELECT_NONE.value,
      helperValue: AppConstants.SELECT_NONE.value,
      vehicleValue: AppConstants.SELECT_NONE.value,
      consignmentMarkers: [],
      firstMapCordinates: { lat: 0, lng: 0 },
      mapCenter: { lat: 0, lng: 0 },
      mapZoom: 12,
      openPublishDialog: false,
      openAssignRoutePopUp: false,
      selectedConsignmentToChangeRoute: [],
      availableRouteRecord: [],
      selectedRouteObj: {},
      orderDataObj: {},
      openAddRouteConfirmDialog: false,
      openRemoveRouteConfirmDialog: false,
      mapPolylineMarker: [],
      polylineColor: "",
      consignmentId: "",
      [name]: value,
    });
  };

  const handleStatusChange = (value: any) => {
    filterChangeRef.current = true;
    updateAndResetAllRouteState("statusValue", value);
    let allRouteStateCopy = _.cloneDeep(allRouteState);
    allRouteStateCopy.statusValue = value;
    fetchConsignmentValueBasedOnTab(allRouteStateCopy);
    fetchComponentData(allRouteStateCopy);
  };

  const handleSlotChange = (value: any) => {
    filterChangeRef.current = true;
    updateAndResetAllRouteState("slotValue", value);
    let allRouteStateCopy = _.cloneDeep(allRouteState);
    allRouteStateCopy.slotValue = value;
    fetchConsignmentValueBasedOnTab(allRouteStateCopy);
    fetchComponentData(allRouteStateCopy);
  };

  const handleZoneChange = (value: any) => {
    filterChangeRef.current = true;
    updateAndResetAllRouteState("zoneValue", value);
    let allRouteStateCopy = _.cloneDeep(allRouteState);
    allRouteStateCopy.zoneValue = value;
    fetchConsignmentValueBasedOnTab(allRouteStateCopy);
    fetchComponentData(allRouteStateCopy);
  };

  const getAllDrivers = (stateObj: any) => {
    const payload = createGetAllDropdownsPayload(stateObj, hubId);
    dispatch(fetchAllDrivers({ payload: payload }));
  };

  const getAllHelpers = (stateObj: any) => {
    const payload = createGetAllDropdownsPayload(stateObj, hubId);
    dispatch(fetchAllHelpers({ payload: payload }));
  };
  const getAllVehicles = (stateObj: any) => {
    const payload = createGetAllDropdownsPayload(stateObj, hubId);
    dispatch(fetchAllVehicles({ payload: payload }));
  };

  const fetchComponentData = (stateObj: any) => {
    getAllDrivers(stateObj);
    getAllHelpers(stateObj);
    getAllVehicles(stateObj);
  };

  const getAllPlannedRoutes = (stateObj: any) => {
    const payload = createGetAllPlannedRoutesPayload(stateObj, hubId);
    dispatch(fetchAllPlannedRoutes({ payload: payload }));
  };

  const getAllUnPlannedRoutes = (stateObj: any) => {
    const payload = createGetAllUnPlannedRoutesPayload(stateObj, hubId, hubName);
    dispatch(fetchAllUnPlannedRoutes({ payload: payload }));
  };

  const designAllRoutesStateObject = () => {
    if (!isUndefined(plannedRoutes) && !isUndefined(fetchStatus) && fetchStatus !== (API_RESPONSE_STATUS.IDLE || API_RESPONSE_STATUS.LOADING || API_RESPONSE_STATUS.FAILED)) {
      let newAllRouteState = designAllRoutesStateObjectUtils(allRouteState, plannedRoutes, hubId);
      setAllRouteState(newAllRouteState);
    }
  };

  const designAllUnPlannedRoutesStateObject = () => {
    if (!isUndefined(unPlannedRoutes && !isUndefined(fetchStatus) && fetchStatus !== (API_RESPONSE_STATUS.IDLE || API_RESPONSE_STATUS.LOADING || API_RESPONSE_STATUS.FAILED))) {
      let newAllRouteState = designAllUnPlannedRoutesStateObjectUtils(allRouteState, unPlannedRoutes);
      setAllRouteState(newAllRouteState);
    }
  };

  const fetchConsignmentValueBasedOnTab = (stateObj: ALL_ROUTE_INITIAL_STATE_INTERFACE) => {
    switch (stateObj.tabValue) {
      case AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED:
        getAllPlannedRoutes(stateObj);
        return;
      case AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED:
        getAllUnPlannedRoutes(stateObj);
        return;
    }
  };

  const handleDateChange = (dateRange: any) => {
    filterChangeRef.current = false;
    updateAndResetFilterForRouteState("selectedDate", dateRange);
    let allRouteStateCopy = _.cloneDeep(allRouteState);
    allRouteStateCopy.selectedDate = dateRange;
    fetchConsignmentValueBasedOnTab(allRouteStateCopy);
    fetchComponentData(allRouteStateCopy);
  };

  const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    updateAndResetAllRouteState("tabValue", value);
    switch (value) {
      case AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED:
        getAllPlannedRoutes(allRouteState);
        return;
      case AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED:
        getAllSlots({
          date: allRouteState.selectedDate,
          slotValue: allRouteState.slotValue,
          hubCode: hubId,
          hubName: hubName[0]?.name,
          statusValue: allRouteState.statusValue,
          zoneValue: allRouteState?.zoneValue,
        });
        getAllUnPlannedRoutes(allRouteState);
        return;
    }
  };

  const handleRouteAccordianToggle = (event: React.ChangeEvent<HTMLInputElement>, routeName: string) => {
    event.stopPropagation();
    if (routeName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleRouteAccordianClickedUtils(allRouteState, routeName, vehiclesList);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleRouteAccordianClicked = (event: React.ChangeEvent<HTMLInputElement>, routeName: string) => {
    event.stopPropagation();
    handleRouteAccordianToggle(event, routeName);
  };

  const handleRouteSlotAccordianToggle = (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, subRouteAreaName?: string) => {
    event.stopPropagation();
    if (routeName && routeDataName && slotName && subRouteAreaName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleSubRouteSlotAccordianToggleUtils(allRouteState, routeName, routeDataName, slotName, subRouteAreaName);
      setAllRouteState(newAllRouteState);
    } else if (routeName && routeDataName && slotName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleRouteSlotAccordianToggleUtils(allRouteState, routeName, routeDataName, slotName);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleRoutAreaAccordianToggle = (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, subRouteAreaName?: string) => {
    event.stopPropagation();
    if (routeName && routeDataName && subRouteAreaName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleRoutAreaSubareaAccordianToggleUtils(allRouteState, routeName, routeDataName, subRouteAreaName);
      setAllRouteState(newAllRouteState);
    } else if (routeName && routeDataName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleRoutAreaAccordianToggleUtils(allRouteState, routeName, routeDataName, vehiclesList);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleRouteCheckboxToggle = (event: React.ChangeEvent<HTMLInputElement>, routeName: string) => {
    event.stopPropagation();
    if (!isUndefined(event?.target?.checked)) {
      const { checked } = event.target;
      if (routeName) {
        let newAllRouteState = handleRouteCheckboxToggleUtils(allRouteState, routeName, checked, vehiclesList);
        setAllRouteState(newAllRouteState);
      }
    }
  };

  const handleSlotCheckboxToggle = (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string) => {
    event.stopPropagation();
    if (!isUndefined(event?.target?.checked)) {
      const { checked } = event.target;
      if (routeName && routeDataName && slotName) {
        let newAllRouteState = handleSlotCheckboxToggleUtils(allRouteState, checked, routeName, routeDataName, slotName);
        setAllRouteState(newAllRouteState);
      }
    }
  };
  const handleAreaCheckboxToggle = (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string) => {
    event.stopPropagation();
    if (!isUndefined(event?.target?.checked)) {
      const { checked } = event.target;
      if (routeName && routeDataName) {
        let newAllRouteState = handleAreaCheckboxToggleUtils(allRouteState, checked, routeName, routeDataName);
        setAllRouteState(newAllRouteState);
      }
    }
  };
  const handleOrderCheckboxToggle = (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => {
    event.stopPropagation();
    if (!isUndefined(event?.target?.checked)) {
      const { checked } = event.target;
      if (routeName && routeDataName && slotName && !isUndefined(orderObjIndex) && Number(orderObjIndex) >= 0) {
        let newAllRouteState = handleOrderCheckboxToggleUtils(allRouteState, checked, routeName, routeDataName, slotName, orderObjIndex);
        setAllRouteState(newAllRouteState);
      }
    }
  };

  const handleRouteOrderAccordianToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    routeName: string,
    routeDataName: string,
    slotName: string,
    orderObjIndex: number,
    subRouteAreaName?: string
  ) => {
    event.stopPropagation();
    if (routeName && routeDataName && subRouteAreaName && slotName && !isUndefined(orderObjIndex) && Number(orderObjIndex) >= 0) {
      let newAllRouteState = handleSubrouteOrderAccordianToggleUtils(allRouteState, routeName, routeDataName, slotName, orderObjIndex, subRouteAreaName);
      setAllRouteState(newAllRouteState);
    } else if (routeName && routeDataName && slotName && !isUndefined(orderObjIndex) && Number(orderObjIndex) >= 0) {
      let newAllRouteState = handleOrderAccordianToggleUtils(allRouteState, routeName, routeDataName, slotName, orderObjIndex);
      setAllRouteState(newAllRouteState);
    }
  };

  const orderActionPopUpViewChange = (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => {
    event.stopPropagation();
    if (routeName && routeDataName && slotName && !isUndefined(orderObjIndex) && Number(orderObjIndex) >= 0) {
      let newAllRouteState = orderActionPopUpViewChangeUtils(allRouteState, routeName, routeDataName, slotName, orderObjIndex);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleSnackbarClose = () => {
    setAllRouteState({ ...allRouteState, openSnackbar: false, snackbarMessage: "", snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS });
  };

  const handlePublishSnackbarClose = () => {
    setAllRouteState({ ...allRouteState, openPublishRouteSnackBar: false, snackbarPublishMessage: "", snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS });
  };

  const handleInputChange = (value: any, name: string) => {
    switch (name) {
      case "Driver":
        setAllRouteState({ ...allRouteState, driverValue: value });
        return;
      case "Helper":
        setAllRouteState({ ...allRouteState, helperValue: value });
        return;
      case "Vehicle":
        setAllRouteState({ ...allRouteState, vehicleValue: value });
        return;
    }
  };

  const handleSubRouteInputChange = (value: any, name: string) => {
    switch (name) {
      case "Driver":
        setAllRouteState({ ...allRouteState, subDriverValue: value });
        return;
      case "Helper":
        setAllRouteState({ ...allRouteState, subHelperValue: value });
        return;
      case "Vehicle":
        setAllRouteState({ ...allRouteState, subVehicleValue: value });
        return;
    }
  };

  const isAllRoutesAssignedWithDriver = () => {
    return isAllRoutesAssignedWithDriverUtils(allRouteState);
  };

  const isActionButtonVisible = () => {
    return isActionButtonVisibleUtils(allRouteState);
  };

  const updateRouteObjectAfterUpdate = (routeData: any) => {
    const successMessage = `Route ${routeData?.routeId || ""} ${AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.UPDATE_ROUTE_SUCCESS_MSG}`;
    let newAllRouteState = updateRouteObjectAfterUpdateUtils(allRouteState, routeData, successMessage);
    setAllRouteState(newAllRouteState);
    fetchComponentData(newAllRouteState);
  };

  const updateRoute = async (updateRoutePayload: any) => {
    if (updateRoutePayload && isObject(updateRoutePayload) && isNonEmptyObject(updateRoutePayload)) {
      const { payload } = await dispatch(createAndEditRoute({ payload: updateRoutePayload, editMode: false }));
      if (payload !== undefined && payload.message !== undefined) {
        setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: payload.message, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
      } else if (payload !== undefined && payload.route !== undefined && payload.route.routeId !== undefined) {
        dispatch(resetCreateRouteSuccess());
        updateRouteObjectAfterUpdate(payload.route);
      } else {
        setAllRouteState({ ...allRouteState, loading: false });
      }
    } else {
      setAllRouteState({ ...allRouteState, loading: false, selectedRoute: {} });
    }
  };

  const savePlannedRouteInformation = () => {
    setAllRouteState({ ...allRouteState, loading: true });
    const isRouteInformationValid = validatePlannedRouteInfoUtils(allRouteState);
    if (isRouteInformationValid !== undefined && isRouteInformationValid.errorObj !== undefined && typeof isRouteInformationValid.errorObj === "string") {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: isRouteInformationValid.errorObj, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    } else {
      const updateRoutePayload = createUpdateRoutesPayload(allRouteState);
      updateRoute(updateRoutePayload);
    }
  };

  const publishPlannedRouteInformation = async () => {
    const publishPayload = createPublishRoutesPayload(allRouteState);
    if (publishPayload) {
      const response = await dispatch(publishRoutes({ payload: publishPayload }));
      if (!isUndefined(response?.payload?.message)) {
        setAllRouteState({ ...allRouteState, openPublishDialog: false });
      }
    }
  };

  // Unplanned
  const handleUnplannedAccordianToggle = (event: React.ChangeEvent<HTMLInputElement>, areaName: any) => {
    event.stopPropagation();
    if (areaName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleUnPlannedAccordianToggleUtils(allRouteState, areaName);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleUnplannedInnerOrderAccordianToggle = (event: React.ChangeEvent<HTMLInputElement>, areaName: any, slotName: any, orderObjIndex: any) => {
    event.stopPropagation();
    if (areaName && slotName && !isUndefined(orderObjIndex) && Number(orderObjIndex) >= 0) {
      let newAllRouteState = handleUnplannedInnerOrderAccordianToggleUtils(allRouteState, areaName, slotName, orderObjIndex);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleUnplannedAreaCheckboxToggle = (event: React.ChangeEvent<HTMLInputElement>, areaName: any) => {
    event.stopPropagation();
    if (!isUndefined(event) && !isUndefined(event.target) && !isUndefined(event.target.checked) && !isUndefined(areaName)) {
      const { checked } = event.target;
      let newAllRouteState = handleUnplannedAreaCheckboxToggleUtils(allRouteState, areaName, checked);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleUnplannedOrderCheckboxToggle = (event: React.ChangeEvent<HTMLInputElement>, areaName: any, slotName: any, orderObjIndex: any) => {
    event.stopPropagation();
    if (!isUndefined(event) && !isUndefined(event.target) && !isUndefined(event.target.checked)) {
      const { checked } = event.target;
      if (areaName && slotName && !isUndefined(orderObjIndex) && Number(orderObjIndex) >= 0) {
        let newAllRouteState = handleUnplannedOrderCheckboxToggleUtils(allRouteState, areaName, slotName, orderObjIndex, checked);
        setAllRouteState(newAllRouteState);
      }
    }
  };

  const handlePublishDialogOpen = () => {
    setAllRouteState({ ...allRouteState, openPublishDialog: true });
  };

  const handlePublishDialogClose = () => {
    setAllRouteState({ ...allRouteState, openPublishDialog: false });
  };

  const handleUpdateDialogOpen = () => {
    const isRouteInformationValid = validatePlannedRouteInfoUtils(allRouteState);
    if (isRouteInformationValid !== undefined && isRouteInformationValid.errorObj !== undefined && typeof isRouteInformationValid.errorObj === "string") {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: isRouteInformationValid.errorObj, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    } else {
      setAllRouteState({ ...allRouteState, openUpdateConfirmDialog: true });
    }
  };

  const handleUpdateDialogClose = () => {
    setAllRouteState({ ...allRouteState, openUpdateConfirmDialog: false });
  };

  const getUpdatedAssigneList = (type: string, route: any) => {
    let updatedList = [] as any;
    switch (type) {
      case "driver":
        updatedList = [...driversList];
        if (route && route.assignee && route.assignee.id) {
          updatedList = [...updatedList, { name: route.assignee.name, value: route.assignee.id }];
        }
        break;
      case "helper":
        updatedList = [...helpersList];
        if (route && route.helper && route.helper.id) {
          updatedList = [...updatedList, { name: route.helper.name, value: route.helper.id }];
        }
        break;
      case "vehicle":
        updatedList = [...vehiclesList];
        if (route && route.assignedVehicle && route.assignedVehicle.id && updatedList.findIndex((vehicle: any) => vehicle.value === route.assignedVehicle.id) === -1) {
          updatedList = [...updatedList, { name: route.assignedVehicle.name, value: route.assignedVehicle.id }];
        }
        break;
      default:
        break;
    }
    return updatedList;
  };

  const getMapCenter = () => {
    return allRouteState && allRouteState.consignmentMarkers && allRouteState.consignmentMarkers.length ? allRouteState.consignmentMarkers[0].location : { lat: 0, lng: 0 };
  };

  const handleOrderActionDialogClose = () => {
    setAllRouteState({ ...allRouteState, loading: false, selectedRouteObj: {}, orderDataObj: {}, openAddRouteConfirmDialog: false, openRemoveRouteConfirmDialog: false });
  };

  const fetchRemoveOrderFromRoute = async (routeData: any, orderData: any) => {
    const requestPayload: any = createFetchRemoveOrderFromRoutesPayload(routeData, orderData, "UNPLANNED");
    const { payload } = await dispatch(createRemoveOrderFromRoute({ payload: requestPayload }));
    if (payload !== undefined && payload.message !== undefined) {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: payload.message, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    } else {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: SNACKBAR_MESSAGES.ORDER_REMOVED_FROM_ROUTE, snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS });
      if (payload !== undefined && payload.routeId !== undefined) {
        getAllPlannedRoutes(allRouteState);
      }
    }
  };

  const removeOrderFromRoute = (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => {
    if (routeName && routeDataName && slotName && !isUndefined(orderObjIndex) && Number(orderObjIndex) >= 0) {
      const orderData = allRouteState?.plannedConsignmentsData[routeName]?.routeDataObj[routeDataName]?.slotWiseOrders[slotName]?.orders[orderObjIndex];
      if (orderData?.consignmentCode) {
        let newAllRouteState = removeOrderFromRouteUtils(allRouteState, routeName, routeDataName, slotName, orderObjIndex, allRouteState?.plannedConsignmentsData[routeName], orderData);
        setAllRouteState(newAllRouteState);
      }
    }
  };

  const assignOrdersToRoute = async (selectedConsignments: any, selectedConsignmentToChangeRoute: any, routeObj: any, displayRouteStatus: string) => {
    const requestPayload: any = createAddOrdersToRoutesPayload(selectedConsignments, selectedConsignmentToChangeRoute, routeObj, displayRouteStatus);
    const { payload } = await dispatch(createRemoveOrderFromRoute({ payload: requestPayload }));
    if (payload !== undefined && payload.message !== undefined) {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: payload.message, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    } else {
      setAllRouteState({
        ...allRouteState,
        loading: false,
        openSnackbar: true,
        snackbarMessage: SNACKBAR_MESSAGES.ORDER_ADDED_TO_ROUTE,
        snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS,
        selectedConsignments: [],
        openAssignRoutePopUp: false,
        selectedConsignmentToChangeRoute: [],
        availableRouteRecord: [],
        selectedRouteObj: {},
        orderDataObj: {},
        openAddRouteConfirmDialog: false,
        openRemoveRouteConfirmDialog: false,
      });
      if (payload !== undefined && payload.routeId !== undefined) {
        if (allRouteState.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED) {
          getAllPlannedRoutes(allRouteState);
        } else {
          getAllUnPlannedRoutes(allRouteState);
        }
      }
    }
  };

  const handleAssignRouteClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (
      allRouteState &&
      allRouteState.availableRouteRecord &&
      isNonEmptyArray(allRouteState.availableRouteRecord) &&
      allRouteState.selectedConsignmentToChangeRoute &&
      isNonEmptyArray(allRouteState.selectedConsignmentToChangeRoute)
    ) {
      let routeObj = allRouteState.availableRouteRecord.find((el: any) => el.isChecked === true);
      if (!isUndefined(routeObj) && !isUndefined(routeObj.id)) {
        setAllRouteState({ ...allRouteState, selectedRouteObj: routeObj, orderDataObj: {}, openAddRouteConfirmDialog: true, openRemoveRouteConfirmDialog: false });
      } else {
        setAllRouteState({ ...allRouteState, openSnackbar: true, snackbarMessage: "No Route selected", snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
      }
    } else {
      setAllRouteState({ ...allRouteState, openSnackbar: true, snackbarMessage: "No Route found", snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    }
  };

  const selectRouteToAssigntoOrder = (routeIndex: number) => {
    if (!isUndefined(routeIndex) && routeIndex >= 0) {
      let newAllRouteState = selectRouteToAssigntoOrderUtils(allRouteState, routeIndex);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleCloseAssignRoutePopUp = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    let newAllRouteState = updatAavailableRouteRecordUtils(allRouteState);
    setAllRouteState(newAllRouteState);
  };

  const getExistingRouteToAssignToOrder = async (consignmentCode: string, routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => {
    const requestPayload = createAddOrderToAnotherRoutePayload(allRouteState, hubId, consignmentCode);
    const { payload } = await dispatch(fetchExistingRouteToAssignToOrder({ payload: requestPayload }));
    if (payload !== undefined && payload.message !== undefined) {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: payload.message, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    } else if (payload && isNonEmptyArray(payload)) {
      let newAllRouteState = updatAavailableRouteRecordUtils(allRouteState, routeName, routeDataName, slotName, orderObjIndex, payload);
      setAllRouteState(newAllRouteState);
    } else {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: SNACKBAR_MESSAGES.NO_ROUTES_TO_DISPLAY, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    }
  };

  const addOrderToAnotherRoute = (routeName: string, routeDataName: string, slotName: string, orderObjIndex: number) => {
    if (routeName && routeDataName && slotName && !isUndefined(orderObjIndex) && Number(orderObjIndex) >= 0) {
      const routeData = allRouteState?.plannedConsignmentsData[routeName];
      if (routeData?.routeDataObj[routeDataName]?.slotWiseOrders[slotName]?.orders[orderObjIndex].consignmentCode) {
        setAllRouteState({ ...allRouteState, loading: true });
        const consignmentCode = routeData.routeDataObj[routeDataName].slotWiseOrders[slotName].orders[orderObjIndex].consignmentCode;
        getExistingRouteToAssignToOrder(consignmentCode, routeName, routeDataName, slotName, orderObjIndex);
      }
    }
  };

  const getAllRoutesForSelectedUnplannedOrders = async (requestPayload: any, consignmentRecord: any) => {
    const { payload }: any = await dispatch(fetchExistingRouteToAssignToOrder({ payload: requestPayload }));
    setShowLoader(false);
    if (payload !== undefined && payload.message !== undefined) {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: payload.message, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    } else if (payload && isNonEmptyArray(payload)) {
      let newAllRouteState = updatAvailableRouteRecordForUnplannedOrdersUtils(allRouteState, consignmentRecord, payload);
      setAllRouteState(newAllRouteState);
    } else {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: SNACKBAR_MESSAGES.NO_ROUTES_TO_DISPLAY, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    }
  };

  const handleAssignUnplannedOrderToRoute = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (allRouteState.selectedConsignments && isNonEmptyArray(allRouteState.selectedConsignments)) {
      setShowLoader(true);
      const consignmentRecord = getConsignmentRecordUtils(allRouteState.selectedConsignments);
      if (consignmentRecord && isNonEmptyArray(consignmentRecord)) {
        const requestPayload = createAddOrderToAnotherRoutePayload(allRouteState, hubId, consignmentRecord);
        getAllRoutesForSelectedUnplannedOrders(requestPayload, consignmentRecord);
      } else {
        setShowLoader(false);
      }
    }
  };

  const handleExpandAllRecordClickMethod = () => {
    let newAllRouteState = handleExpandAllRecordClickMethodUtils(allRouteState);
    setAllRouteState(newAllRouteState);
  };

  const handleSelectAllRoutes = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (!isUndefined(event) && !isUndefined(event.target) && !isUndefined(event.target.checked)) {
      const { checked } = event.target;
      let newAllRouteState = handleSelectAllRoutesUtils(allRouteState, checked);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleOpenCloseUnplannedRouteSlot = (areaName: any, slotName: any) => {
    if (!isUndefined(areaName) && !isUndefined(slotName)) {
      let newAllRouteState = handleOpenCloseUnplannedRouteSlotUtils(allRouteState, areaName, slotName);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleUnplannedSlotCheckboxToggle = (event: React.ChangeEvent<HTMLInputElement>, areaName: any, slotName: any) => {
    event.stopPropagation();
    if (!isUndefined(event) && !isUndefined(event.target) && !isUndefined(event.target.checked) && !isUndefined(areaName)) {
      const { checked } = event.target;
      if (!isUndefined(areaName) && !isUndefined(slotName)) {
        let newAllRouteState = handleUnplannedSlotCheckboxToggleUtils(allRouteState, checked, areaName, slotName);
        setAllRouteState(newAllRouteState);
      }
    }
  };

  const isConsignmentSelected = () => {
    return loadDashIsArrayAndNotEmpty(allRouteState?.selectedConsignments);
  };

  const handleConsignmentIdOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isUndefined(event?.target?.value)) {
      setAllRouteState({ ...allRouteState, consignmentId: event?.target?.value });
    }
  };

  const handleConsignmentSearch = () => {
    if (!isUndefined(allRouteState?.consignmentId) && !isNull(allRouteState?.consignmentId)) {
      let newAllRouteState = handleConsignmentSearchUtils(allRouteState);
      setAllRouteState(newAllRouteState);
      setTimeout(() => {
        const element = document.getElementById(`order-code-${allRouteState.consignmentId}`);
        element?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }, 100);
    } else {
      setAllRouteState({ ...allRouteState, openSnackbar: true, snackbarMessage: SNACKBAR_MESSAGES.CONSIGNMENT_ID_REQUIRED, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    }
  };

  const handleCloseMoveOrderToNewRoutePopUp = () => {
    const requestPayload = mapUnplannedOrderToDraftedRoutePayload(allRouteState, allRouteState.draftedRouteDetails, BUTTONS_TEXT.UNPLANNED);
    dispatch(updateConsignmentStatus({ payload: requestPayload }));
    setAllRouteState({ ...allRouteState, openMoveOrderToNewRoute: false, draftedRouteDetails: {} });
  };

  const mapUnplannedOrderToDraftedRoute = async (route: any) => {
    const requestPayload = mapUnplannedOrderToDraftedRoutePayload(allRouteState, route, BUTTONS_TEXT.PLANNED);
    const response = await dispatch(updateConsignmentStatus({ payload: requestPayload }));
    setShowLoader(false);
    if (!isUndefined(response?.payload?.routeId)) {
      setAllRouteState({ ...allRouteState, openMoveOrderToNewRoute: true, draftedRouteDetails: route });
    }
  };

  const showMoveUnplannedRouteToPlannedPopUp = async (requestPayload: any) => {
    const responsePayload = await dispatch(createDraftRoute({ params: requestPayload }));
    if (!isUndefined(responsePayload?.payload?.route?.id) && !isUndefined(responsePayload?.payload?.route?.routeId)) {
      mapUnplannedOrderToDraftedRoute(responsePayload?.payload?.route);
    } else {
      setShowLoader(false);
    }
  };

  const handleCreateUnplannedOrderToRoute = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (allRouteState.selectedConsignments && isNonEmptyArray(allRouteState.selectedConsignments)) {
      setShowLoader(true);
      const requestPayload = createDraftUnplannedRoutePayload(allRouteState, hubId, hubName);
      showMoveUnplannedRouteToPlannedPopUp(requestPayload);
    }
  };

  const assignOrdersToNewRoute = async () => {
    setShowLoader(true);
    const requestPayload = createNewRouteProceedPayload(allRouteState);
    const response = await dispatch(createAndEditRoute({ payload: requestPayload, editMode: false }));
    setShowLoader(false);
    if (!isUndefined(response?.payload?.route?.routeId)) {
      dispatch(resetCreateRouteSuccess());
      setAllRouteState({
        ...allRouteState,
        openSnackbar: true,
        snackbarMessage: `${response?.payload?.route?.routeId} - ${SNACKBAR_MESSAGES.CREATE_ROUTE_SUCCESS_MSG}`,
        snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS,
        openMoveOrderToNewRoute: false,
        draftedRouteDetails: {},
        tabValue: AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED,
        loading: true,
      });
      getAllPlannedRoutes(allRouteState);
    }
  };

  const toggleCreateSubroute = (routeId: string) => {
    if (routeId) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleToggleCreateSubrouteUtils(allRouteState, routeId);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleOpenCreateSubRouteDialog = () => {
    if (allRouteState?.selectedConsignmentsForSubRoute?.length > 0) {
      setAllRouteState({ ...allRouteState, openCreateSubRouteDialog: true });
    } else {
      setAllRouteState({ ...allRouteState, openSnackbar: true, snackbarMessage: SNACKBAR_MESSAGES.NO_ORDERS_PRESENT_FOR_SUB_ROUTE, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    }
  };

  const handleCloseCreateSubRouteDialog = () => {
    setAllRouteState({ ...allRouteState, openCreateSubRouteDialog: false });
  };

  const assignOrdersToNewSubRoute = async () => {
    if (!isNull(allRouteState?.selectedRoute?.id)) {
      setShowLoader(true);
      const requestPayload = createNewSubRouteRequestPayload(allRouteState);
      const response = await dispatch(createNewSubRoute({ payload: requestPayload }));
      setShowLoader(false);
      if (!isUndefined(response?.payload?.routeId)) {
        setAllRouteState({
          ...allRouteState,
          openSnackbar: true,
          snackbarMessage: `${response?.payload?.routeId} - ${SNACKBAR_MESSAGES.CREATE_ROUTE_SUCCESS_MSG}`,
          snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS,
          openCreateSubRouteDialog: false,
          selectedConsignmentsForSubRoute: [],
          loading: true,
        });
        getAllPlannedRoutes(allRouteState);
      }
    }
  };

  const handleDeleteIconClick = (e: any, routesData: any) => {
    e.stopPropagation();
    setAllRouteState({ ...allRouteState, selectedRouteDeletion: routesData, openDeleteSubRouteDialog: true });
  };

  const handleCloseDeleteSubRouteDialog = () => {
    setAllRouteState({ ...allRouteState, openDeleteSubRouteDialog: false, selectedRouteDeletion: {} });
  };

  const handleDeleteRoute = async () => {
    const bulkDeletePayload: any = createBulkDeleteRoutePayload([allRouteState.selectedRouteDeletion]);
    const response = await dispatch(bulkDeleteRoute({ payload: bulkDeletePayload }));
    if (!isUndefined(response)) {
      setAllRouteState({
        ...allRouteState,
        openDeleteSubRouteDialog: false,
        selectedRouteDeletion: {},
        openSnackbar: true,
        snackbarMessage: `${SNACKBAR_MESSAGES.DELETE_ROUTE_SUCCESS_MSG}`,
        snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS,
        loading: true,
      });
      getAllPlannedRoutes(allRouteState);
    }
  };

  const isSubRouteObjectNonEmpty = () => {
    return allRouteState?.selectedSubRoute && isObject(allRouteState.selectedSubRoute) && isNonEmptyObject(allRouteState.selectedSubRoute);
  };

  const isRouteObjectNonEmpty = () => {
    return allRouteState && allRouteState.selectedRoute && isObject(allRouteState.selectedRoute) && isNonEmptyObject(allRouteState.selectedRoute);
  };

  const handleSubRouteUpdateDialogOpen = () => {
    const isRouteInformationValid = validatePlannedSubRouteInfoUtils(allRouteState);
    if (isRouteInformationValid?.errorObj !== undefined && typeof isRouteInformationValid?.errorObj === "string") {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: isRouteInformationValid.errorObj, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    } else {
      setAllRouteState({ ...allRouteState, openSubRouteOrderActionPopUp: true });
    }
  };

  const handleSubRouteUpdateDialogClose = () => {
    setAllRouteState({ ...allRouteState, openSubRouteOrderActionPopUp: false });
  };

  const updateSubRouteObjectAfterUpdate = (routeData: any) => {
    const successMessage = `Route ${routeData?.routeId || ""} ${AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.UPDATE_ROUTE_SUCCESS_MSG}`;
    let newAllRouteState = updateSubRouteObjectAfterUpdateUtils(allRouteState, routeData, successMessage);
    setAllRouteState(newAllRouteState);
    fetchComponentData(newAllRouteState);
  };

  const updateSubRoute = async (updateSubRoutePayload: any) => {
    if (updateSubRoutePayload && isObject(updateSubRoutePayload) && isNonEmptyObject(updateSubRoutePayload)) {
      const { payload } = await dispatch(createAndEditRoute({ payload: updateSubRoutePayload, editMode: false }));
      if (payload !== undefined && payload.message !== undefined) {
        setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: payload.message, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
      } else if (payload !== undefined && payload.route !== undefined && payload.route.routeId !== undefined) {
        updateSubRouteObjectAfterUpdate(payload.route);
      } else {
        setAllRouteState({ ...allRouteState, loading: false });
      }
    } else {
      setAllRouteState({ ...allRouteState, loading: false, selectedSubRoute: {}, openSubRouteOrderActionPopUp: false });
    }
  };

  const savePlannedSubRoute = () => {
    setAllRouteState({ ...allRouteState, loading: true });
    const isRouteInformationValid = validatePlannedSubRouteInfoUtils(allRouteState);
    if (isRouteInformationValid?.errorObj !== undefined && typeof isRouteInformationValid.errorObj === "string") {
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: isRouteInformationValid.errorObj, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR });
    } else {
      const updateSubRoutePayload = createSubRoutesUpdatePayload(allRouteState?.selectedSubRoute, allRouteState.subDriverValue, allRouteState.subHelperValue, allRouteState.subVehicleValue);
      updateSubRoute(updateSubRoutePayload);
    }
  };

  /**
   *
   * View Utils
   */

  const renderDialogDetailsContent = (heading: string, subHeading: string) => {
    return (
      <Grid className={classes.publishDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>{`${heading}`}</Typography>
        </Grid>
        <Grid className="contentItem desc" item>
          <Typography>{`${subHeading}`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getPublishDialogDetailsContent = () => {
    return (
      <Grid className={classes.publishDialogContentContainer} container key={allRouteState?.selectedRoute?.routeId}>
        <Grid className="contentItem heading" item>
          <Typography>{`${SNACKBAR_MESSAGES.CONFIRM_PUBLISH_ROUTE}`}</Typography>
        </Grid>
        <Grid className="contentItem desc" item>
          <Typography>
            <span className="count">
              {allRouteState.selectedConsignments.length > 1 ? `${allRouteState.selectedConsignments.length} Routes` : `${allRouteState.selectedConsignments.length} Route`}
            </span>
            {`${allRouteState.selectedConsignments.length > 1 ? " are " : " is "}${SNACKBAR_MESSAGES.DRIVER_NOTIFIED_MESSAGE}`}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getUpdateDialogDetailsContent = () => {
    const heading: string = `Are you sure you want to update ${allRouteState?.selectedRoute?.routeId}?`;
    const subHeading: string = `${SNACKBAR_MESSAGES.CONFIRM_CHANGES_TO_ROUTE}`;
    return renderDialogDetailsContent(heading, subHeading);
  };

  const getRemoveRouteDialogDetailsContent = () => {
    const routeId: string = allRouteState?.selectedRouteObj?.routeId.toString() || "";
    const heading: string = `Are you sure you want to remove ${allRouteState?.orderDataObj?.consignmentCode || ""} from Route ${routeId}?`;
    const subHeading: string = `${SNACKBAR_MESSAGES.CONFIRM_CHANGES_TO_ROUTE}`;
    return renderDialogDetailsContent(heading, subHeading);
  };

  const getAddToRouteDialogDetailsContent = () => {
    const routeId: string = allRouteState?.selectedRouteObj?.routeId.toString() || "";
    const heading: string = `Are you sure you want to add ${allRouteState?.selectedConsignmentToChangeRoute && getAllAreaTitle(allRouteState.selectedConsignmentToChangeRoute)} to Route ${routeId} ?`;
    const subHeading: string = `${SNACKBAR_MESSAGES.CONFIRM_CHANGES_TO_ROUTE}`;
    return renderDialogDetailsContent(heading, subHeading);
  };

  const getAddToNewDraftedRouteDialogDetailsContent = () => {
    const newRoute: string = allRouteState?.draftedRouteDetails?.routeId || "";
    const heading: string = `Are you sure you want to add ${allRouteState?.selectedConsignments?.length || ""} order(s) to new Route ${newRoute} ?`;
    const subHeading: string = `${SNACKBAR_MESSAGES.CONFIRM_CHANGES_TO_NEW_ROUTE}`;
    return renderDialogDetailsContent(heading, subHeading);
  };

  // Sub Route
  const createNewSubRouteRouteDialogDetailsContent = () => {
    const heading: string = `Are you sure you want to add ${allRouteState?.selectedConsignmentsForSubRoute?.length || ""} order(s) to new Sub Route ?`;
    const subHeading: string = `${SNACKBAR_MESSAGES.CONFIRM_CHANGES_TO_NEW_SUB_ROUTE}`;
    return renderDialogDetailsContent(heading, subHeading);
  };

  const deleteSubRouteRouteDialogDetailsContent = () => {
    const heading: string = `Are you sure you want to delete sub route ${allRouteState?.selectedRouteDeletion?.routeId || ""} ?`;
    const subHeading: string = `${SNACKBAR_MESSAGES.CONFIRM_DELETE_SUB_ROUTE}`;
    return renderDialogDetailsContent(heading, subHeading);
  };

  const updateSubRouteDetailsDetailsContent = () => {
    const heading: string = `Are you sure you want to update ${allRouteState?.selectedSubRoute?.routeId || ""} ?`;
    const subHeading: string = `${SNACKBAR_MESSAGES.CONFIRM_CHANGES_TO_SUB_ROUTE}`;
    return renderDialogDetailsContent(heading, subHeading);
  };

  const isRouteSaveDisabled = (): boolean => {
    let disable: boolean = true;
    if (allRouteState?.selectedRoute?.routeId && allRouteState?.plannedConsignmentsData && allRouteState?.plannedConsignmentsData[allRouteState?.selectedRoute?.routeId]) {
      const routeData = allRouteState?.plannedConsignmentsData[allRouteState?.selectedRoute?.routeId];
      const currentHelper = routeData.helper ? routeData.helper.id : AppConstants.SELECT_NONE.value;
      const currentDriver = routeData.assignee ? routeData.assignee.id : AppConstants.SELECT_NONE.value;
      const currentVehicle = routeData.assignedVehicle ? routeData.assignedVehicle.id : AppConstants.SELECT_NONE.value;
      if (allRouteState.helperValue !== currentHelper || allRouteState.driverValue !== currentDriver || allRouteState.vehicleValue !== currentVehicle) {
        disable = false;
      }
    }
    return disable;
  };

  const showCreateSubRouteButtom = (): boolean => {
    let show: boolean = false;
    if (isNonEmptyObject(allRouteState?.selectedRoute?.routeDataObj)) {
      const arr: any[] = Object.values(allRouteState?.selectedRoute?.routeDataObj)
        .map((route: any) => route?.routeType === ROUTE_TYPE.ROUTE_AREA)
        .filter(Boolean);
      if (arr?.length > 1) {
        show = true;
      }
    }
    return show;
  };

  const isCreateSubRouteDisabled = (): boolean => {
    let disable: boolean = true;
    if (allRouteState?.selectedConsignmentsForSubRoute?.length > 0) {
      disable = false;
    }
    return disable;
  };

  const isSubRouteSaveDisabled = (): boolean => {
    let disable: boolean = true;
    if (isNonEmptyObject(allRouteState.selectedSubRoute)) {
      const routeData = allRouteState.selectedSubRoute;
      const currentHelper = routeData.helper ? routeData.helper.id : AppConstants.SELECT_NONE.value;
      const currentDriver = routeData.assignee ? routeData.assignee.id : AppConstants.SELECT_NONE.value;
      const currentVehicle = routeData.assignedVehicle ? routeData.assignedVehicle.id : AppConstants.SELECT_NONE.value;
      if (allRouteState.subHelperValue !== currentHelper || allRouteState.subDriverValue !== currentDriver || allRouteState.subVehicleValue !== currentVehicle) {
        disable = false;
      }
    }
    return disable;
  };

  const isSelectedRouteParentRoute = () => {
    let show: boolean = false;
    if (allRouteState?.selectedConsignments?.length > 0) {
      const arr: any[] = allRouteState?.selectedConsignments.map((route: any) => route?.routeType === ROUTE_TYPE.PARENT_ROUTE).filter(Boolean);
      if (arr?.length > 0) {
        show = true;
      }
    }
    return show;
  };

  const getUpdateDialogActions = () => {
    return (
      <Grid className={classes.publishDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => handleUpdateDialogClose()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => savePlannedRouteInformation()}>
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const getPublishDialogActions = () => {
    return (
      <Grid className={classes.publishDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => handlePublishDialogClose()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => publishPlannedRouteInformation()}>
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const getRemoveRouteDialogActions = () => {
    return (
      <Grid className={classes.publishDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => handleOrderActionDialogClose()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => fetchRemoveOrderFromRoute(allRouteState.selectedRouteObj, allRouteState.orderDataObj)}>
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const getAddConsignmentToRouteDialogActions = () => {
    return (
      <Grid className={classes.publishDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => handleOrderActionDialogClose()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button
          className="dialogBtn primary"
          variant="contained"
          onClick={() => assignOrdersToRoute(allRouteState.selectedConsignments, allRouteState.selectedConsignmentToChangeRoute, allRouteState.selectedRouteObj, "PLANNED")}
        >
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const getRouteDetailsForInfoWindow = (marker: MAP_MARKER_INTERFACE) => {
    return (
      <Card className={classes.infoWindowCardStyle}>
        {marker?.parentRouteName && <RenderMapMarkerTitleValue classes={classes} title={`Parent Rote ID : `} value={marker?.parentRouteName} />}
        <RenderMapMarkerTitleValue classes={classes} title={`Rote ID : `} value={marker?.routeId} />
        <RenderMapMarkerTitleValue classes={classes} title={`Consignment : `} value={marker?.consignmentCode} />
        <RenderMapMarkerTitleValue classes={classes} title={`Delivery Area : `} value={marker?.deliveryArea} />
        <RenderMapMarkerTitleValue classes={classes} title={`Delivery Slot : `} value={marker?.deliverySlot} />
      </Card>
    );
  };

  const renderSubRouteSelectedFooterAction = () => {
    return (
      <>
        <Grid item>
          <Button
            className={`${classes.buttonStyle} secondary buttonGridContainerStyle`}
            variant="contained"
            onClick={(event: any) => handleRoutAreaAccordianToggle(event, allRouteState?.selectedSubRoute?.parentRouteName, allRouteState?.selectedSubRoute?.routeId)}
          >
            {AppConstants.BUTTONS.CANCEL}
          </Button>
        </Grid>
        <Grid item>
          <Button className={`${classes.buttonStyle} primary buttonGridContainerStyle`} variant="contained" disabled={isSubRouteSaveDisabled()} onClick={() => handleSubRouteUpdateDialogOpen()}>
            <span className={"primary buttonGridContainerStyle"}>{AppConstants.BUTTONS.SAVE}</span>
          </Button>
        </Grid>
      </>
    );
  };

  const renderRouteSelectedFooterAction = () => {
    return (
      <>
        <Grid item>
          <Button
            className={`${classes.buttonStyle} secondary buttonGridContainerStyle`}
            variant="contained"
            onClick={(event: any) => handleRouteAccordianClicked(event, allRouteState?.selectedRoute?.routeId)}
          >
            {AppConstants.BUTTONS.CANCEL}
          </Button>
        </Grid>
        <Grid item>
          <Button className={`${classes.buttonStyle} primary buttonGridContainerStyle`} variant="contained" disabled={isRouteSaveDisabled()} onClick={() => handleUpdateDialogOpen()}>
            <span className={"primary buttonGridContainerStyle"}>{AppConstants.BUTTONS.SAVE}</span>
          </Button>
        </Grid>
        {allRouteState?.selectedRoute?.subRouteCreated && showCreateSubRouteButtom() && (
          <Grid item>
            <Button className={`${classes.buttonStyle} primary buttonGridContainerStyle`} variant="contained" disabled={isCreateSubRouteDisabled()} onClick={() => handleOpenCreateSubRouteDialog()}>
              <span className={"primary buttonGridContainerStyle"}>{ALL_ROUTES_TEXT_CONTANT.CREATE_SUB_ROUTE}</span>
            </Button>
          </Grid>
        )}
      </>
    );
  };

  const getMapContainerFooter = () => {
    return (
      <Grid container spacing={1} direction="row" justify="space-between" alignItems="center" className={classes.mapFooterGridStyle}>
        <Grid item xs={12}>
          <Grid container spacing={1} direction="row" justify="flex-end" alignItems="center">
            {allRouteState.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED && (
              <>
                {isSubRouteObjectNonEmpty() ? renderSubRouteSelectedFooterAction() : isRouteObjectNonEmpty() && renderRouteSelectedFooterAction()}
                {isConsignmentSelected() ? (
                  <>
                    <CustomTooltip title={isSelectedRouteParentRoute() ? SNACKBAR_MESSAGES.ASSIGN_PARENT_ROUTE_DRIVER_TO_PUBLISH : SNACKBAR_MESSAGES.ASSIGN_DRIVER_TO_PUBLISH}>
                      <Grid item>
                        <Button className={`${classes.buttonStyle} primary`} variant="contained" disabled={isAllRoutesAssignedWithDriver()} onClick={() => handlePublishDialogOpen()}>
                          {AppConstants.BUTTONS.PUBLISH}
                        </Button>
                      </Grid>
                    </CustomTooltip>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {allRouteState.tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED &&
              allRouteState.selectedConsignments &&
              isNonEmptyArray(allRouteState.selectedConsignments) &&
              !allRouteState.openAssignRoutePopUp && (
                <>
                  <Grid item>
                    <Button className={`${classes.buttonStyle} secondary buttonGridContainerStyle`} variant="contained" onClick={(event: any) => handleAssignUnplannedOrderToRoute(event)}>
                      {AppConstants.BUTTONS.ADD_TO_EXISTING_ROUTES}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button className={`${classes.buttonStyle} primary buttonGridContainerStyle`} variant="contained" onClick={(event: any) => handleCreateUnplannedOrderToRoute(event)}>
                      {BUTTONS_TEXT.CREATE_NEW_ROUTE}
                    </Button>
                  </Grid>
                </>
              )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getAddToNewDraftedRouteDialogActions = () => {
    return (
      <Grid className={classes.publishDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => handleCloseMoveOrderToNewRoutePopUp()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => assignOrdersToNewRoute()}>
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const createNewSubRouteRouteDialogActions = () => {
    return (
      <Grid className={classes.publishDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => handleCloseCreateSubRouteDialog()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => assignOrdersToNewSubRoute()}>
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const deleteSubRouteDialogActions = () => {
    return (
      <Grid className={classes.publishDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => handleCloseDeleteSubRouteDialog()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className={`dialogBtn ${classes.deleteButton}`} variant="contained" onClick={() => handleDeleteRoute()}>
          {AppConstants.BUTTONS.DELETE}
        </Button>
      </Grid>
    );
  };

  const updateSubRouteDialogActions = () => {
    return (
      <Grid className={classes.publishDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => handleSubRouteUpdateDialogClose()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => savePlannedSubRoute()}>
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  useEffect(() => {
    if (publishedRoutes && Array.isArray(publishedRoutes) && publishedRoutes.length > 0) {
      setAllRouteState({
        ...allRouteState,
        loading: true,
        openSnackbar: false,
        openPublishRouteSnackBar: true,
        snackbarPublishMessage: `"${publishedRoutes.length} Route(s)" ${AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.PUBLISH_ROUTES_SUCCESS_MSG}`,
        snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS,
        openPublishDialog: false,
        selectedConsignments: [],
        showDriverAssignedPopup: false,
        driverValue: AppConstants.SELECT_NONE.value,
        helperValue: AppConstants.SELECT_NONE.value,
        vehicleValue: AppConstants.SELECT_NONE.value,
        selectedRoute: {},
      });
      fetchConsignmentValueBasedOnTab(allRouteState);
    }
  }, [publishedRoutes]);

  useEffect(() => {
    error?.message &&
      setAllRouteState({ ...allRouteState, loading: false, openSnackbar: true, snackbarMessage: error?.message || error || "", snackbarType: AppConstants.SNACKBAR.TYPES.ERROR, selectedRoute: {} });
  }, [error]);

  useEffect(() => {
    plannedRoutes && designAllRoutesStateObject();
  }, [plannedRoutes]);

  useEffect(() => {
    unPlannedRoutes && designAllUnPlannedRoutesStateObject();
  }, [unPlannedRoutes]);

  useEffect(() => {
    initializeRoute({ date: allRouteState.selectedDate, slotValue: allRouteState.slotValue, statusValue: allRouteState.statusValue });
  }, [hubId, allRouteState.selectedDate]);

  useEffect(() => {
    setAllRouteState({ ...allRouteState, loading: true });
    fetchComponentData(allRouteState);
    getAllPlannedRoutes(allRouteState);
  }, [hubId]);

  return (
    <div className={classes.root}>
      {(showLoader || allRouteState.loading) && <Loader></Loader>}
      <Grid className={classes.container} container>
        <Grid className="createRtItem content" item>
          <Grid className={classes.createRtContentContainer} container>
            <Grid className="contentItem left" item>
              <CustomAllRoutesLeftSection
                classes={classes}
                allRouteState={allRouteState}
                // Filters
                consignmentStatusList={orderStatuses}
                slotsList={slotsList}
                zoneList={zoneList}
                handleDateChange={handleDateChange}
                handleTabChange={handleTabChange}
                handleStatusChange={handleStatusChange}
                handleSlotChange={handleSlotChange}
                handleZoneChange={handleZoneChange}
                handleConsignmentIdOnChange={handleConsignmentIdOnChange}
                handleConsignmentSearch={handleConsignmentSearch}
                hubId={hubId}
                hubName={hubName}
                handleChangeAllRoutesHub={handleChangeAllRoutesHub}
                // Planned
                handleRouteAccordianToggle={handleRouteAccordianToggle}
                handleRoutAreaAccordianToggle={handleRoutAreaAccordianToggle}
                handleRouteSlotAccordianToggle={handleRouteSlotAccordianToggle}
                handleRouteOrderAccordianToggle={handleRouteOrderAccordianToggle}
                handleRouteCheckboxToggle={handleRouteCheckboxToggle}
                handleAreaCheckboxToggle={handleAreaCheckboxToggle}
                handleSlotCheckboxToggle={handleSlotCheckboxToggle}
                handleOrderCheckboxToggle={handleOrderCheckboxToggle}
                orderActionPopUpViewChange={orderActionPopUpViewChange}
                removeOrderFromRoute={removeOrderFromRoute}
                addOrderToAnotherRoute={addOrderToAnotherRoute}
                handleDeleteRoute={handleDeleteIconClick}
                // Unplanned
                handleUnplannedAccordianToggle={handleUnplannedAccordianToggle}
                handleUnplannedInnerOrderAccordianToggle={handleUnplannedInnerOrderAccordianToggle}
                handleUnplannedOrderCheckboxToggle={handleUnplannedOrderCheckboxToggle}
                handleExpandAllRecordClickMethod={handleExpandAllRecordClickMethod}
                handleSelectAllRoutes={handleSelectAllRoutes}
                handleUnplannedAreaCheckboxToggle={handleUnplannedAreaCheckboxToggle}
                handleOpenCloseUnplannedRouteSlot={handleOpenCloseUnplannedRouteSlot}
                handleUnplannedSlotCheckboxToggle={handleUnplannedSlotCheckboxToggle}
              />
            </Grid>
            <Grid className="contentItem right" item>
              {isSubRouteObjectNonEmpty() ? (
                <RenderSubrouteAssignDriverDialog
                  classes={classes}
                  subRouteStateObj={allRouteState.selectedSubRoute}
                  driverValue={allRouteState.subDriverValue}
                  helperValue={allRouteState.subHelperValue}
                  vehicleValue={allRouteState.subVehicleValue}
                  handleInputChange={handleSubRouteInputChange}
                  getUpdatedAssigneList={getUpdatedAssigneList}
                />
              ) : (
                allRouteState?.selectedRoute &&
                isObject(allRouteState.selectedRoute) &&
                isNonEmptyObject(allRouteState.selectedRoute) && (
                  <AssignDriverDialog
                    classes={classes}
                    countryCode={countryCode}
                    getUpdatedAssigneList={getUpdatedAssigneList}
                    stateObj={allRouteState?.selectedRoute}
                    driverValue={allRouteState.driverValue}
                    helperValue={allRouteState.helperValue}
                    vehicleValue={allRouteState.vehicleValue}
                    handleInputChange={handleInputChange}
                    toggleCreateSubroute={toggleCreateSubroute}
                    hubId={hubId}
                    routeConfigDetails={routeConfigDetails}
                  />
                )
              )}

              <Grid className="mapRightContainer" container>
                <Grid item xs={12} className="mapRightContainer mapItem">
                  {!allRouteState.loading && allRouteState?.consignmentMarkersForMap?.length > 0 ? (
                    <MapContainer
                      center={allRouteState.mapCenter}
                      zoom={allRouteState.mapZoom}
                      markers={allRouteState.consignmentMarkersForMap}
                      showDirections={false}
                      roundTrip={false}
                      optimizeRoute={false}
                      updateMap={true}
                      showInfoWindow={true}
                      getRouteDetailsForInfoWindow={getRouteDetailsForInfoWindow}
                      mapOptionStyles={mapOptionStyles}
                      mapPolylineMarker={allRouteState.mapPolylineMarker}
                      polylineColor={allRouteState.polylineColor}
                    />
                  ) : (
                    <Box className={classes.noMapGridContainerViewStyle}>{""}</Box>
                  )}
                </Grid>
                <Grid item xs={12} className="mapRightContainer buttonItem">
                  {getMapContainerFooter()}
                </Grid>
              </Grid>

              {allRouteState.openAssignRoutePopUp && (
                <AssignRouteToOrdersDialog
                  classes={classes}
                  allRouteState={allRouteState}
                  handleCloseAssignRoutePopUp={handleCloseAssignRoutePopUp}
                  selectRouteToAssigntoOrder={selectRouteToAssigntoOrder}
                  handleAssignRouteClick={handleAssignRouteClick}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {allRouteState.openSnackbar && (
        <CustomSnackbar
          open={allRouteState.openSnackbar}
          handleClose={handleSnackbarClose}
          autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT}
          message={allRouteState.snackbarMessage}
          type={allRouteState.snackbarType}
        />
      )}
      {allRouteState.openPublishRouteSnackBar && (
        <CustomSnackbar
          open={allRouteState.openPublishRouteSnackBar}
          handleClose={handlePublishSnackbarClose}
          message={allRouteState.snackbarPublishMessage}
          type={AppConstants.SNACKBAR.TYPES.SUCCESS}
          autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT_PUBLISH}
          action={viewPublishRoute}
          actionText={SNACKBAR_MESSAGES.VIEW}
        />
      )}
      {allRouteState.openPublishDialog && (
        <CustomDialog
          open={allRouteState.openPublishDialog}
          PaperProps={publishDialogPaperProps}
          title={<></>}
          content={getPublishDialogDetailsContent()}
          actions={getPublishDialogActions()}
          handleClose={handlePublishDialogClose}
        />
      )}
      {allRouteState.openUpdateConfirmDialog && (
        <CustomDialog
          open={allRouteState.openUpdateConfirmDialog}
          PaperProps={publishDialogPaperProps}
          title={<></>}
          content={getUpdateDialogDetailsContent()}
          actions={getUpdateDialogActions()}
          handleClose={handleUpdateDialogClose}
        />
      )}
      {allRouteState.openRemoveRouteConfirmDialog && (
        <CustomDialog
          open={allRouteState.openRemoveRouteConfirmDialog}
          PaperProps={publishDialogPaperProps}
          title={<></>}
          content={getRemoveRouteDialogDetailsContent()}
          actions={getRemoveRouteDialogActions()}
          handleClose={handleOrderActionDialogClose}
        />
      )}
      {allRouteState.openAddRouteConfirmDialog && (
        <CustomDialog
          open={allRouteState.openAddRouteConfirmDialog}
          PaperProps={publishDialogPaperProps}
          title={<></>}
          content={getAddToRouteDialogDetailsContent()}
          actions={getAddConsignmentToRouteDialogActions()}
          handleClose={handleOrderActionDialogClose}
        />
      )}
      {allRouteState.openMoveOrderToNewRoute && (
        <CustomDialog
          open={allRouteState.openMoveOrderToNewRoute}
          PaperProps={publishDialogPaperProps}
          title={<></>}
          content={getAddToNewDraftedRouteDialogDetailsContent()}
          actions={getAddToNewDraftedRouteDialogActions()}
          handleClose={handleCloseMoveOrderToNewRoutePopUp}
        />
      )}
      {allRouteState.openCreateSubRouteDialog && (
        <CustomDialog
          open={allRouteState.openCreateSubRouteDialog}
          PaperProps={publishDialogPaperProps}
          title={<></>}
          content={createNewSubRouteRouteDialogDetailsContent()}
          actions={createNewSubRouteRouteDialogActions()}
          handleClose={handleCloseCreateSubRouteDialog}
        />
      )}
      {allRouteState.openDeleteSubRouteDialog && (
        <CustomDialog
          open={allRouteState.openDeleteSubRouteDialog}
          PaperProps={publishDialogPaperProps}
          title={<></>}
          content={deleteSubRouteRouteDialogDetailsContent()}
          actions={deleteSubRouteDialogActions()}
          handleClose={handleCloseDeleteSubRouteDialog}
        />
      )}
      {allRouteState.openSubRouteOrderActionPopUp && (
        <CustomDialog
          open={allRouteState.openSubRouteOrderActionPopUp}
          PaperProps={publishDialogPaperProps}
          title={<></>}
          content={updateSubRouteDetailsDetailsContent()}
          actions={updateSubRouteDialogActions()}
          handleClose={handleSubRouteUpdateDialogClose}
        />
      )}
    </div>
  );
};

export default AllRoutes;
