import React, { useEffect, useState } from "react";
import { useStyles } from "./camsWidgets.style";
import { AppState } from "../config/redux/reducers";
import { useSelector } from "react-redux";

const CamsDashboard = () => {
  const classes = useStyles();

  const { countryCode, hubCode } = useSelector((state: AppState) => state.common);

  const [hub, setHub] = useState("");

  useEffect(() => {
    const hub = hubCode.indexOf("all") > -1 ? "all" : hubCode;
    setHub(hub);
  }, [hubCode]);

  return (
    <div className={classes.CF_iframe}>
      <iframe src={"/cams/dashboard?view=widget&hubCode=" + hub + '&countryCode=' + countryCode} frameBorder="0" allowFullScreen></iframe>
    </div>
  );
};
export default CamsDashboard;
