import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../../network/networkHandler.types";
import { PerformanceState } from "./performanceSlice.types";
import AppConstants from "../../../constants";
import { transformSelectValues, transformSlots, totalValues, transformPerformanceResponse, transformVolumeResponse, transformDateValues, transformDataIntoArray } from "../../../utils/helpers.utils";

export const fetchFiltersDropdownValues = createAsyncThunk(
  "PerformanceDashboard/FetchFiltersDropdownValues",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `filter/all/${sessionStorage.getItem(AppConstants.COUNTRY_CODE)}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPerformanceParameters = createAsyncThunk(
  "Dashboard/FetchPerformanceParameters",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "operations/dashboard/consignments",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, false);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRouteParameters = createAsyncThunk(
  "Dashboard/FetchRouteParameters",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "operations/dashboard/routes/global",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, false);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRouteGlobalParams = createAsyncThunk(
  "Dashboard/FetchRouteGlobalParams",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "operations/dashboard/routes",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, false);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchConsignmentsSlots = createAsyncThunk(
  "Dashboard/FetchConsignmentsSlots",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "operations/dashboard/consignments/slots",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, false);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRoutesSlots = createAsyncThunk(
  "Dashboard/FetchRoutesSlots",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "operations/dashboard/routes/global/slots",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, false);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const routesInitialState = {
  onTimePercentage: "",
  handOverOnTimePercentage: "",
  unPlannedConsignments: "",
  globalStateCount: {},
  slotWise: {},
  formattedSlots: [],
  performanceData: {},
  countsData: {},
  percentageData: {},
  volumeData: {},
  paymentWise: [],
}

const consignmentsInitialState = {
  totalConsignments: "",
  onTimePercentage: "",
  handOverOnTimePercentage: "",
  performanceParams: {},
  slotWise: {},
  globalStateCount: {},
  transformedSlots: {},
  countsData: {},
  percentageData: {},
  volumeData: {},
  formattedSlots: [],
  paymentWise: [],
  deliveryTypeWise: [],
}

const initialState: PerformanceState = {
  loading: false,
  filtersDropdowns: {
    countriesList: [],
    hubCodeList: [],
    hubNameList: [],
    deliveryAreaList: [],
    deliverySlotList: [],
    orderTypeList: []
  },
  allSlots: [],
  consignments: {
    ...consignmentsInitialState
  },
  error: "",
  routes: {
    ...routesInitialState
  },
  routesGlobal: {
    statusWise: {},
    totalRoutes: "",
    deliveryTypeWise: [],
  },
};

const performanceSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchFiltersDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchFiltersDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let hubCodeList: any = {};
        let hubNameList: any = {};
        let deliveryAreaList: any = {};
        let deliverySlotList: any = {};
        let orderTypeList: any = {};
        if (payload.hubMap) {
          Object.keys(payload.hubMap).filter((country: any) => {
            hubCodeList[country] = transformSelectValues(payload.hubMap[country], 'code');
            hubNameList[country] = transformSelectValues(payload.hubMap[country], 'value');
            return true;
          });
        }
        if (payload.deliveryArea) {
          Object.keys(payload.deliveryArea).filter((country: any) => {
            deliveryAreaList[country] = transformSelectValues(payload.deliveryArea[country]);
            return true;
          });
        }
        if (payload.deliverySlot) {
          Object.keys(payload.deliverySlot).filter((country: any) => {
            deliverySlotList[country] = transformSelectValues(payload.deliverySlot[country]);
            return true;
          });
        }
        if (payload.orderType) {
          Object.keys(payload.orderType).filter((country: any) => {
            orderTypeList[country] = transformSelectValues(payload.orderType[country]);
            return true;
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.filtersDropdowns = {
          countriesList: transformSelectValues(payload.countries),
          hubCodeList: hubCodeList,
          hubNameList: hubNameList,
          deliveryAreaList: deliveryAreaList,
          deliverySlotList: deliverySlotList,
          orderTypeList: orderTypeList
        };
      })
      .addCase(fetchFiltersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchPerformanceParameters.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchPerformanceParameters.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = "";
        state.error = "";
        state.loading = false;
        state.consignments.performanceParams = payload.statusWise;
        let ontimeValue = payload.onTimePercentage;
        let roundedOntimeValue = ontimeValue && Math.round(ontimeValue.replace("%", ""));
        state.consignments.onTimePercentage = roundedOntimeValue || 0;
        let handOvervalue = payload.handOverOnTimePercentage;
        let roundedHandoverValue = handOvervalue && Math.round(handOvervalue.replace("%", ""));
        state.consignments.handOverOnTimePercentage = roundedHandoverValue || 0;
        state.consignments.slotWise = payload.slotWise || {};
        state.consignments.globalStateCount = payload.globalStateCount || {};
        state.consignments.totalConsignments = totalValues(payload.statusWise);
        state.consignments.formattedSlots = transformSlots(payload.slotWise);
        let transformedPerformance = transformPerformanceResponse(payload.slotWise);
        state.consignments.volumeData = transformDateValues(AppConstants.DASHBOARD_CONSTANTS.PERFORMANCE_CONSTANTS.SLOT_VALUES.Volume, transformVolumeResponse(payload.slotWise));
        state.consignments.countsData = transformDateValues(AppConstants.DASHBOARD_CONSTANTS.PERFORMANCE_CONSTANTS.SLOT_VALUES.Counts, transformedPerformance);
        state.consignments.percentageData = transformDateValues(AppConstants.DASHBOARD_CONSTANTS.PERFORMANCE_CONSTANTS.SLOT_VALUES.Percentages, transformedPerformance);
        state.consignments.paymentWise = transformDataIntoArray(AppConstants.DASHBOARD_CONSTANTS.PERFORMANCE_CONSTANTS.SLOT_VALUES.PaymentWise, payload.paymentWise);
        state.consignments.deliveryTypeWise = transformDataIntoArray(AppConstants.DASHBOARD_CONSTANTS.PERFORMANCE_CONSTANTS.SLOT_VALUES.DeliveryTypeWise, payload.deliveryTypeWise);

      })
      .addCase(fetchPerformanceParameters.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchConsignmentsSlots.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchConsignmentsSlots.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = "";
        state.error = "";
        state.loading = false;
        if (payload.allSlots.length) {
          state.allSlots = transformSelectValues(payload.allSlots);
        }
      })
      .addCase(fetchConsignmentsSlots.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchRoutesSlots.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchRoutesSlots.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = "";
        state.error = "";
        state.loading = false;
        if (payload.allSlots.length) {
          state.allSlots = transformSelectValues(payload.allSlots);
        }
      })
      .addCase(fetchRoutesSlots.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchRouteParameters.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchRouteParameters.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = "";
        state.error = "";
        state.loading = false;
        let ontimeValue = payload.onTimePercentage;
        let roundedOntimeValue = ontimeValue && Math.round(ontimeValue.replace("%", ""));
        state.routes.onTimePercentage = roundedOntimeValue || 0;
        let handOvervalue = payload.handOverOnTimePercentage;
        let roundedHandoverValue = handOvervalue && Math.round(handOvervalue.replace("%", ""));
        state.routes.handOverOnTimePercentage = roundedHandoverValue || 0;
        state.routes.unPlannedConsignments = payload.unPlannedConsignments;
        state.routes.slotWise = payload.slotWise || {};
        state.routes.globalStateCount = payload.globalStateCount || {};
        state.routes.formattedSlots = transformSlots(payload.slotWise);
        let routesTransformedPerformance = transformPerformanceResponse(payload.slotWise);
        state.routes.volumeData = transformDateValues(AppConstants.DASHBOARD_CONSTANTS.ROUTES_PERFORMANCE_CONSTANTS.SLOT_VALUES.Volume, transformVolumeResponse(payload.slotWise));
        state.routes.countsData = transformDateValues(AppConstants.DASHBOARD_CONSTANTS.ROUTES_PERFORMANCE_CONSTANTS.SLOT_VALUES.Counts, routesTransformedPerformance);
        state.routes.percentageData = transformDateValues(AppConstants.DASHBOARD_CONSTANTS.ROUTES_PERFORMANCE_CONSTANTS.SLOT_VALUES.Percentages, routesTransformedPerformance);
        state.routes.paymentWise = transformDataIntoArray(AppConstants.DASHBOARD_CONSTANTS.ROUTES_PERFORMANCE_CONSTANTS.SLOT_VALUES.PaymentWise, payload.paymentWise);

      })
      .addCase(fetchRouteParameters.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.routes = { ...routesInitialState }
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchRouteGlobalParams.pending, (state) => {
        state.errorCode = "";
        state.error = "";
        state.loading = true;
      })
      .addCase(fetchRouteGlobalParams.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = "";
        state.error = "";
        state.loading = false;
        state.routesGlobal.statusWise = payload.statusWise;
        state.routesGlobal.totalRoutes = totalValues(payload.statusWise);
        state.routesGlobal.deliveryTypeWise = transformDataIntoArray(AppConstants.DASHBOARD_CONSTANTS.ROUTES_PERFORMANCE_CONSTANTS.SLOT_VALUES.DeliveryTypeWise, payload.deliveryTypeWise);
      })
      .addCase(fetchRouteGlobalParams.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload
          ? errorPayload.error
          : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
  },
});

export default performanceSlice.reducer;
