import React from "react";

import AppConstants from "../../../constants/AppConstants";

import { Card, Grid, Typography } from "@material-ui/core";
import { ShowMessageInsideCard } from "../Utils/DelveDashboardViewUtils";
import { checkIfInputIsValidObjectWithKeys, getPercentageValue, isUndefined, isNull, isInputAnArray, isNonEmptyArray, getHubRecordList } from "../../../utils/helpers.utils";
import { TextConstants } from "../../../constants/TextConstants";
import { TAB_VALUES_CONSTANT } from "../../../constants/DelveDashboardConstant";

import RedirectIcon from "../../../assets/icons/RedirectIcon.svg";

interface DelveDashboardHubVerificationDetailsInterface {
  classes: any;
  recordObj: any;
  handleHubLevelVerificationCardClick: any;
  hubRecordList: any;
  countryCode: any;
  tabValue: any;
}

const DelveDashboardHubVerificationDetails = (props: DelveDashboardHubVerificationDetailsInterface) => {
  const { classes, recordObj, handleHubLevelVerificationCardClick, hubRecordList, countryCode, tabValue } = props;

  const isDashboardRecordContainshubLevelObj = () => {
    return !isUndefined(recordObj) && !isUndefined(recordObj.hubLevelVerificationData) && checkIfInputIsValidObjectWithKeys(recordObj.hubLevelVerificationData);
  };

  const getVerfiedOrdersCount = (hubData: any) => {
    let count = 0 as any;
    if (!isUndefined(hubData)) {
      if (!isUndefined(hubData.verifiedOrders)) {
        count = Number(hubData.verifiedOrders);
      }
      if (!isUndefined(hubData.partiallyVerifiedOrders)) {
        count = Number(count) + Number(hubData.partiallyVerifiedOrders);
      }
    }
    return count;
  };

  const getVerfiedIssuesOrdersCount = (hubData: any) => {
    let count = 0 as any;
    if (!isUndefined(hubData)) {
      if (!isUndefined(hubData.verifiedOrdersWithIssues)) {
        count = Number(hubData.verifiedOrdersWithIssues);
      }
      if (!isUndefined(hubData.partiallyVerifiedOrdersWithIssues)) {
        count = Number(count) + Number(hubData.partiallyVerifiedOrdersWithIssues);
      }
    }
    return count;
  };

  const getStoreNameFromHubCode = (hubName: any) => {
    if (
      !isUndefined(hubName) &&
      !isNull(hubName) &&
      !isUndefined(countryCode) &&
      !isUndefined(hubRecordList) &&
      !isUndefined(getHubRecordList(hubRecordList, countryCode))
    ) {
      let listData = getHubRecordList(hubRecordList, countryCode);
      if (!isUndefined(listData) && isInputAnArray(listData) && isNonEmptyArray(listData)) {
        let storeObj = listData.find((storeData: any) => storeData.value === hubName);
        if (!isUndefined(storeObj) && !isUndefined(storeObj.name) && !isNull(storeObj.name)) {
          return `- ${storeObj.name}`;
        }
      }
    }
    return "";
  };

  return (
    <Grid container spacing={0} className={classes.IssueSummaryMainGridContainer}>
      <Grid item xs={12} className={classes.issueGridItemStyle}>
        <Typography className={classes.issueGridItemSpanStyle}>{`Store Wise Summary`}</Typography>
      </Grid>

      {isDashboardRecordContainshubLevelObj() ? (
        <Grid container spacing={2}>
          {Object.keys(recordObj.hubLevelVerificationData).map((hubName: any, hubIndex: any) => (
            <Grid
              item
              xs={4}
              key={`${hubIndex}-hubIndex-hubLevelVerificationData-delveDashboardRecord-dashboardState`}
              className={classes.storeVerificationGridItemCard}
              onClick={() => handleHubLevelVerificationCardClick(hubName)}
            >
              <Grid container spacing={0} className={classes.storeVerificationGridContainerCard}>
                <Grid item xs={12} className={`${classes.storeVerificationItemRow} ${classes.issueNameSpanIconDiv}`}>
                  <Typography className={classes.storeVerificationHubSpanStyle}>{`${hubName || ""} ${getStoreNameFromHubCode(hubName)}`}</Typography>
                  <img src={RedirectIcon} alt="RedirectIcon" className={classes.issueRedirectIconSvgStyle} />
                </Grid>
                <Grid item xs={6} className={classes.storeVerificationItemRow}>
                  <Typography className={classes.storeVerificationHeader}>{`${
                    tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value ? TextConstants.ORDERS_VERIFIED : TextConstants.CONSIGNMENT_VERIFIED
                  }`}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.storeVerificationItemRow}>
                  <Typography className={classes.storeVerificationHeader}>{`${
                    tabValue === TAB_VALUES_CONSTANT.DELIVERIES.value ? TextConstants.ORDERS_WITH_ISSUES : TextConstants.CONSIGNMENT_WITH_ISSUES
                  }`}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.storeVerificationItemRow}>
                  <Typography className={classes.storeVerificationValue}>{`${getVerfiedOrdersCount(recordObj.hubLevelVerificationData[hubName])}/${
                    recordObj.hubLevelVerificationData[hubName]?.totalOrders || recordObj.hubLevelVerificationData[hubName]?.totalConsignments || "0"
                  } `}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.storeVerificationItemRow}>
                  <Typography className={classes.storeVerificationValue}>{`${getVerfiedIssuesOrdersCount(recordObj.hubLevelVerificationData[hubName])} / ${getVerfiedOrdersCount(
                    recordObj.hubLevelVerificationData[hubName]
                  )}`}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.storeVerificationItemRow}>
                  <Typography className={classes.storeVerificationVerifiedValue}>{`${
                    getPercentageValue(
                      recordObj.hubLevelVerificationData[hubName]?.totalOrders || recordObj.hubLevelVerificationData[hubName]?.totalConsignments,
                      getVerfiedOrdersCount(recordObj.hubLevelVerificationData[hubName])
                    ) || "0"
                  } %`}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.storeVerificationItemRow}>
                  <Typography className={classes.storeVerificationVerifiedWithIssueValue}>{`${getPercentageValue(
                    getVerfiedOrdersCount(recordObj.hubLevelVerificationData[hubName]),
                    getVerfiedIssuesOrdersCount(recordObj.hubLevelVerificationData[hubName])
                  )} %`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Card className={classes.storeVerificationGridCard}>
          <ShowMessageInsideCard classes={classes} message={`No Data Available`} />
        </Card>
      )}
    </Grid>
  );
};

export default React.memo(DelveDashboardHubVerificationDetails);
