import React, {useCallback, forwardRef, ReactElement, Ref, useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
//common components
import CustomDialog from '../common/Dialog';
import TextBox from '../common/TextBox';
//redux
import {closeDialog, fetchDriversList, assignDriverManually, getAddressStatus} from './redux/expressSlice';
import {AppState} from "../config/redux/reducers";
//material-ui components
import {TransitionProps} from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import Lens from "@material-ui/icons/Lens";
import {
    Grid,
    Typography,
    ListItemText,
    Box,
    List,
    ListItem,
    Button,
    IconButton,
    InputAdornment,
    SvgIcon,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
//assets
import bikeCircledIcon from './../assets/icons/bike-icon.svg';
import carCircledIcon from './../assets/icons/car-circled.svg';
import carIcon from './../assets/icons/Car.svg';
import bikeIcon from './../assets/icons/bike.svg';
import locationIcon from './../assets/icons/map-pin.svg';
import refreshIcon from './../assets/icons/refresh.svg';
import clockIcon from './../assets/icons/clock.svg';
import verifiedIcon from './../assets/icons/verified-icon.svg';
import unverifiedIcon from './../assets/icons/unverified-icon.svg';
import pendingIcon from './../assets/icons/pending-icon.svg';
//styles
import {useStyles} from './DriverAssignment.styles';
import AppConstants from "../constants";
import {getAddressInfo} from 'mocks/routePlanning/response.transforms';
//utils
import {localTimeString} from 'utils/helpers.utils';
import {
    createPayloadToFetchDriverList,
    driverStatusField,
    createPayloadToAssignDriver,
    getWaitTimeForDriver,
    createPayloadToMarkAddressUnverified
} from './../mocks/express/response.transforms';
//constants
import {ADDRESS_STATUS} from '../constants/CommonConstants';

interface DriverAssignmentProps {
    open: boolean;
    consignment: any;
    closePopup: () => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const DriverAssignment = (props: DriverAssignmentProps) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const {driversList, consignmentDataAfterAddressUpdate} = useSelector(
        (state: AppState) => state.expressDashboard
    )
    const {countryCode} = useSelector(
        (state: AppState) => state.common
    );
    const {open, consignment, closePopup} = props;

    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isDriverSelected, setIsDriverSelected] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [listToShow, setListToShow] = useState<any>([]);
    const [addressVerified, setAddressVerified] = useState(false);
    const [openAddressPopup, setOpenAddressPopup] = useState(false);
    let deliveryInformation: any = {};
    let metadata: any = {};
    let custInfo: any = {
        address: {}
    };
    let deliveryTime: any;
    let items: any = [];
    let deliveryInformationAfterAddressUpdate: any = {};
    let custInfoAfterAddressUpdate: any = {
        address: {}
    }
    let addressStatusAfterUpdate = ""
    let isCheckboxDisabled = false;

    if (consignment) {
        deliveryInformation = consignment.deliveryInformation;
        metadata = consignment.metadata;
        custInfo = deliveryInformation && deliveryInformation.customerContactInfo;
        if (deliveryInformation && deliveryInformation.deliverySlot) {
            const endTime = new Date(deliveryInformation.deliverySlot.endTime);
            deliveryTime = `${localTimeString(endTime)}`;
        }
        items = consignment.products;
    }
    if (consignmentDataAfterAddressUpdate) {
        deliveryInformationAfterAddressUpdate = consignmentDataAfterAddressUpdate.deliveryInformation;
        custInfoAfterAddressUpdate = deliveryInformationAfterAddressUpdate?.customerContactInfo;
        addressStatusAfterUpdate = custInfoAfterAddressUpdate?.address?.status;
        if (addressStatusAfterUpdate === ADDRESS_STATUS.UNVERIFIED) {
            isCheckboxDisabled = true;
        }
    }

    const dialogPaperProps = {
        classes: {
            root: classes.dialogPaperPropsRoot
        },
        square: true
    }

    const confirmDialogPaperProps = {
        classes: {
            root: classes.confirmDialogPaperPropsRoot
        }
    };

    const fetchDriversLists = useCallback(
        (consignment: any, countryCode: any) => {
            if (Object.keys(consignment).length) {
                const payload = createPayloadToFetchDriverList(countryCode, consignment.posInformation);
                dispatch(
                    fetchDriversList({
                        payload: payload
                    })
                );
                setSelectedIndex(null);
            }
        },
        [dispatch]
    );

    const getStatusField = (fieldName: string, statusCode: string) => {
        const statuses: any = AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
        return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
    }

    const handleDialogClose = useCallback(
        () => {
            closePopup();
            setSelectedIndex(null);
            dispatch(
                closeDialog()
            );
        },
        [closePopup, dispatch],
    );

    const manuallyAssignDriver = useCallback(
        (selectedDriver: any, consignment: any) => {
            const payload = createPayloadToAssignDriver(selectedDriver, consignment);
            dispatch(
                assignDriverManually({
                    payload: payload
                })
            );
            closePopup();
        },
        [dispatch, closePopup]
    );

    const handleListItemClick = (event: any, index: any) => {
        setSelectedIndex(index);
        let selectedItem = driversList.at(index);
        if (selectedItem.status === AppConstants.EXPRESS_CONSTANTS.DRIVER_STATUS.OUT_FOR_DELIVERY || selectedItem.category === AppConstants.EXPRESS_CONSTANTS.DRIVERS_CATEGORY.OTHERS) {
            setIsDriverSelected(false);
        } else {
            setIsDriverSelected(true);
        }
    };

    const handleSearchBoxTextChange = (event: any) => {
        const searchValue = event.target.value;
        setSearchValue(searchValue);
    };

    const handleSearch = () => {
        if (searchValue === "") {
            setListToShow(driversList);
        } else {
            const searchedDriverList = [...driversList].filter((driver: any) => {
                const name = driver.driverName.toLowerCase();
                const search = searchValue.toLowerCase();
                return name.includes(search);
            });
            setListToShow(searchedDriverList);
        }
    };

    const handleAssignDriver = () => {
        let selectedDriver = listToShow.at(selectedIndex);
        if (selectedDriver) {
            manuallyAssignDriver(selectedDriver, consignment);
        }
    };

    const handleCancel = () => {
        setSelectedIndex(null);
        setListToShow(driversList);
        setSearchValue("");
        setIsDriverSelected(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddressVerified(event.target.checked);
        setOpenAddressPopup(true)
    };

    const handleMarkAddressVerified = useCallback(
        (consignment: any, address: boolean) => {
            const payload = createPayloadToMarkAddressUnverified(consignment, address);
            dispatch(
                getAddressStatus({
                    params: payload
                })
            );
            setOpenAddressPopup(false);
        },
        [dispatch]
    )

    const updateAddressDialogActionHandler = useCallback(
        (type: string, order?: string, address?: any) => {
            if (type === 'yes') {
                setAddressVerified(true);
                handleMarkAddressVerified(order, true);
            } else {
                setOpenAddressPopup(false);
                setAddressVerified(false);
            }
        },
        [handleMarkAddressVerified],
    )

    const addressStatus = (status: any) => {
        if (status === ADDRESS_STATUS.VERIFIED) {
            return <img src={verifiedIcon} alt="verified-icon"/>
        } else {
            if (status === ADDRESS_STATUS.UNVERIFIED) {
                return <img src={unverifiedIcon} alt="unverified-icon"/>
            } else {
                return <img src={pendingIcon} alt="pending-icon"/>
            }
        }
    }

    const getTitleContent = () => {
        return (
            <Grid className={classes.titleContainer} container>
                <Grid className="headingContainer" container>
                    <Grid container alignItems="center">
                        <Typography className="titleHeading">
                            <span className="label"> Driver Assignment </span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const getDriversList = () => {
        return listToShow && listToShow.length ? listToShow.map((driver: any, index: number) => (
            <ListItem
                className={`list-container ${driver.category.toUpperCase() === AppConstants.EXPRESS_CONSTANTS.DRIVERS_CATEGORY.ASSIGNED ? `assignedList` : driver.category.toUpperCase() === AppConstants.EXPRESS_CONSTANTS.DRIVERS_CATEGORY.UNASSIGNED ? `unassignedList` : ''}`}
                button
                selected={selectedIndex !== null && selectedIndex === index}
                onClick={(event: any) => handleListItemClick(event, index)}
                classes={{
                    selected: classes.selected
                }}>
                < ListItemText>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={4}
                              style={{fontSize: "14px", fontWeight: 500, display: "flex", flexDirection: "column",
                                  wordWrap: "break-word", wordBreak: "break-all", maxWidth: "100%"}}>
                            <span>{driver.driverName}</span>
                        </Grid>
                        <Grid item xs={2}
                              style={{textAlign: "center"}}>{driver.vehicleType === AppConstants.EXPRESS_CONSTANTS.VEHICLE_TYPE.BIKE ?
                            <img src={bikeIcon} alt="Bike"/> : <img src={carIcon} alt="car"></img>}</Grid>
                        <Grid item xs={3} style={{textAlign: "center"}}
                              className={['driverStatus', driverStatusField('color', driver.status),].join(" ")}>{driver.status}</Grid>
                        <Grid item xs={2} style={{textAlign: "center", fontSize: "14px", fontWeight: 500}}>
                            <Grid container direction="column">
                                <Grid
                                    item>{driver.category === AppConstants.EXPRESS_CONSTANTS.DRIVERS_CATEGORY.OTHERS ? "-" : driver.proximityToHub === 0.0 ? "In hub" : driver.proximityToHub + " km"}</Grid>
                                {(driver.status === AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.DRIVER_STATUSES.AVAILABLE.value || (driver.status === AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.DRIVER_STATUSES.ASSIGNED.value && driver.proximityToHub === 0.0)) ? (
                                    <Grid item style={{
                                        marginTop: "4px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <img src={clockIcon} alt="clock"
                                             style={{height: "12px", width: "12px", marginRight: "4px"}}/>
                                        <span
                                            style={{fontSize: "12px"}}>{getWaitTimeForDriver(driver.hubCheckInTime) + " mins"}</span>
                                    </Grid>
                                ) : ''}
                            </Grid>
                        </Grid>
                        <Grid item xs={1} style={{
                            textAlign: "right",
                            fontSize: "14px",
                            fontWeight: 500
                        }}>{driver.assignments}</Grid>
                    </Grid>
                </ListItemText>
            </ListItem>
        )) : (<Typography className={classes.noData}>No Drivers to display</Typography>)
    }

    const getDetailsContent = () => {
        return (
            <Grid className={classes.mycontentContainer}>

                <Grid className="detailsHeader" container>
                    <Grid container justify="space-between">
                        <Grid item style={{display: 'flex'}}>
                            <Grid item className="deliveryDetails" style={{
                                marginTop: "0px",
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "8px"
                            }}>
                                <span className="delivery">Consignment ID :</span>
                                <span className="deliveryTimeDetails time">{consignment.consignmentCode}</span>
                            </Grid>
                            {
                                metadata && metadata.deliveryStatus &&
                                <Grid className="tagContainer" item style={{display: "flex", alignItems: "center"}}>
                                    <Typography
                                        className={['tag', getStatusField('color', metadata && metadata.deliveryStatus)].join(" ")}>{metadata && metadata.deliveryStatus ? metadata.deliveryStatus : ""}</Typography>
                                </Grid>
                            }
                        </Grid>
                        <Grid item style={{display: "flex"}}>
                            <Typography
                                className="vehicleType">{deliveryInformation && deliveryInformation.preferredVehicle ? deliveryInformation.preferredVehicle : ""} Delivery</Typography>
                            {deliveryInformation && deliveryInformation.preferredVehicle === AppConstants.EXPRESS_CONSTANTS.VEHICLE_TYPE.BIKE ?
                                <img src={bikeCircledIcon} alt="bike"/>
                                :
                                <img src={carCircledIcon} alt="car-circled"/>
                            }
                        </Grid>
                    </Grid>
                    <Grid container className="deliveryDetails" justify="space-between">
                        <Grid item>
                            <Typography className="delivery">Delivery Time</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Typography className="deliveryTimeDetails">{deliveryTime}</Typography>
                                {deliveryInformation && deliveryInformation.deliverySlot && deliveryInformation.deliverySlot.promiseDeliveryTime &&
                                    <Typography
                                        className="deliveryTimeDetails time">({deliveryInformation.deliverySlot.promiseDeliveryTime + " mins"})</Typography>
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container className="deliveryDetails" justify="space-between">
                        <Grid item>
                            <Typography className="delivery">Total Items</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container justify="space-around">
                                <Typography className="deliveryTimeDetails">{items && items.length}</Typography>
                                {deliveryInformation && deliveryInformation.totalWeight &&
                                    <Typography
                                        className="deliveryTimeDetails time">({deliveryInformation.totalWeight + " kg"})</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="deliveryDetails">
                        <Typography className="deliveryTimeDetails">Customer Details</Typography>
                    </Grid>
                    <Grid container className="deliveryDetails">
                        <Grid item>
                            <Typography className="delivery">{custInfo && custInfo.name}</Typography>
                        </Grid>
                        {addressStatusAfterUpdate ? (
                                <Grid item style={{marginLeft: "12px"}}>
                                    {addressStatusAfterUpdate === ADDRESS_STATUS.UNVERIFIED ?
                                        <img src={unverifiedIcon} alt="info-icon"/>
                                        : ""}
                                </Grid>
                            ) :
                            <Grid item style={{marginLeft: "12px"}}>
                                {addressStatus(custInfo?.address?.status)}
                            </Grid>
                        }
                    </Grid>
                    <Grid item className="deliveryDetails" style={{display: "flex"}}>
                        <img className="location" src={locationIcon} alt="location"></img>
                        <Typography
                            className="delivery">{getAddressInfo(custInfo && custInfo.address ? custInfo.address : "")}</Typography>

                    </Grid>
                    {!(custInfo?.address?.status === ADDRESS_STATUS.UNVERIFIED) &&
                        <Grid container className="deliveryDetails">
                            <FormControlLabel
                                classes={{root: classes.checkbox}}
                                label={<Typography style={{
                                    fontSize: "12px",
                                    color: "#0E5AA7",
                                    lineHeight: "16px",
                                    fontWeight: 700,
                                    marginLeft: "12px"
                                }}>Mark address as inaccurate</Typography>}
                                control={
                                    <Checkbox
                                        disabled={isCheckboxDisabled}
                                        checked={addressVerified}
                                        size="small"
                                        color="primary"
                                        onChange={handleChange}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                }
                            />
                        </Grid>
                    }
                </Grid>
                <Grid className="driverComponent">
                    <Typography className="driverTitle">Drivers List</Typography>
                    <img src={refreshIcon} alt="refresh" className="refresh"
                         onClick={() => fetchDriversLists(consignment, countryCode)}/>
                </Grid>
                <Grid container style={{marginTop: "8px"}}>
                    <Grid item xs={4} style={{display: "flex", alignItems: "center"}}>
                        <Grid container style={{display: "flex"}}>
                            <Grid item className={classes.main} xs={6}>
                                <Lens className={classes.unassignedStatusIcon}/>
                                <Typography className={classes.legend}>Unassigned</Typography>
                            </Grid>
                            <Grid item className={classes.main} xs={6} style={{paddingLeft: "6px"}}>
                                <Lens className={classes.assignedStatusIcon}/>
                                <Typography className={classes.legend}>Assigned</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.searchTextbox} item xs={8}>
                        <TextBox
                            variant="outlined"
                            onChange={handleSearchBoxTextChange}
                            value={searchValue}
                            textBoxId="searchTextBox"
                            type="text"
                            placeholderText="Search by driver name"
                        ></TextBox>
                    </Grid>
                </Grid>
                <Grid className="driverListContainer" container direction="column">
                    <Grid container className="itemContainer header" justify="space-between" alignItems="center">
                        <Grid item xs={4}>Driver Name</Grid>
                        <Grid item xs={2} style={{textAlign: "center"}}>Vehicle</Grid>
                        <Grid item xs={3} style={{textAlign: "center"}}>Status</Grid>
                        <Grid item xs={2} style={{textAlign: "center"}}>
                            <span>Proximity<br/>to hub</span>
                        </Grid>
                        <Grid item xs={1} style={{textAlign: "right"}}>
                            Orders
                        </Grid>
                    </Grid>
                    <Box style={{maxHeight: "344px", overflow: "scroll"}}>
                        <List component="nav" aria-label="driversList" style={{paddingTop: "0px"}}>
                            {getDriversList()}
                        </List>
                    </Box>
                    <Grid className="buttonsContainer" container>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6} style={{display: "flex", justifyContent: "flex-end"}}>
                            <Button className="userBtn secondary" variant="contained"
                                    onClick={() => handleCancel()}>{AppConstants.BUTTONS.CLEAR_NEW}</Button>
                            <Button className="userBtn primary" variant="contained" disabled={!isDriverSelected}
                                    onClick={() => handleAssignDriver()}>{AppConstants.BUTTONS.ASSIGN}</Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        )
    }

    const handleAddressDialogClose = useCallback(
        () => {
            setOpenAddressPopup(false);
        },
        [],
    );

    const getAddressDialogTitleContent = () => {
        return (
            <Grid className={classes.titleContainer} container>
            </Grid>
        )
    }

    const getAddressDialogDetailsContent = () => {
        return (
            <Grid className={classes.confirmDialogContentContainer} container>
                <Grid className="contentItem heading" item>
                    <Typography style={{fontSize: "14px", fontWeight: 500, color: "#252525"}}>
                        Do you want to mark this address unverified?</Typography>
                </Grid>
            </Grid>
        )
    };

    const getAddressDialogActions = () => {
        return (
            <Grid className={classes.confirmDialogButtonsContainer} container>
                <Button className="dialogBtn secondary" variant="contained" onClick={() => {
                    updateAddressDialogActionHandler('no')
                }}>{AppConstants.BUTTONS.NO}</Button>
                <Button className="dialogBtn primary" variant="contained" onClick={() => {
                    updateAddressDialogActionHandler('yes', consignment, addressVerified)
                }}>{AppConstants.BUTTONS.YES}</Button>
            </Grid>
        )
    }

    useEffect(() => {
        if (driversList && driversList.length) {
            setListToShow(driversList);
            setSelectedIndex(null);
        }
    }, [driversList])

    useEffect(() => {
        if (consignment !== null) {
            setSelectedIndex(null);
            fetchDriversLists(consignment, countryCode);
        }
    }, [fetchDriversLists, countryCode, consignment]);

    useEffect(() => {
        handleSearch();
    }, [searchValue]);

    return (
        <>
            <CustomDialog
                open={open}
                TransitionComponent={Transition}
                PaperProps={dialogPaperProps}
                title={getTitleContent()}
                content={getDetailsContent()}
                actions={null}
                handleClose={handleDialogClose}
            ></CustomDialog>
            <CustomDialog
                hideCloseIcon={true}
                open={openAddressPopup}
                PaperProps={confirmDialogPaperProps}
                title={getAddressDialogTitleContent()}
                content={getAddressDialogDetailsContent()}
                actions={getAddressDialogActions()}
                handleClose={handleAddressDialogClose}
            ></CustomDialog>
        </>
    )
}

export default DriverAssignment;