import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { transformSelectValues } from "../../utils/helpers.utils";
import { PosState } from "./posSlice.types";
import AppConstants from "../../constants";



export const fetchHubDetails = createAsyncThunk(
  "POS/FetchHubDetails",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "hub/filter",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAddHubCountryDropdownValues = createAsyncThunk(
  "POS/FetchAddUserDropdownValues",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `user/populate`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const regenerateQR = createAsyncThunk(
  "POS/RegenerateQR",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `hub/qr/generate/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, false, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const closeDialog = createAsyncThunk("POS/CloseDialog", () => {
  return false;
});

export const resetHubCreationStatus = createAsyncThunk("Users/ResetHubCreationStatus", () => {
  return false;
});

export const resetHubUpdationStatus = createAsyncThunk("Users/ResetHubUpdationStatus", () => {
  return false;
});


const initialState: PosState = {
  loading: false,
  error: "",
  tableLoading: false,
  hubsData: {
    elements: [],
    pageNumber: 0,
  },
  hubCreationStatus: false,
  hubCreationMsg: "",
  hubUpdateStatus: false,
  hubUpdateMsg: "",
  addUserDropdowns: {
    countriesList: []
  },
  regeneratedQRCode: null,
  closedDetails: false,
  isQRRegenerated: false
};

const POSSlice = createSlice({
  name: "POS",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchHubDetails.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchHubDetails.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { params }
          }
        } = action;
        state.loading = false;
        state.closedDetails = false;
        state.isQRRegenerated = false;
        state.hubsData = {
          ...payload,
          elements:
            params.pageNumber === 0
              ? [...payload.results.elements]
              : [...state.hubsData.elements, ...payload.results.elements],
          pageNumber: payload.results.pageNumber,
          totalElements: payload.results.totalElements,
          totalPages: payload.results.totalPages
        };
      })
      .addCase(fetchHubDetails.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAddHubCountryDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAddHubCountryDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          let countriesList: any = {};
          if (payload.roleCountryMap) {
            Object.keys(payload.roleCountryMap).filter((role: any) => {
              countriesList[role] = transformSelectValues(payload.roleCountryMap[role]);
              return true;
            });
          }
          state.errorCode = "";
          state.error = "";
          state.addUserDropdowns = {
            countriesList: countriesList,
          };
        }
      })
      .addCase(fetchAddHubCountryDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.addUserDropdowns = {
          countriesList: {}
        };
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(regenerateQR.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(regenerateQR.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.regeneratedQRCode = payload && payload.results.path;
        state.isQRRegenerated = true;
      })
      .addCase(regenerateQR.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(closeDialog.fulfilled, (state, action) => {
        state.regeneratedQRCode = null;
        state.closedDetails = true;
      })
  },
});

export default POSSlice.reducer;
