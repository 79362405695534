import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";
import { boxShadow } from "styled-system";
import { marginTop } from "styled-system";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      "& .headingContainer": {
        flexDirection: "column",
        "& .titleHeading": {
          fontSize: 18,
          fontWeight: 500,
          "& .content": {
            fontWeight: 600,
          },
        },
        "& .titleContent": {
          fontSize: 12,
          fontWeight: 600,
          margin: 'auto 0px',
          "&.link": {
            cursor: "pointer",
          },
          '&.ORANGE': {
            color: COLORS.GOLDEN_BELL
          },
          '&.SUCCESS_GREEN': {
            color: COLORS.PERSIAN_GREEN_DARK
          },
        },
      },
      "& .tagContainer": {
        marginLeft: theme.spacing(1),
        "& .tag": {
          color: COLORS.WHITE,
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.5, 1),
          lineHeight: "14px",
          backgroundColor: COLORS.BLACK,
          fontWeight: 600,
          textTransform: "uppercase",
          fontSize: 12,
          "&.PICTON_BLUE": {
            backgroundColor: COLORS.PICTON_BLUE,
          },
          "&.CREAM_CAN": {
            backgroundColor: COLORS.CREAM_CAN,
          },
          "&.VISTA_BLUE": {
            backgroundColor: COLORS.VISTA_BLUE,
          },
          "&.SEA_GREEN": {
            backgroundColor: COLORS.SEA_GREEN,
          },
          "&.GOLDEN_BELL": {
            backgroundColor: COLORS.GOLDEN_BELL,
          },
          "&.SCAMPI": {
            backgroundColor: COLORS.SCAMPI,
          },
          "&.BLUE": {
            backgroundColor: COLORS.PRIMARY_MAIN,
          },
          "&.SEA_GREEN2": {
            backgroundColor: COLORS.SEA_GREEN2,
          },
          "&.LUCKY": {
            backgroundColor: COLORS.LUCKY,
          },
          "&.DUSTY_GRAY": {
            backgroundColor: COLORS.DUSTY_GRAY,
          },
          "&.JUNGLE_GREEN": {
            backgroundColor: COLORS.JUNGLE_GREEN,
          },
          "&.BLACK": {
            backgroundColor: COLORS.BLACK,
          },
          "&.MINSK": {
            backgroundColor: COLORS.SCAMPI,
            color: COLORS.WHITE,
          },
        },
      },
      "& .editIcon": {
        paddingLeft: theme.spacing(1),
        "& .icon": {
          cursor: "pointer",
          verticalAlign: "middle",
          fill: "none",
        },
         "& .refreshText": {
            color: COLORS.MYSTIC_GREY,
            fontSize: 12,
          },
           "& .refresh-block": {
            cursor: "default !important",
          },
          
      },
    },
    tabs: {
      paddingTop: "0px",
      paddingLeft: "0px",
      minHeight: "38px",
      '& .tabLabel': {
        fontSize: "14px",
        fontWeight: 700
      }
    },
    contentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      '& .tabValues': {
        padding: "8px 0px 0px 0px",
      },
      "& .geocodedAddress": {
        paddingBottom: theme.spacing(2),
        "& .addressColItem": {
          alignSelf: "flex-start",
        },
        "& .geoLocAddressContainer": {
          cursor: "pointer",
          "& .address": {
            fontSize: 12,
            fontWeight: 600,
            color: theme.palette.primary.main,
            lineHeight: "14px",
            paddingRight: 3,
          },
          "& .addressLogo": {
            width: 12,
            height: 12,
            transform: "rotate(0deg)",
            transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            "&.open": {
              transform: "rotate(180deg)",
            },
          },
        },
        "& .addressMapContainer": {
          height: 243,
          border: `1px solid ${COLORS.SILVER}`,
          borderRadius: theme.spacing(1),
          marginTop: theme.spacing(1),
          backgroundColor: COLORS.SILVER,
        },
      },
      "& .detailsHeader": {
        flexDirection: "column",
        backgroundColor: COLORS.TRANSPARENT_BLUE,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        "& .detailsHeaderItem": {
          paddingBottom: theme.spacing(1),
          "&:last-child": {
            paddingBottom: 0,
          },
          "& .headerItemContainer": {
            alignItems: "center",
            flexWrap: "nowrap",
          },
          "& .contactItemContainer": {
            alignItems: "center",
            flexWrap: "nowrap",
          },
          "& p": {
            lineHeight: "17px",
            fontSize: 14,
          },
          "& .name": {
            fontWeight: 600,
          },
          "&.geocodedAddress": {
            paddingBottom: theme.spacing(2),
            "& .addressColItem": {
              alignSelf: "flex-start",
            },
            "& .geoLocAddressContainer": {
              cursor: "pointer",
              "& .address": {
                fontSize: 12,
                fontWeight: 600,
                color: theme.palette.primary.main,
                lineHeight: "14px",
                paddingRight: 3,
              },
              "& .addressLogo": {
                width: 12,
                height: 12,
                transform: "rotate(0deg)",
                transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                "&.open": {
                  transform: "rotate(180deg)",
                },
              },
            },
            "& .addressMapContainer": {
              height: 243,
              border: `1px solid ${COLORS.SILVER}`,
              borderRadius: theme.spacing(1),
              marginTop: theme.spacing(1),
              backgroundColor: COLORS.SILVER,
            },
          },
          "& .contactMobile": {
            marginRight: theme.spacing(5),
          },
          "& .logo": {
            width: 24,
            height: 24,
          },
          "& .contact": {
            "& a": {
              lineHeight: "17px",
              fontSize: 14,
              fontWeight: 600,
              textDecoration: "none",
              color: theme.palette.text.primary,
            },
            paddingLeft: theme.spacing(1),
            "&.mobile": {
              paddingLeft: theme.spacing(0.25),
            },
          },
        },
      },
      "& .detailsContent": {
        flexDirection: "column",
        flexWrap: "nowrap",
        padding: theme.spacing(3, 0, 2, 0),
        "& .contentRow": {
          paddingBottom: theme.spacing(3),
          "&.hubDetails": {
            paddingBottom: theme.spacing(1),
          },
          "&:last-child": {
            paddingBottom: 0,
          },
          "& .contentColumn": {
            flex: "2 0 0",
            "&:last-child": {
              flex: "1 0 0",
            },
            "& p": {
              fontSize: 14,
              lineHeight: "17px",
            },
            "& .label": {
              paddingBottom: theme.spacing(1),
            },
            "& .content": {
              fontWeight: 600,
            },
          },
        },
      },
      "& .sellerDetails": {
        paddingTop: theme.spacing(1.75),
        "& .detailsHeader": {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      "& .deliveryProofSection": {
        paddingBottom: theme.spacing(1),
        "& .content": {
          width: "100%",
          "& .deliveryProofContainer": {
            flexDirection: "column",
            "& .deliveryProofItem": {
              paddingBottom: theme.spacing(1.5),
              "&:last-child": {
                paddingBottom: 0,
              },
              "& .imagesContainer": {
                flexWrap: "nowrap",
                "& .imagesItem": {
                  marginRight: theme.spacing(1.5),
                  width: "50%",
                  height: 145,
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: theme.spacing(1),
                  padding: theme.spacing(2),
                  "&:last-child": {
                    marginRight: 0,
                  },
                  "& .imageItemContainer": {
                    flexDirection: "column",
                    "& .imageItem": {
                      paddingBottom: theme.spacing(2),
                      "& .heading": {
                        fontSize: 14,
                        fontWeight: 500,
                      },
                      "& .allImagesContainer": {
                        flexWrap: "nowrap",
                        overflow: "auto",
                        "& .viewerContainer": {
                          display: "flex",
                        },
                        "&.sign": {
                          justifyContent: "space-around",
                        },
                        "& img": {
                          width: "auto",
                          height: 64,
                          cursor: "pointer",
                          marginRight: theme.spacing(1.5),
                          "&:last-child": {
                            marginRight: 0,
                          },
                        },
                      },
                    },
                  },
                },
              },
              "& .commentsContainer": {
                flexDirection: "column",
                padding: theme.spacing(1),
                backgroundColor: theme.palette.secondary.main,
                borderRadius: theme.spacing(1),
                "& .commentsItem": {
                  paddingBottom: theme.spacing(0.75),
                  "& p": {
                    fontWeight: 500,
                    fontSize: 12,
                  },
                  "& .heading": {
                    fontSize: 14,
                  },
                },
              },
            },
          },
        },
      },
      "& .consignmentHistory": {
        paddingBottom: theme.spacing(1),
      },
      "& .tabularSection": {
        paddingBottom: theme.spacing(1),
        "&:last-child": {
          paddingBottom: 0,
        },
        "&.crates": {
          "& .itemsContainer .item .itemContainer .itemColumn.item1 p": {
            fontWeight: 600,
          },
          "& .itemsContainer .item .itemContainer .itemColumn.item1 .header p": {
            fontWeight: 500,
          },
          "& .crateBarcode": {
            width: 40,
            height: 15,
            marginLeft: theme.spacing(3),
          },
          "& .crateStageLocationIcon": {
            width: 16,
            height: 16,
            marginRight: theme.spacing(1),
          },
        },
        "& .itemDiv": {
          padding: theme.spacing(0),
          "& .content": {
            width: "100%",
            "& .itemsContainer": {
              flexWrap: "nowrap",
              "& .item": {
                padding: theme.spacing(2, 0),
                borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                "&:first-child": {
                  padding: 0,
                },
                "&:last-child": {
                  borderBottom: "none",
                },
                "& .itemContainer": {
                  padding: theme.spacing(0, 2),
                  "&.items": {
                    backgroundColor: COLORS.SECONDARY_MAIN,
                    padding: theme.spacing(2),
                  },
                  "& p": {
                    fontSize: 14,
                    lineHeight: "17px",
                    fontWeight: 600,
                  },
                  "&.header p": {
                    color: COLORS.DOVE_GRAY,
                    fontWeight: 500,
                  },
                  "& .itemColumn": {
                    padding: theme.spacing(0),
                    "&.item1": {
                      "& p": {
                        fontWeight: 400,
                        textTransform: "capitalize",
                      },
                      "& .rejectedReason": {
                        fontSize: 10,
                        color: COLORS.ALIZARIN_CRIMSON,
                      },
                      "& .viewOriginalLink": {
                        fontSize: 9,
                        color: COLORS.COPY,
                        cursor: "pointer",
                        paddingRight: 6,
                      },
                      "& .originalName": {
                        fontSize: 14,
                        lineHeight: "17px",
                        fontWeight: 400,
                      },
                    },
                    "&.item2": {
                      "& p": {
                        textAlign: "center",
                        "& .green": {
                          color: COLORS.PERSIAN_GREEN,
                        },
                      },
                    },
                    "&.item3": {
                      "& p": {
                        textAlign: "right",
                      },
                    },
                  },
                },
              },
            },
            "& .paymentContainer": {
              padding: 16,
              paddingTop: 13,
              "& .totalDivider": {
                margin: "11px 0px",
              },
              "& .paymentItem": {
                "&:last-child p": {
                  fontWeight: 600,
                },
                padding: "5px 0",
                "& .paymentItemColumn": {
                  "& p": {
                    fontSize: 14,
                    lineHeight: "17px",
                  },
                  "& .vat": {
                    fontWeight: 400,
                    paddingLeft: theme.spacing(0.25),
                  },
                  "&.right": {
                    marginLeft: "auto",
                  },
                },
              },
            },
          },
        },
      },
    },
    accordionSummary: {
      "& .header": {
        fontSize: 14,
        fontWeight: 600,
        paddingBottom: theme.spacing(0.5),
        "&:last-child": {
          paddingBottom: 0,
        },
        "& .smallHeading": {
          fontSize: 12,
        },
      },
      "& .content": {
        flexGrow: 1,
      },
    },

    timeline: {
      padding: "0 !important",
      margin: 0,
      "& .timelineItem": {
        minHeight: 50,
        "&:last-child": {
          minHeight: "auto",
        },
        "&:before": {
          content: "none",
        },
        "& .timelineDot": {
          padding: 0,
          margin: 0,
          border: "none",
          boxShadow: "none",
          backgroundColor: "transparent",
          "& .dotIcon": {
            width: 18,
            height: 18,
            padding: 5,
            "&.active": {
              padding: 0,
            },
          },
        },
        '& .lastModifiedContent': {
          fontSize: "12px",
          lineHeight: "10px",
          color: COLORS.DOVE_GRAY_DARK,
          paddingTop: "6px",
          '& .name': {
            marginLeft: "4px"
          }
        },
        '& .timelineContentItem': {
          padding: "8px 0px"
        },
        "& .primaryConnector": {
          backgroundColor: COLORS.TRANSPARENT,
          borderLeft: `1px dashed ${COLORS.SILVER}`,
        },
        "& .timelineContent": {
          padding: 0,
          paddingLeft: theme.spacing(1),
          "& .heading": {
            paddingTop: 1,
            fontSize: 14,
            lineHeight: "14px",
            fontWeight: 600,
            color: theme.palette.primary.main,
            "&.error": {
              color: COLORS.DARK_RED,
              "&.active": {
                paddingBottom: 17,
              },
            },
            "&.active": {
              // paddingBottom: 17
            },
            "& .dateTime": {
              fontSize: 12,
              lineHeight: "15px",
              fontWeight: 500,
              color: COLORS.DOVE_GRAY_DARK,
              paddingLeft: theme.spacing(0.5),
              verticalAlign: "bottom",
            },
          },
          "& .metadataContainer": {
            paddingBottom: theme.spacing(1.5),
            "& .title": {
              fontSize: 12,
              lineHeight: "10px",
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              fontWeight: 500,
              color: COLORS.COPY,
            },
            "& .description": {
              fontSize: 10,
              lineHeight: "10px",
              color: COLORS.DOVE_GRAY_LIGHT,
            },
          },
        },
      },
    },
    accordionBundle: {
      flexGrow: 1,
      "&.MuiAccordion-root":{
        boxShadow:'none !important'
      },
      "& .header": {
        fontSize: 14,
        fontWeight: 600,
        paddingBottom: theme.spacing(0.5),
        "&:last-child": {
          paddingBottom: 0,
        },
        "& .smallHeading": {
          fontSize: 12,
        },
      },
      "& .content": {
        flexGrow: 1,
      },
    },
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 680,
    },
    checkbox: {
      marginLeft: "0px",
    },
    addressLabel: {
      fontSize: "12px",
      color: COLORS.PRIMARY_MAIN,
      lineHeight: "16px",
      fontWeight: 700,
      marginLeft: "12px",
    },
    confirmDialogPaperPropsRoot: {
      width: 338,
      height: 214,
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    confirmDialogContentContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
      "& .contentItem": {
        "& p": {
          lineHeight: 1.2,
        },
        "&.heading": {
          "& p": {
            fontSize: 24,
            fontWeight: 600,
          },
        },
        "&.desc": {
          padding: theme.spacing(0, 2.5),
          "& p": {
            fontSize: 18,
            fontWeight: 400,
            "& .count": {
              fontWeight: 600,
            },
          },
        },
      },
    },
    confirmDialogButtonsContainer: {
      justifyContent: "center",
      "& .dialogBtn": {
        "&.primary": {
          marginLeft: theme.spacing(1),
        },
      },
    },
    verticalDiv: {
      color: "#0E5AA7",
      height: "16px",
      background: "#0E5AA7",
      width: "2px",
      margin: "2px 16px 0px 16px",
    },
    pdfDocStyle: {
      overflow: "scroll",
      height: "890px",
    },
    pdfHeader: {
      position: "absolute",
      backgroundColor: "#393939",
      height: "56px",
      width: "100%",
      zIndex: 1,
    },
    pdfRoot: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    zoomOutStyle: {
      marginRight: "10px",
      cursor: "pointer",
      marginTop: "20px",
    },
    zoomInStyle: {
      cursor: "pointer",
      marginTop: "12px",
      marginLeft: "16px",
    },
    pdfName: {
      fontSize: 14,
      fontWeight: 600,
      color: COLORS.WHITE,
      lineHeight: "17px",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
    },
    pdfScaleNo: {
      backgroundColor: "#000408",
      width: "44px",
      height: "24px",
      padding: "6px",
      color: "white",
    },
    invoiceModalBody: {
      borderRadius: "8px",
      "& .react-pdf__Page": {
        marginTop: "56px",
        "& .react-pdf__Page__canvas": {
          margin: "0 auto",
          height: "100% !important",
          overflow: "auto",
          minWidth: "1064px",
        },
      },
      "& .react-pdf__Page__annotations.annotationLayer": {
        padding: "8px",
      },
    },
    countSection: {
      margin: "auto",
      width: 100,
      height: 40,
      borderRadius: 100,
      backgroundColor: COLORS.FOOTER_COUNT_BACKGROUND,
      color: COLORS.WHITE,
      left: "calc(50% - 40px)",
      bottom: "38px",
      position: "fixed",
      zIndex: 100,
      "& .countContainer": {
        height: "100%",
        "& .countItem": {
          margin: "auto",
          "& p": {
            fontSize: 16,
            fontWeight: 400,
          },
          "& .current": {
            fontWeight: 500,
          },
          "& .separator": {
            padding: "0 2px",
          },
        },
      },
    },
    closeModalIcon: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingRight: "48px",
    },
    modalTitle: {
      fontSize: "24px",
      fontWeight: 600,
      padding: "24px",
    },
    applyModalBodyTitle: {
      display: "flex",
      justifyContent: "flex-start",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      paddingTop: "16px",
    },
    noPdfModal: {
      border: "none",
      minWidth: "800px",
      minHeight: "500px",
      maxHeight: "890px",
      backgroundColor: COLORS.SECONDARY_LIGHT,
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-40%, -50%)",
      padding: "24px",
      textAlign: "center",
      borderRadius: "8px",
    },
    closeNoPdfModalIcon: {
      position: "absolute",
      right: "-20px",
      top: "-21px",
      width: "40px",
      height: "40px",
      zIndex: 1,
      cursor: "pointer",
    },
    noInvoiceMsg: {
      fontSize: "18px",
      fontWeight: 500,
    },
    openX: {
      transform: "scaleX(1)",
      height: 18,
      width: 20,
      color: COLORS.COPY,
    },
    closeX: {
      transform: "scaleX(-1)",
      height: 18,
      width: 20,
      color: COLORS.COPY,
    },
    openY: {
      transform: "scaleY(1)",
      height: 18,
      width: 20,
      color: COLORS.COPY,
    },
    closeY: {
      transform: "scaleY(-1)",
      height: 18,
      width: 20,
      color: COLORS.COPY,
    },
    bundleProduct:{
      "& .icon": {
        fill: "none",
      },
      '& .bundleRows':{
        marginTop: 24,
        '& .itemContainer':{
            marginTop: 12,
            '&.rowItem':{
              "& p": {
                fontWeight: '400 !important',
              },
             
            }
           
        },
      }
    },
    itemHeader:{
      display: 'flex',
      backgroundColor: COLORS.LIGHT_BLUE,
      width: 'fit-content',
      padding: '4px 8px 2px 8px',
      borderRadius: 12
    },
    rejectedReason:{
      textTransform: "capitalize",
      color: '#DD2326',
      fontSize: 8,
      padding: '0px 16px',
      marginTop: 8,
      "& p": {
        fontWeight: 400,
        fontSize:12
      },
    } 
  })
);