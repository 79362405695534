import React from "react";
import { Link } from "react-router-dom";

import { Button, Grid, TextField } from "@material-ui/core";

import AppConstants from "../../../constants";
import DateRangePickerComponent from "../../../common/DateRangeComponent";
import SelectBox from "../../../common/SelectBox";
import CustomTimePicker from "../../../common/CustomTimePicker";

//Assets
import calendarIcon from "../../../assets/icons/calendar-icon.svg";

import { TextConstants } from "../../../constants/TextConstants";

interface DelveDashboardSearchHeaderInterface {
  classes: any;
  dashboardState: any;
  countryCode: any;
  hubRecordListWithCodeAndName: any;
  handleDateChange: any;
  handleDeliverySlotChange: any;
  handleSlotTimeChange: any;
  clearAllFilterHandler: any;
}

const DelveDashboardSearchHeader = (props: DelveDashboardSearchHeaderInterface) => {
  const { classes, dashboardState, countryCode, hubRecordListWithCodeAndName, handleDateChange, handleDeliverySlotChange, handleSlotTimeChange, clearAllFilterHandler } = props;

  return (
    <Grid container className={classes.gritContainerItemSearchHeader}>
      <Grid item xs={2} className={classes.gritFilterHeaderItem}>
        <Button className={classes.clearAllFilterBtnStyle} onClick={() => clearAllFilterHandler()}>
          {AppConstants.BUTTONS.CLEAR_ALL_FILTERS}
        </Button>
      </Grid>

      <Grid item xs={2} className={classes.gritSearchHeaderItem}>
        <SelectBox
          fullWidth
          className={classes.select}
          value={dashboardState.hubs}
          id="hubs"
          label={TextConstants.STORES_LABEL}
          multiple
          items={hubRecordListWithCodeAndName[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || []}
          handleChange={handleDeliverySlotChange}
        ></SelectBox>
      </Grid>

      <Grid item xs={2} className={classes.gritSearchHeaderItem}>
        <DateRangePickerComponent
          fullWidth
          keyId="delve-dashboard-date-picker"
          label={TextConstants.DATE_LABEL}
          className={classes.icon}
          value={dashboardState.dateSlot}
          isTextField={true}
          iconSrc={calendarIcon}
          autoUpdateInput={false}
          autoApply={true}
          maxSpan={{
            day: "30",
          }}
          showDropdowns={true}
          linkedCalendars={true}
          locale={{
            format: AppConstants.DATE_FORMAT,
          }}
          updateDateRange={handleDateChange}
        />
      </Grid>

      <Grid item xs={2} className={classes.gritSearchHeaderItem}>
        {/* Slot Start Time */}
        <CustomTimePicker
          keyId="delve-dashboard-start-time-picker"
          label={`Slot Start Time`}
          name="startTime"
          value={dashboardState.timeSlot.startTime}
          handleChange={(date: any) => handleSlotTimeChange(date, "startTime")}
          ampmBoolean={false}
          placeholder={AppConstants.SLOT_FORMAT_UI}
          fullWidth
        />
      </Grid>

      <Grid item xs={2} className={classes.gritSearchHeaderItem}>
        {/* Slot End Time */}
        <CustomTimePicker
          keyId="delve-dashboard-start-time-picker"
          label={`Slot End Time`}
          name="endTime"
          value={dashboardState.timeSlot.endTime}
          handleChange={(date: any) => handleSlotTimeChange(date, "endTime")}
          ampmBoolean={false}
          placeholder={AppConstants.SLOT_FORMAT_UI}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(DelveDashboardSearchHeader);
