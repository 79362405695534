import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { CarrefourDeliveryState } from "./carrefourDeliverySlice.types";
import AppConstants from "../../constants";
import { transformSelectValues, saveFile, transformProductsResponse } from "../../utils/helpers.utils";
import { getDropdownsData, getPlannedRoutesData } from "../../mocks/carrefourHomeDelivery/response.transforms";

export const fetchPlanningList = createAsyncThunk(
  "CarrefourDelivery/FetchByCountry",
  async (
    {
      requestContinuationToken,
      searchBy,
      searchValue,
    }: {
      requestContinuationToken?: string;
      searchBy: string;
      searchValue: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/${searchBy}/${searchValue}`,
        method: EApiMiddlewareMethods.GET,
      } as any;

      if (requestContinuationToken) {
        request.headers = {
          "x-requestcontinuation-token": requestContinuationToken,
        };
      }
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDynamicFiltersOrdersDropdownValues = createAsyncThunk(
  "CarrefourDelivery/FetchDynamicFiltersOrdersDropdownValues",
  async ({
    filters
  }: {
    filters: any
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/dynamic-filter-count',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDynamicFiltersRoutesDropdownValues = createAsyncThunk(
  "CarrefourDelivery/FetchDynamicFiltersRoutesDropdownValues",
  async ({
    filters
  }: {
    filters: any
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/dynamic-filter-count',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDeliveryReasons = createAsyncThunk(
  "CarrefourDelivery/FetchDeliveryReasons",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `order/get-delivery-reasons/Default`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPlanningOrderListByFilter = createAsyncThunk(
  "CarrefourDelivery/FetchOrdersByFilter",
  async (
    {
      filters
    }: {
      filters: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPlanningRouteListByFilter = createAsyncThunk(
  "CarrefourDelivery/FetchRoutesByFilter",
  async (
    {
      filters
    }: {
      filters: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/filter',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllDrivers = createAsyncThunk(
  "CarrefourDelivery/FetchAllDrivers",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'assignee/drivers',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllHelpers = createAsyncThunk(
  "CarrefourDelivery/FetchAllHelpers",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'assignee/helpers',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllVehicles = createAsyncThunk(
  "CarrefourDelivery/FetchAllVehicles",
  async (
    {
      payload
    }: {
      payload: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'vehicle/load',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createDraftRoute = createAsyncThunk(
  "CarrefourDelivery/CreateDraftRoute",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/draft/create',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadAllSlots = createAsyncThunk(
  "CarrefourDelivery/LoadAllSlots",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/draft/load/slots',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const consignmentReplanning = createAsyncThunk(
  "CarrefourDelivery/ConsignmentReplanning",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/replanning',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllPlannedRoutes = createAsyncThunk(
  "CarrefourDelivery/FetchAllPlannedRoutes",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/view',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAndEditRoute = createAsyncThunk(
  "CarrefourDelivery/CreateAndEditRoute",
  async (
    {
      payload,
      editMode
    }: {
      payload: any,
      editMode: boolean
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/edit',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const discardRouteChanges = createAsyncThunk(
  "CarrefourDelivery/DiscardRouteChanges",
  async (
    {
      params,
      redirect
    }: {
      params: any,
      redirect?: boolean
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/discard',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const publishRoutes = createAsyncThunk(
  "CarrefourDelivery/PublishRoutes",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/publish',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRoute = createAsyncThunk(
  "CarrefourDelivery/DeleteRoute",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/delete',
        method: EApiMiddlewareMethods.POST,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateConsignmentStatus = createAsyncThunk(
  "CarrefourDelivery/UpdateOrderStatus",
  async (
    {
      payload
    }: {
      payload: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/updateRouteStatus',
        method: EApiMiddlewareMethods.PUT,
        data: payload
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrdersFilterChipsDisplayCount = createAsyncThunk(
  "CarrefourDelivery/FetchOrdersFilterChipsDisplayCount",
  async (
    {
      filters
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/displayStatusCount',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRoutesFilterChipsDisplayCount = createAsyncThunk(
  "CarrefourDelivery/FetchRoutesFilterChipsDisplayCount",
  async (
    {
      filters
    }: {
      filters: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'route/displayStatusCount',
        method: EApiMiddlewareMethods.POST,
        data: filters
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRouteConsignments = createAsyncThunk(
  "CarrefourDelivery/FetchRouteConsignments",
  async ({
    id
  }: {
    id: string
  },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `route/get-consignments-by-route/${id}`,
        method: EApiMiddlewareMethods.POST,
        data: {}
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportToExcel = createAsyncThunk(
  "CarrefourDelivery/ExportOrderToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'export/order',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportRoutesToExcel = createAsyncThunk(
  "CarrefourDelivery/ExportRoutesToExcel",
  async (
    {
      payload,
    }: {
      payload: object;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'export/route',
        method: EApiMiddlewareMethods.POST,
        data: payload,
        responseType: "blob"
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const exportInvoicePdf = createAsyncThunk(
  "CarrefourDelivery/ExportInvoicePdf",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/view-invoice',
        method: EApiMiddlewareMethods.POST,
        data: params,
        responseType: "bytes"
      } as any;
      const { data } = await networkHandler(request, false, true, false);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRejectionReasons = createAsyncThunk(
  "CarrefourDelivery/FetchRejectionReasons",
  async (
    {
      countryCode
    }: {
      countryCode: any;
    },
    { rejectWithValue }
  ) => {
    let params = countryCode ? countryCode : sessionStorage.getItem(AppConstants.COUNTRY_CODE);
    try {
      const request = {
        url: `order/get-lineitem-rejection-reasons/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCustomerAddress = createAsyncThunk(
  "CarrefourDelivery/UpdateCustomerAddress",
  async (
    {
      params
    }: {
      params: any
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: 'order/update-delivery-address',
        method: EApiMiddlewareMethods.POST,
        data: params
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAllConsignmentsState = createAsyncThunk("CarrefourDelivery/UpdateAllConsignmentsState", ({
  consignments
}: {
  consignments: any
}) => {
  return consignments;
});

export const clearPublishedRoutesErrors = createAsyncThunk("CarrefourDelivery/ClearPublishedRoutesErrors", () => {
  return [];
});

export const clearPublishedRoutes = createAsyncThunk("CarrefourDelivery/ClearPublishedRoutes", () => {
  return [];
});

export const resetPlanningError = createAsyncThunk("CarrefourDelivery/ResetPlanningError", () => {
  return "";
});

export const clearUpdatedRoute = createAsyncThunk("CarrefourDelivery/ClearUpdatedRoute", () => {
  return null;
});

export const clearDeletedRoute = createAsyncThunk("CarrefourDelivery/ClearDeletedRoute", () => {
  return null;
});

export const resetCreateRouteSuccess = createAsyncThunk("CarrefourDelivery/ResetCreateRouteSuccess", () => {
  return false;
});

export const resetDraftRoute = createAsyncThunk("CarrefourDelivery/ResetDraftRoute", () => {
  return {};
});

export const resetConsignmentReplanningSuccess = createAsyncThunk("CarrefourDelivery/ResetConsignmentReplanningSuccess", () => {
  return false;
});

export const clearCurrentSlot = createAsyncThunk("CarrefourDelivery/ClearCurrentSlot", () => {
  return null;
});

export const fetchConsignmentHistoryAndProducts = createAsyncThunk(
  "CarrefourDeliveryConsignmentDetails/FetchConsignmentHistoryAndProducts",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/statusHistoryProducts/${params} `,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCratesInfo = createAsyncThunk(
  "CarrefourDeliveryConsignmentDetails/getCratesInfo",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order / updateCratesAlectoApi`,
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCarrefourDeliveryStatuses = createAsyncThunk(
  "CarrefourDelivery/fetchCarrefourDeliveryStatuses",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: `order/statuses/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCHDSlotDropdownValues = createAsyncThunk(
  "CarrefourDelivery/FetchCHDSlotDropdownValues",
  async (
    {
      countryCode
    }: {
      countryCode: any;
    },
    { rejectWithValue }
  ) => {
    let params = countryCode ? countryCode : sessionStorage.getItem(AppConstants.COUNTRY_CODE);
    try {
      const request = {
        url: `filter/all/${params}/CHD`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const closeDialog = createAsyncThunk("CarrefourDelivery/CloseDialog", () => {
  return false;
});

export const resetReplanningSuccess = createAsyncThunk("CarrefourDelivery/ResetReplanningSuccess", () => {
  return false;
});

export const updateAddressSuccess = createAsyncThunk("VTAddressUpdate/AddressUpdateSuccess", () => {
  return false;
});

const initialState: CarrefourDeliveryState = {
  loading: false,
  tableLoading: false,
  invoiceData: "",
  displayInvoice: false,
  invoiceMessage: "",
  invoiceConsignment: "",
  exportError: "",
  cratesFromAlecto: "",
  productsListFromAlecto: [],
  vtOrdersFilterChipsDisplayCount: {
    consignmentCount: {
      UNPLANNED: 0,
      PLANNED: 0,
      PUBLISHED: 0,
      PREPARING: 0,
      PREPARED: 0,
      OUT_FOR_DELIVERY: 0,
      COMPLETED: 0,
    }
  },
  routesFilterChipsDisplayCount: {
    routCount: {
      UNPLANNED: 0,
      PLANNED: 0,
      PUBLISHED: 0,
      PREPARING: 0,
      PREPARED: 0,
      OUT_FOR_DELIVERY: 0,
      COMPLETED: 0,
    }
  },
  ordersFiltersDropdowns: {
    hubCodeList: [],
    hubNameList: [],
    deliveryAreaList: [],
    typeList: [],
    deliverySlotList: [],
    statusList: [],
    paymentTypeList: [],
    carrierList: [],
    deliveryPropositionList: [],
    routeIdList: [],
    routeStatusList: [],
    driverList: [],
    helperList: [],
    vehicleList: [],
    undeliveredReasonList: []
  },
  routesFiltersDropdowns: {
    hubCodeList: [],
    hubNameList: [],
    routeIdList: [],
    deliveryAreaList: [],
    deliverySlotList: [],
    carrierList: [],
    deliveryPropositionList: [],
    routeStatusList: [],
    driverList: [],
    helperList: [],
    vehicleList: [],
    createdByList: []
  },
  reasonsDropdownList: [],
  deliveryReasons: {},
  ordersData: {
    elements: [],
    pageNumber: 0
  },
  routesData: {
    elements: [],
    pageNumber: 0
  },
  historyAndProducts: {},
  error: "",
  errorCode: "",
  draftRoute: {},
  plannedRoutes: {},
  slotwisePlannedRoutes: [],
  areawiseConsignments: [],
  slotwiseConsignments: {},
  allConsignments: [],
  routeConsignments: [],
  driversList: [],
  helpersList: [],
  vehiclesList: [],
  slotsList: [],
  consignmentStatusList: [],
  currentSlot: null,
  routeCreateSuccess: false,
  updatedRoute: null,
  routeDiscardSuccess: false,
  publishedRoutesErrors: [],
  publishedRoutes: [],
  chdReplanningConsignmentSuccess: false,
  deletedRoute: null,
  excelLoading: false,
  consignmentStatuses: [],
  productsList: [],
  consignmentDataAfterReplanning: {},
  carrefourDeliveryStatuses: [],
  isUpdateAddress: false,
  rejectionReasonList: [],
  rejectionReasons: {},
  deliverySlotListCHD: [],
  updatedCustomerAddress: {}
};

const carrefourDeliverySlice = createSlice({
  name: "CarrefourDelivery",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchPlanningList.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchPlanningList.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.ordersData = payload;
      })
      .addCase(fetchPlanningList.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDynamicFiltersOrdersDropdownValues.pending, (state) => {
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDynamicFiltersOrdersDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.errorCode = "";
        state.error = "";
        state.chdReplanningConsignmentSuccess = false;
        state.ordersFiltersDropdowns = {
          hubCodeList: transformSelectValues(payload.hubCodes || []),
          hubNameList: transformSelectValues(payload.hubNames || []),
          deliveryAreaList: transformSelectValues(payload.areas || []),
          typeList: transformSelectValues(payload.types || []),
          deliverySlotList: transformSelectValues(payload.deliverySlots || []),
          statusList: transformSelectValues(payload.statuses || []),
          paymentTypeList: transformSelectValues(payload.paymentTypes || []),
          carrierList: transformSelectValues(payload.carriers || []),
          deliveryPropositionList: transformSelectValues(payload.deliveryPropositions || []),
          routeIdList: transformSelectValues(payload.routeIds || []),
          routeStatusList: transformSelectValues(payload.routeStatuses || []),
          driverList: transformSelectValues(payload.drivers || []),
          helperList: transformSelectValues(payload.helpers || []),
          vehicleList: transformSelectValues(payload.vehicles || []),
          undeliveredReasonList: transformSelectValues(payload.undeliveredReasons || [])
        };
      })
      .addCase(fetchDynamicFiltersOrdersDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDynamicFiltersRoutesDropdownValues.pending, (state) => {
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDynamicFiltersRoutesDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.errorCode = "";
        state.error = "";
        state.routesFiltersDropdowns = {
          hubCodeList: transformSelectValues(payload.hubCodes || []),
          hubNameList: transformSelectValues(payload.hubNames || []),
          routeIdList: transformSelectValues(payload.routeIds || []),
          deliveryAreaList: transformSelectValues(payload.areas || []),
          deliverySlotList: transformSelectValues(payload.deliverySlots || []),
          carrierList: transformSelectValues(payload.carriers || []),
          deliveryPropositionList: transformSelectValues(payload.deliveryPropositions || []),
          routeStatusList: transformSelectValues(payload.routeStatuses || []),
          driverList: transformSelectValues(payload.drivers || []),
          helperList: transformSelectValues(payload.helpers || []),
          vehicleList: transformSelectValues(payload.vehicles || []),
          createdByList: transformSelectValues(payload.createdBy || [])
        };
      })
      .addCase(fetchDynamicFiltersRoutesDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchDeliveryReasons.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchDeliveryReasons.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let deliveryReasonsList: any = [];
        if (payload.reasons) {
          deliveryReasonsList = payload.reasons.map((reason: any) => {
            return {
              code: reason.reasonCode,
              value: reason.reasonDescription
            };
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.reasonsDropdownList = transformSelectValues(deliveryReasonsList);
        state.deliveryReasons = payload.reasons;
      })
      .addCase(fetchDeliveryReasons.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.chdReplanningConsignmentSuccess = false;
        state.vtOrdersFilterChipsDisplayCount = payload;
      })
      .addCase(fetchOrdersFilterChipsDisplayCount.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchRoutesFilterChipsDisplayCount.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchRoutesFilterChipsDisplayCount.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.routesFilterChipsDisplayCount = payload;
      })
      .addCase(fetchRoutesFilterChipsDisplayCount.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAllDrivers.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAllDrivers.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.driversData = payload;
        const drivers = getDropdownsData(payload);
        state.driversList = transformSelectValues(drivers);
      })
      .addCase(fetchAllDrivers.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAllHelpers.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAllHelpers.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.helpersData = payload;
        const helpers = getDropdownsData(payload);
        state.helpersList = transformSelectValues(helpers);
      })
      .addCase(fetchAllHelpers.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAllVehicles.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAllVehicles.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.vehiclesData = payload;
        const vehicles = getDropdownsData(payload);
        state.vehiclesList = transformSelectValues(vehicles);
      })
      .addCase(fetchAllVehicles.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(loadAllSlots.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(loadAllSlots.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.slotwisePlannedRoutes = [];
        state.slotsData = payload;
        state.currentSlot = payload.currentSlot && payload.currentSlot.length ? payload.currentSlot[0] : {};
        state.slotsList = transformSelectValues(payload.allSlots);
      })
      .addCase(loadAllSlots.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.currentSlot = null;
        state.slotsList = [];
        state.draftRoute = {};
        state.slotwisePlannedRoutes = [];
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAllPlannedRoutes.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAllPlannedRoutes.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.plannedRoutes = payload;
        state.slotwisePlannedRoutes = getPlannedRoutesData(payload.allRoutes);
      })
      .addCase(fetchAllPlannedRoutes.rejected, (state, action) => {
        state.loading = false;
        state.plannedRoutes = {};
        state.slotwisePlannedRoutes = [];
      })
      .addCase(createDraftRoute.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.draftRoute = {};
      })
      .addCase(createDraftRoute.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.errorCode = "";
        state.error = "";
        state.routeCreateSuccess = false;
        state.updatedRoute = null;
        state.routeDiscardSuccess = false;
        state.currentSlot = null;
        state.slotwisePlannedRoutes = [];
        state.draftRoute = payload;
        state.consignmentStatusList = transformSelectValues(payload.deliveryStatuses || []);
        sessionStorage.setItem('routeId', payload.route && payload.route.id);
        sessionStorage.setItem('extraConsignments', '[]');
      })
      .addCase(createDraftRoute.rejected, (state, action) => {
        const {
          payload,
          meta: {
            arg: { params }
          }
        } = action;
        const errorPayload: any = payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.routeCreateSuccess = false;
        state.updatedRoute = null;
        state.routeDiscardSuccess = false;
        state.currentSlot = null;
        state.draftRoute = {};
        state.slotwisePlannedRoutes = [];
        if (!params.editMode) {
          sessionStorage.setItem('routeId', '');
          sessionStorage.setItem('extraConsignments', '[]');
        }
      })
      .addCase(consignmentReplanning.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(consignmentReplanning.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.chdReplanningConsignmentSuccess = true;
        state.consignmentDataAfterReplanning = payload;
      })
      .addCase(consignmentReplanning.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.chdReplanningConsignmentSuccess = false;
      })
      .addCase(createAndEditRoute.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(createAndEditRoute.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { editMode }
          }
        } = action;
        state.loading = false;
        if (editMode) {
          state.updatedRoute = payload;
        } else {
          state.routeCreateSuccess = true;
        }
        state.currentSlot = null;
        sessionStorage.setItem('routeId', '');
        sessionStorage.setItem('extraConsignments', '[]');
      })
      .addCase(createAndEditRoute.rejected, (state, action) => {
        const {
          payload,
          meta: {
            arg: { editMode }
          }
        } = action;
        const errorPayload: any = payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        if (editMode) {
          state.updatedRoute = null;
        } else {
          state.routeCreateSuccess = false;
        }
      })
      .addCase(discardRouteChanges.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(discardRouteChanges.fulfilled, (state, action) => {
        const {
          meta: {
            arg: { redirect }
          }
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.routeDiscardSuccess = true;
        state.draftRoute = {};
        state.currentSlot = null;
        if (!redirect) {
          sessionStorage.setItem('routeId', '');
        }
        sessionStorage.setItem('extraConsignments', '[]');
      })
      .addCase(discardRouteChanges.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.routeDiscardSuccess = false;
      })
      .addCase(publishRoutes.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.publishedRoutesErrors = [];
      })
      .addCase(publishRoutes.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.publishErrorDto && payload.publishErrorDto.length) {
          state.publishedRoutesErrors = payload.publishErrorDto;
          state.publishedRoutes = payload.publishedRoutes && payload.publishedRoutes.length ? payload.publishedRoutes : [];
        } else {
          state.publishedRoutes = payload.publishedRoutes && payload.publishedRoutes.length ? payload.publishedRoutes : [];
        }
      })
      .addCase(publishRoutes.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.publishedRoutes = [];
        state.publishedRoutesErrors = [];
      })
      .addCase(deleteRoute.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(deleteRoute.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.deletedRoute = payload;
      })
      .addCase(deleteRoute.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.deletedRoute = null;
      })
      .addCase(updateConsignmentStatus.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(updateConsignmentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(updateConsignmentStatus.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchPlanningOrderListByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchPlanningOrderListByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.chdReplanningConsignmentSuccess = false;
        state.ordersData = {
          ...payload,
          elements:
            filters.pageNumber === 0
              ? [...payload.elements]
              : [...state.ordersData.elements, ...payload.elements],
          totalElements: payload.totalElements,
          totalPages: payload.totalPages
        };
      })
      .addCase(fetchPlanningOrderListByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchPlanningRouteListByFilter.pending, (state) => {
        state.tableLoading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchPlanningRouteListByFilter.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { filters }
          }
        } = action;
        state.tableLoading = false;
        state.errorCode = "";
        state.error = "";
        state.routesData = {
          ...payload,
          elements:
            filters.pageNumber === 0
              ? [...payload.elements]
              : [...state.routesData.elements, ...payload.elements],
          totalElements: payload.totalElements,
          totalPages: payload.totalPages
        };
      })
      .addCase(fetchPlanningRouteListByFilter.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.tableLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchRouteConsignments.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchRouteConsignments.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.routeConsignments = payload;
      })
      .addCase(fetchRouteConsignments.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateAllConsignmentsState.fulfilled, (state, action) => {
        const { payload } = action;
        state.areawiseConsignments = payload.areaWiseConsignments;
        state.slotwiseConsignments = payload.slotwiseConsignments;
        state.allConsignments = payload.allConsignments;
        state.loading = payload.loading;
        state.error = "";
      })
      .addCase(clearPublishedRoutesErrors.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.publishedRoutesErrors = payload;
      })
      .addCase(clearPublishedRoutes.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.publishedRoutes = payload;
      })
      .addCase(resetPlanningError.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = payload;
        state.error = payload;
      })
      .addCase(clearUpdatedRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.updatedRoute = payload;
        state.routeDiscardSuccess = false;
      })
      .addCase(clearDeletedRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.deletedRoute = payload;
      })
      .addCase(clearCurrentSlot.fulfilled, (state, action) => {
        const { payload } = action;
        state.currentSlot = payload;
      })
      .addCase(resetCreateRouteSuccess.fulfilled, (state, action) => {
        const { payload } = action;
        state.routeCreateSuccess = payload;
      })
      .addCase(resetDraftRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.draftRoute = payload;
      })
      .addCase(resetConsignmentReplanningSuccess.fulfilled, (state, action) => {
        const { payload } = action;
        state.chdReplanningConsignmentSuccess = payload;
      })
      .addCase(exportToExcel.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(exportToExcel.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Consignments_${currentDate} _${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(exportRoutesToExcel.pending, (state) => {
        state.excelLoading = true;
        state.error = "";
      })
      .addCase(exportRoutesToExcel.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.excelLoading = false;
        state.errorCode = "";
        state.error = "";
        if (payload) {
          const currentDate = moment().format("YYYY-MM-DD");
          const currentTime = moment().format("HH-mm");
          let filename = `Routes_${currentDate} _${currentTime}.xlsx`;
          saveFile(payload, filename);
        }
      })
      .addCase(exportRoutesToExcel.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.excelLoading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.exportError = errorPayload.status;
      })
      .addCase(exportInvoicePdf.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(exportInvoicePdf.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        const data = payload.Invoices && payload.Invoices.length ? payload.Invoices[0].FileDataBytes : [];
        state.invoiceConsignment = payload.Invoices && payload.Invoices.length ? payload.Invoices[0].ConsignmentNo : "";
        state.displayInvoice = payload.Invoices && payload.Invoices.length;
        const pdfData = `data: application / pdf; base64, ${data} `;
        state.invoiceData = payload.Invoices && payload.Invoices.length ? pdfData : [];
        state.invoiceMessage = payload.Message;
      })
      .addCase(exportInvoicePdf.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchConsignmentHistoryAndProducts.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchConsignmentHistoryAndProducts.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.updatedCustomerAddress = {};
        state.historyAndProducts = payload;
        state.consignmentStatuses = payload.statuses && payload.statuses.length ? payload.statuses : [];
        let prodRes: any = transformProductsResponse(payload.products && payload.products.length ? payload.products : []);
        state.productsList = prodRes || [];
      })
      .addCase(fetchConsignmentHistoryAndProducts.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(getCratesInfo.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(getCratesInfo.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.cratesFromAlecto = payload;
        state.productsListFromAlecto = payload && payload.crateAvailableOrders && payload.crateAvailableOrders.length > 0 && payload.crateAvailableOrders[0].products && payload.crateAvailableOrders[0].products.length > 0 ? payload.crateAvailableOrders[0].products : null;
      })
      .addCase(getCratesInfo.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(closeDialog.fulfilled, (state, action) => {
        state.consignmentDataAfterReplanning = {};
        state.updatedCustomerAddress = {};
      })
      .addCase(resetReplanningSuccess.fulfilled, (state, action) => {
        state.chdReplanningConsignmentSuccess = false
      })
      .addCase(fetchCarrefourDeliveryStatuses.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchCarrefourDeliveryStatuses.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.carrefourDeliveryStatuses = transformSelectValues(payload.statuses && payload.statuses.length ? payload.statuses : []);
      })
      .addCase(fetchCarrefourDeliveryStatuses.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateAddressSuccess.fulfilled, (state, action) => {
        state.isUpdateAddress = false
      })
      .addCase(fetchRejectionReasons.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchRejectionReasons.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        let rejectionReasonsList: any = [];
        if (payload.reasons) {
          rejectionReasonsList = payload.reasons.map((reason: any) => {
            return {
              code: reason.reasonCode,
              value: reason.reasonDescription
            };
          });
        }
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.rejectionReasonList = transformSelectValues(rejectionReasonsList);
        state.rejectionReasons = payload.reasons;
      })
      .addCase(fetchRejectionReasons.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchCHDSlotDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchCHDSlotDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        let deliverySlotListCHD: any = {};
        if (payload.deliverySlot) {
          Object.keys(payload.deliverySlot).filter((country: any) => {
            deliverySlotListCHD[country] = transformSelectValues(payload.deliverySlot[country]);
            return true;
          });
        }
        state.deliverySlotListCHD = deliverySlotListCHD;
      })
      .addCase(fetchCHDSlotDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateCustomerAddress.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(updateCustomerAddress.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.isUpdateAddress = true;
        state.updatedCustomerAddress = payload;
      })
      .addCase(updateCustomerAddress.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.chdReplanningConsignmentSuccess = false;
      })
  },
});

export default carrefourDeliverySlice.reducer;