import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {COLORS} from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        titleContainer: {
            '& .headingContainer': {
                flexDirection: "column",
                '& .titleHeading': {
                    fontSize: 18,
                    fontWeight: 500,
                    '& .content': {
                        fontWeight: 600
                    }
                },
                '& .titleContent': {
                    fontSize: 12,
                    fontWeight: 600,
                    '&.link': {
                        cursor: "pointer"
                    }
                }
            },
            '& .tagContainer': {
                marginLeft: theme.spacing(1),
                '& .tag': {
                    color: COLORS.WHITE,
                    borderRadius: theme.spacing(0.5),
                    padding: theme.spacing(0.5, 1),
                    lineHeight: "14px",
                    backgroundColor: COLORS.BLACK,
                    fontWeight: 600,
                    textTransform: "uppercase",
                    fontSize: 12,
                    '&.INFO_BLUE': {
                        backgroundColor: COLORS.INFO_BLUE
                    },
                    '&.READY_BLUE': {
                        backgroundColor: COLORS.READY_BLUE
                    },
                    '&.MINSK': {
                        backgroundColor: COLORS.MINSK
                    },
                    '&.PRIMARY_BLUE': {
                        backgroundColor: COLORS.PRIMARY_MAIN
                    },
                    '&.JUNGLE_GREEN': {
                        backgroundColor: COLORS.JUNGLE_GREEN
                    },
                    '&.PARTIAL_SUCCESS_GREEN': {
                        backgroundColor: COLORS.PARTIAL_SUCCESS_GREEN
                    },
                    '&.ERROR_RED': {
                        backgroundColor: COLORS.PALE_RED
                    },
                    '&.PALE_GRAY': {
                        backgroundColor: COLORS.PALE_GRAY
                    },
                    '&.ALERT_ORANGE': {
                        backgroundColor: COLORS.ALERT_ORANGE
                    },
                }
            },
        },
        contentContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            '& .selectionArea': {
                paddingBottom: theme.spacing(3),
                '& .selectionFormControlContainer': {
                    '& .formHeading': {
                        fontSize: 14,
                        fontWeight: 400,
                        color: theme.palette.text.primary,
                        paddingBottom: theme.spacing(2)
                    },
                    '& .radioGroup': {
                        flexDirection: 'row',
                        '& .radioItem': {
                            marginRight: theme.spacing(3)
                        },
                        '& .MuiFormControlLabel-label': {
                            fontSize: 14,
                            fontWeight: 500,
                            color: theme.palette.text.primary,
                        }
                    }
                }
            },
            '& .selectedContentArea': {
                '& .contentAreaContainer': {
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    '&.reschedule .MuiOutlinedInput-root': {
                        height: 40
                    },
                    '& .contentAreaItem': {
                        paddingBottom: theme.spacing(3),
                        flexGrow: 1,
                        '& .itemContainer': {
                            flexDirection: "column",
                            '& .itemLabel': {
                                paddingBottom: theme.spacing(1),
                                alignSelf: "flex-start",
                                '& p': {
                                    fontSize: 14,
                                    fontWeight: 400
                                }
                            },
                            '& .itemInput': {
                                '& .MuiOutlinedInput-input': {
                                    fontSize: 14,
                                    fontWeight: 400
                                }
                            }
                        },
                        '&.warning': {
                            height: "55px",
                            background: "#E79832",
                            borderRadius: "8px"
                        },
                        '& .warningItem': {
                            display: "flex",
                            margin: "16px"
                        },
                        '& .warningTitle': {
                            fontSize: "14px",
                            marginLeft: "10px",
                            lineHeight: "17px",
                            fontWeight: 500,
                            color: "#FFFFFF",
                            display: "flex",
                            alignItems: "center",
                        }
                    }
                }
            }
        },
        buttons: {
            width: "100%",
            '& .userBtn': {
                '&.secondary': {
                    marginRight: theme.spacing(2)
                },
                flexGrow: 1
            }
        },
        dialogPaperPropsRoot: {
            margin: 0,
            marginLeft: "auto",
            maxHeight: "none",
            height: "100vh",
            width: 680
        },
        confirmDialogTitleContainer: {
            paddingTop: "8px",
            '& .titleItem': {
                display: "flex",
                alignItems: "center",
                '&.icon': {
                    width: "18px",
                    height: "18px"
                },
                '&.text': {
                    fontSize: "14px",
                    fontWeight: 700,
                    textTransform: "uppercase",
                    color: "#252525",
                    paddingLeft: "12px",
                }
            }
        },
        confirmDialogContentContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            '& .contentItem': {
                fontSize: 24,
                fontWeight: 600,
                '& p': {
                    lineHeight: 1.2,
                },
                '&.desc': {
                    padding: theme.spacing(0, 2.5),
                    '& p': {
                        fontSize: 18,
                        fontWeight: 400,
                        '& .count': {
                            fontWeight: 600
                        }
                    }
                },
                '& .contentDetail': {
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#252525",
                    '&.secondItem': {
                        paddingTop: "12px"
                    }
                }
            },
        },
        confirmDialogButtonsContainer: {
            justifyContent: "center",
            '& .dialogBtn': {
                '&.primary': {
                    marginLeft: theme.spacing(1)
                }
            }
        },
        confirmDialogPaperPropsRoot: {
            width: 338,
            height: 214,
            margin: "auto",
            borderRadius: theme.spacing(1)
        },
        '& .react-pdf__Page': {
            marginTop: "10px",
        },
        '& .react - pdf__Page__textContent': {
            border: "1px solid darkgrey",
            boxShadow: "5px 5px 5px 1px #ccc",
            borderRadius: "5px",
        },
        '& .react - pdf__Page__annotations.annotationLayer': {
            padding: "20px",
        },
        '& .react - pdf__Page__canvas': {
            margin: "0 auto",
        },
    })
);