import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      '& .mainItem': {
        fontSize: "15px",
        width: "736px",
        height: "460px",
        backgroundColor: COLORS.WHITE,
        borderRadius: "8px",
        border: "1px solid #0A437C",
        boxShadow: "0px 0px 10px 2px rgba(14, 90, 167, 0.1)",
      },
      '& .resetItem': {
        fontSize: "15px",
        width: "736px",
        height: "530px",
        backgroundColor: COLORS.WHITE,
        borderRadius: "8px",
        border: "1px solid #0A437C",
        boxShadow: "0px 0px 10px 2px rgba(14, 90, 167, 0.1)",
      }
    },
    loginContainer: {
      '& .headerLoginItem': {
        display: "flex",
        justifyContent: "center",
        padding: '30px',
        margin: '0px 0px 16px 0px',
        borderBottom: `1px solid ${COLORS.LIGHT_WHITE}`,
        background: COLORS.LIGHT_WHITE
      },
      '& .logoContainer': {
        margin: 'auto',
        alignItems: 'center',
        '& .logo': {
          width: 350,
          height: 50
        },
        '& .logoSpanStyle':{
          fontSize: "32px",
          fontWeight: 700,
          color: COLORS.PRIMARY_MAIN,
        }
      },
      '& .buttonsContainer': {
        '& .btnItem': {
          paddingBottom: theme.spacing(2),
          display: 'flex',
          '&:last-child': {
            paddingBottom: theme.spacing(0)
          },
          '& .loginBtn': {
            width: "100%",
            height: 55,
            margin: theme.spacing(0, 0.5, 0, 0.5),
          },
          '&.forgotPwdItem': {
            alignSelf: "flex-end"
          },
          '&.newUserItem': {
            alignSelf: "center"
          },
          '& .forgotPwdBtn': {
            cursor: "pointer",
            fontSize: theme.spacing(1.75),
            color: theme.palette.primary.main
          }
        }
      },
      '& .formContainer': {
        '& .formItem': {
          '& .formTitle': {
            fontSize: theme.spacing(3),
            fontWeight: 600
          },
          '& .MuiFormControl-root': {
            margin: theme.spacing(0),
            width: "100%"
          },
          '& .MuiOutlinedInput-root': {
            height: 48
          }
        }
      },
      '& .loginItem': {
        padding: theme.spacing(0, 6.5, 2.5, 6.5),
        '&:first-child': {
          paddingTop: theme.spacing(0)
        },
        '&:last-child': {
          paddingBottom: theme.spacing(0)
        },
        '& .formContainer': {
          '& .formItem': {
            paddingBottom: theme.spacing(3),
            '&:last-child': {
              paddingBottom: theme.spacing(0)
            }
          }
        }
      },
      '& .registerItem': {
        padding: theme.spacing(1.5, 0),
        '&:first-child': {
          paddingTop: theme.spacing(0)
        },
        '&:last-child': {
          paddingBottom: theme.spacing(0)
        },
        '& .formContainer': {
          flexWrap: "nowrap",
          '& .formItemGroup': {
            paddingBottom: theme.spacing(2),
            '&:first-child': {
              paddingBottom: theme.spacing(3)
            },
            '&:last-child': {
              paddingBottom: theme.spacing(0)
            },
            '& .formItem': {
              flexGrow: 1,
              maxWidth: "50%",
              '&.left': {
                paddingRight: theme.spacing(2.5)
              },
              '&.right': {
                paddingLeft: theme.spacing(2.5)
              }
            }
          }
        }
      }
    }
  }),
);

