import React from "react";
import { useSelector } from "react-redux";

import { useStyles } from "./camsWidgets.style";
import { AppState } from "../config/redux/reducers";

const ExceptionsDeliveries = () => {
  const classes = useStyles();

  const { countryCode, hubCode } = useSelector((state: AppState) => state.common);

  return (
    <div className={classes.CF_iframe}>
      <iframe src={"/cams/exceptions-deliveries?view=widget&countryCode=" + countryCode} frameBorder="0" allowFullScreen></iframe>
    </div>
  );
};
export default ExceptionsDeliveries;
