import React, {forwardRef, ReactElement, Ref, useCallback, useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../config/redux/reducers";
import moment from 'moment';
//common components
import CustomDialog from '../common/Dialog';
import CustomSnackbar from '../common/CustomSnackbar';
import SwitchButton from "../common/SwitchButton/SwitchButton";
import DateRangePickerComponent from '../common/DateRangeComponent';
import Loader from './../common/Loader';
import CustomRadio from '../common/CustomRadioButton/CustomRadio';

//assets
import {ReactComponent as userProfileIcon} from './../assets/icons/user-profile-icon.svg';
import {ReactComponent as mobileLogoIcon} from './../assets/icons/new-mobile-icon.svg';
import {ReactComponent as activeTimelineDot} from './../assets/icons/active-timeline-dot.svg';
import {ReactComponent as errorTimelineDot} from './../assets/icons/timeline-dot-error.svg';
import {ReactComponent as timelineDot} from './../assets/icons/timeline-dot.svg';
import calendarIcon from './../assets/icons/calendar-icon.svg';
//redux slice
import {
    fetchDriverActivityLogs,
    markDriverAway,
    closeDialog,
    updateAssignmentMode,
    releaseDriver
} from './redux/driversSlice';
//styles
import {useStyles} from './DriverDetails.styles';
//Material UI Components
import {TransitionProps} from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import {
    Grid,
    SvgIcon,
    Typography,
    Tab,
    Tabs,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Button,
    Box
} from '@material-ui/core';
import {TabContext, TabPanel} from '@material-ui/lab';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent
} from '@material-ui/lab';
import AppConstants from '../constants/index';
import {
    createPayloadForDriverActivity,
    createPayloadToUpdateDriverAway,
    getDriverStatusField,
    getDriverStatusFieldAfterMarkingAway,
    getFieldName,
    createPayloadToUpdateAssignmentMode,
    createPayloadToReleaseDriver,
    Driver
} from '../mocks/drivers/response.transforms';

interface DriverDetailsProps {
    open: boolean;
    user: any;
    defaultTab: any;
    closePopup: () => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const DriverDetails = (props: DriverDetailsProps) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const {open, user, defaultTab, closePopup} = props;
    const {
        driverLogs,
        errorCode,
        loading,
        userUpdateStatus,
        userUpdateMsg,
        driversAfterMarkingAway,
        assignmentModeMsg,
        assignmentModeStatus,
        releaseDriverStatus,
        releaseDriverMsg
    } = useSelector(
        (state: AppState) => state.drivers
    );
    const {countryCode} = useSelector(
        (state: AppState) => state.common
    );
    const {isAdmin, isLastMileViewer, isOnlyExpLastMileViewer,} = useSelector(
        (state: AppState) => state.userLogin
    );
    const [tabValue, setTabValue] = useState(defaultTab);
    const [openAwaySnackbar, setOpenAwaySnackbar] = useState(false);
    const [openModeUpdateSnackbar, setOpenModeUpdateSnackbar] = useState(false);
    const [openDriverReleaseSnackbar, setOpenDriverReleaseSnackbar] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isAuto, setIsAuto] = useState("MANUAL");
    const [currentDate, setCurrentDate] = useState({
        startDate: moment(new Date(), AppConstants.DATE_FORMAT_BACKEND).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
        endDate: moment(new Date(), AppConstants.DATE_FORMAT_BACKEND).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT)
    });
    const [minDate, setMinDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    let hubItems: any = [];

    if (user) {
        if (user.countryDetails) {
            for (let country in user.countryDetails) {
                let countryHubs = user.countryDetails[country];
                countryHubs.filter((hub: any) => {
                    hubItems.push(hub);
                    return true;
                })
            }
        }
    }
    const dialogPaperProps = {
        classes: {
            root: classes.dialogPaperPropsRoot
        },
        square: true
    }

    const handleAwaySnackbarClose = useCallback(
        () => {
            setOpenAwaySnackbar(false);
        },
        [],
    );

    const handleModeSnackbarClose = useCallback(
        () => {
            setOpenModeUpdateSnackbar(false);
        },
        [],
    );

    const handleDriverReleaseClose = useCallback(
        () => {
            setOpenDriverReleaseSnackbar(false);
        },
        [],
    );

    const handleDialogClose = useCallback(
        () => {
            closePopup();
            setTabValue(defaultTab);
            dispatch(
                closeDialog()
            );
        },
        [closePopup, dispatch],
    );

    const getTitleContent = () => {
        return (
            <></>
        )
    }

    const handleDateChange = useCallback(
        (user: any, dateRange: any) => {
            setCurrentDate(dateRange);
            const payload = createPayloadForDriverActivity(user, dateRange, countryCode);
            dispatch(
                fetchDriverActivityLogs({
                    params: payload
                })
            );
        },
        [dispatch, countryCode],
    );

    const handleReleaseDriver = useCallback(
        (user: Driver) => {
            const payload = createPayloadToReleaseDriver(user);
            dispatch(
                releaseDriver({
                    params: payload
                })
            );
            setOpenDriverReleaseSnackbar(true)
        },
        [dispatch, countryCode],
    );


    const handleDriverActivityLogs = useCallback(
        (user: any) => {
            if (Object.keys(user).length) {
                const payload = createPayloadForDriverActivity(user, currentDate, countryCode);
                dispatch(
                    fetchDriverActivityLogs({
                        params: payload
                    })
                );
            }
        },
        [dispatch, countryCode, currentDate]
    );

    const handleUpdateUserStatus = useCallback(
        (user: any, status: any) => {
            if (Object.keys(user).length) {
                const payload = createPayloadToUpdateDriverAway(user, status, countryCode);
                dispatch(
                    markDriverAway({
                        payload: payload
                    })
                );
                setOpenAwaySnackbar(true);
            }
        },
        [dispatch, countryCode]
    );

    const handleUpdateAssignmentModeForRadio = useCallback(
        (user: any, value: any) => {
            if (Object.keys(user).length) {
                const payload = createPayloadToUpdateAssignmentMode(user, value, countryCode);
                dispatch(
                    updateAssignmentMode({
                        payload: payload
                    })
                );
                setOpenModeUpdateSnackbar(true);
            }
        },
        [dispatch, countryCode]
    );


    const handleSwitch = (e: any) => {
        if (isActive) {
            setIsActive(false);
            handleUpdateUserStatus(user, false);
        } else {
            handleUpdateUserStatus(user, true);
            setIsActive(true);
        }

    };

    const handleAutoManualUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = (event.target as HTMLInputElement).value;
        setIsAuto((event.target as HTMLInputElement).value);
        handleUpdateAssignmentModeForRadio(user, value);
    };

    const handleTabChange = useCallback(
        (event: React.ChangeEvent<{}>, value: string) => {
            setTabValue(value);
        },
        []
    )

    const getDriverLogs = () => {
        return driverLogs && driverLogs.length ? driverLogs.map((item: any, index: number) => (
            < TimelineItem key={`${item.key} - ${index}`} className="timelineItem">
                <TimelineSeparator>
                    {index === 0 ?
                        <TimelineDot className="timelineDot">
                            <SvgIcon className="dotIcon active"
                                     component={item.status === "DELIVERY_FAILED" ? errorTimelineDot : activeTimelineDot}
                                     viewBox="0 0 18 18"/>
                        </TimelineDot>
                        :
                        <TimelineDot className="timelineDot">
                            <SvgIcon className="dotIcon" component={timelineDot} viewBox="0 0 8 8"/>
                        </TimelineDot>
                    }
                    {index !== driverLogs.length - 1 && <TimelineConnector className="primaryConnector"/>}
                </TimelineSeparator>
                <TimelineContent className="timelineContent">
                    <Typography
                        className={["heading", index === 0 ? "active" : "", (item.status === "DELIVERY_FAILED") ? "error" : ""].join(" ")}><span
                        className="status">{item.status}</span><span
                        className="dateTime">{`(${new Date(item.updatedAt).toLocaleDateString()} at ${new Date(item.updatedAt).toLocaleTimeString('en-US', {
                        hour12: false,
                        hour: 'numeric',
                        minute: 'numeric',
                    })})`}</span>
                        {item.status === "Assigned" ? (
                            <>
                                <span className="order">{`${"-"} ${"Consignment Number"}`}</span>
                                <span className="dateTime">{`${":"} ${item.consignmentCode}`}</span>
                            </>
                        ) : ""}
                    </Typography>
                </TimelineContent>
            </TimelineItem>
        )) : (
            <Typography style={{fontSize: "14px", fontWeight: 500}}>{errorCode.message}</Typography>)
    }

    const getDetailsContent = () => {
        return (
            <Grid className={classes.contentContainer} container>
                <TabContext value={tabValue}>
                    <Tabs value={tabValue} className={classes.tabs} aria-label="Order Navigation Tabs"
                          onChange={handleTabChange}>
                        <Tab className="tabLabel" label="User Profile"
                             value={AppConstants.DRIVERS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.DRIVER_PROFILE}/>
                        <Tab className="tabLabel" label="Activity Logs"
                             value={AppConstants.DRIVERS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ACTIVITY_LOG}
                             onClick={() => handleDriverActivityLogs(user)}/>
                    </Tabs>
                    <TabPanel className="tabValues"
                              value={AppConstants.DRIVERS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.DRIVER_PROFILE}>
                        <Grid className={classes.contentContainer} container>
                            <Grid className="userDetails" item>
                                <Grid className="detailsHeader" container>
                                    <Grid className="detailsHeaderItem" item>
                                        {user.images ? (
                                          <Grid className="profileIcon" item><img src={user.images.md} alt="Profile"
                                            style={{ width: '100%', height: '100%', borderRadius: '50%' }}/>
                                          </Grid>
                                        ) : (
                                          <SvgIcon className="profileIcon" viewBox="0 0 140 140" component={userProfileIcon}/>
                                        )}
                                    </Grid>
                                    <Grid className="detailsHeaderItem" item>
                                        <Grid container className="rightPanelContainer">
                                            <Grid item className="rightPanelItem">
                                                <Grid container>
                                                    <Grid item>
                                                        <Typography
                                                            className="fullname">{`${user.driverName}`}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className="rightPanelItem">
                                                <Grid container alignItems="center">
                                                    {driversAfterMarkingAway && driversAfterMarkingAway.status ? (
                                                        <Grid item className={classes.statusHeaderContainer}>
                                                            <Typography
                                                                className={['tag', getDriverStatusFieldAfterMarkingAway('color', driversAfterMarkingAway.status),].join(" ")}>{driversAfterMarkingAway.status ? getFieldName(driversAfterMarkingAway.status) : ""}</Typography>
                                                        </Grid>
                                                    ) : (
                                                        <Grid item className={classes.statusHeaderContainer}>
                                                            <Typography
                                                                className={['tag', getDriverStatusField('color', user.status),].join(" ")}>{user.status ? user.status : ""}</Typography>
                                                        </Grid>
                                                    )}
                                                    {!(isLastMileViewer || isOnlyExpLastMileViewer) &&
                                                        <>
                                                            {user.status === "Available" || user.status === "Active" || user.status === "Back to Store" || user.status === "Away" ? (
                                                                <Grid item style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    fontSize: "14px",
                                                                    fontWeight: 600,
                                                                }}>
                                                                    {loading && <Loader></Loader>}
                                                                    <SwitchButton
                                                                        size="large"
                                                                        checked={isActive}
                                                                        handleChange={handleSwitch}
                                                                        onClick={(e: any) => e.stopPropagation()}
                                                                    />
                                                                    Away
                                                                </Grid>
                                                            ) : null}
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item className="rightPanelItem">
                                                <Grid container alignItems="center">
                                                    <SvgIcon className="logo" viewBox="0 0 12 21"
                                                             component={mobileLogoIcon}/>
                                                    <Typography component="span"
                                                                className="contact mobile">{user.phone ?
                                                        <a href={`tel:${user.phone}`}>{user.phone}</a> : ""}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className="detailsHeaderItem" item>
                                        <Grid container className="rightPanelContainer">
                                            {!(isLastMileViewer || isOnlyExpLastMileViewer) &&
                                                <>
                                                    <FormControl>
                                                        <Grid container>
                                                            <FormLabel id="radio-buttons-group" style={{
                                                                fontSize: "14px",
                                                                fontWeight: 600,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                marginTop: "8px",
                                                                color: "#252525"
                                                            }}>Assignment Mode</FormLabel>
                                                            <Grid item xs={12} style={{marginTop: "4px"}}>
                                                                <RadioGroup
                                                                    aria-labelledby="radio-buttons-group"
                                                                    name="radio-buttons-group"
                                                                    value={isAuto}
                                                                    onChange={handleAutoManualUpdate}
                                                                >
                                                                    <Grid container>
                                                                        <FormControlLabel value="MANUAL"
                                                                                          control={<CustomRadio/>}
                                                                                          label="Manual"
                                                                                          classes={{label: classes.radioLabel}}/>
                                                                        <FormControlLabel value="AUTO"
                                                                                          control={<CustomRadio/>}
                                                                                          label="Auto"
                                                                                          classes={{label: classes.radioLabel}}/>
                                                                    </Grid>
                                                                </RadioGroup>
                                                            </Grid>
                                                        </Grid>
                                                    </FormControl>
                                                </>
                                            }
                                        </Grid>
                                        <Grid container className="rightPanelContainer">
                                            {isAdmin && <Grid item className="rightPanelItem" style={{ marginTop: "8px" }}>
                                                <Grid container alignItems='center'>
                                                    <Button className='endSessionBtn primary' variant="contained"
                                                            onClick={() => handleReleaseDriver(user)}>{AppConstants.BUTTONS.RELEASE_DRIVER}</Button>
                                                </Grid>
                                            </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid className="detailsContent" container>
                                    <Grid className="contentRow" item>
                                        <Grid className="contentRowContainer" container>
                                            <Grid className="contentColumn" item>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography className="label">POS Number</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className="content">{user.hubCodes}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid className="contentColumn" item>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography className="label">POS Name</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className="content">{user.hubNames}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid className="contentColumn" item>
                                                <Grid container direction="column" style={{alignItems: "center"}}>
                                                    <Grid item>
                                                        <Typography className="label">Vehicle</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className="content">{user.vehicleType}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className="contentRow" item>
                                        <Grid className="contentRowContainer" container>
                                            <Grid className="contentColumn" item>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography className="label">Vendor</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className="content">{user.vendor}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid className="contentColumn" item>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography className="label">User created date</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            className="content">{user && moment(user.updatedAt && user.updatedAt.split('.')[0]).format(AppConstants.DATE_FORMAT_UI)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid className="contentColumn" item>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography className="label">User updated date</Typography>
                                                    </Grid>
                                                    <Grid item className="lastItem">
                                                        <Typography
                                                            className="content">{user && moment(user.updatedAt && user.updatedAt.split('.')[0]).format(AppConstants.DATE_FORMAT_UI)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className="contentRow" item>
                                        <Grid className="contentRowContainer" container>
                                            <Grid className="contentColumn" item>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <Typography className="label">Modified By</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            className="content">{user && user.modifiedBy}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel className="tabValues"
                              value={AppConstants.DRIVERS_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES.ACTIVITY_LOG}>
                        <Grid className={classes.contentContainer} container>
                            <Grid className="userDetails" item>
                                <Grid className="detailsHeader" container>
                                    <Grid className="detailsHeaderItem" item>
                                        <Grid container className="rightPanelContainer">
                                            <Grid item className="rightPanelItem">
                                                <Typography className="fullname">{`${user.driverName}`}</Typography>
                                            </Grid>
                                            <Grid item className="rightPanelItem">
                                                <Grid container alignItems="center">
                                                    <SvgIcon className="logo" viewBox="0 0 12 21"
                                                             component={mobileLogoIcon}/>
                                                    <Typography component="span"
                                                                className="contact mobile">{user.phone ?
                                                        <a href={`tel:${user.phone}`}>{user.phone}</a> : ""}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: "24px", justifyContent: "flex-end"}}>
                                <Grid item
                                      style={{display: "flex", alignItems: "center", marginRight: "8px"}}>Date:</Grid>
                                <Grid item>
                                    <Grid className="filterItem" item>
                                        <DateRangePickerComponent
                                            keyId="create-route-date-picker"
                                            className="icon"
                                            value={currentDate}
                                            isTextField={true}
                                            iconSrc={calendarIcon}
                                            autoUpdateInput={false}
                                            autoApply={true}
                                            maxSpan={{
                                                day: '1'
                                            }}
                                            showDropdowns={true}
                                            linkedCalendars={true}
                                            locale={{
                                                format: AppConstants.DATE_FORMAT
                                            }}
                                            minDate={minDate}
                                            updateDateRange={(e: any) => handleDateChange(user, e)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {loading && <Loader></Loader>}
                            <Grid item style={{padding: "24px"}}>
                                <Timeline className={classes.timeline} align="left">
                                    {getDriverLogs()}
                                </Timeline>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Grid>
        )
    }

    const getDialogActions = () => {
        return (
            <></>
        );
    };

    useEffect(() => {
        setCurrentDate({
            startDate: moment(new Date(), AppConstants.DATE_FORMAT_BACKEND).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT),
            endDate: moment(new Date(), AppConstants.DATE_FORMAT_BACKEND).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT)
        })
        if (user.status === "Away") {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
        if (user.assignmentMode === "MANUAL") {
            setIsAuto("MANUAL");
        } else {
            setIsAuto("AUTO")
        }
    }, [user])

    return (
        <>
            <CustomDialog
                open={open}
                TransitionComponent={Transition}
                PaperProps={dialogPaperProps}
                title={getTitleContent()}
                content={getDetailsContent()}
                actions={getDialogActions()}
                handleClose={handleDialogClose}
            ></CustomDialog>
            {openAwaySnackbar && userUpdateStatus ? (
                <CustomSnackbar open={openAwaySnackbar} handleClose={handleAwaySnackbarClose}
                                autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={userUpdateMsg}/>
            ) : ""}
            {openModeUpdateSnackbar && assignmentModeStatus ? (
                <CustomSnackbar open={openModeUpdateSnackbar} handleClose={handleModeSnackbarClose}
                                autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={assignmentModeMsg}/>
            ) : ""}
            {openDriverReleaseSnackbar && releaseDriverStatus ? (
                <CustomSnackbar open={openDriverReleaseSnackbar} handleClose={handleDriverReleaseClose}
                                autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={releaseDriverMsg}/>
            ) : ""}
        </>
    )
}

export default DriverDetails;