import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import networkHandler from "../../network/networkHandler";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { transformSelectValues } from "../../utils/helpers.utils";
import { HubState } from "./hubSlice.types";
import AppConstants from "../../constants";



export const fetchHubDetails = createAsyncThunk(
  "Hubs/FetchHubDetails",
  async (
    {
      params
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "hub/filter",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, false);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewHub = createAsyncThunk(
  "Hubs/AddNewHub",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "hub/create",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAddHubCountryDropdownValues = createAsyncThunk(
  "Users/FetchAddUserDropdownValues",
  async (params, { rejectWithValue }) => {
    try {
      const request = {
        url: `user/populate`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateHubDetails = createAsyncThunk(
  "Hubs/UpdateHubDetails",
  async (
    {
      params,
    }: {
      params: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = {
        url: "hub/update",
        method: EApiMiddlewareMethods.POST,
        data: params,
      } as any;
      const { data } = await networkHandler(request, false, true, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetHubCreationStatus = createAsyncThunk("Users/ResetHubCreationStatus", () => {
  return false;
});

export const resetHubUpdationStatus = createAsyncThunk("Users/ResetHubUpdationStatus", () => {
  return false;
});


const initialState: HubState = {
  loading: false,
  error: "",
  tableLoading: false,
  hubsData: {
    elements: [],
    pageNumber: 0,
  },
  hubCreationStatus: false,
  hubCreationMsg: "",
  hubUpdateStatus: false,
  hubUpdateMsg: "",
  addUserDropdowns: {
    countriesList: []
  },
};

const UsersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchHubDetails.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchHubDetails.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { params }
          }
        } = action;
        state.loading = false;
        state.hubsData = {
          ...payload,
          elements:
            params.pageNumber === 0
              ? [...payload.elements]
              : [...state.hubsData.elements, ...payload.elements],
          totalElements: payload.totalElements,
          totalPages: payload.totalPages
        };
      })
      .addCase(fetchHubDetails.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchAddHubCountryDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchAddHubCountryDropdownValues.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          let countriesList: any = {};
          if (payload.roleCountryMap) {
            Object.keys(payload.roleCountryMap).filter((role: any) => {
              countriesList[role] = transformSelectValues(payload.roleCountryMap[role]);
              return true;
            });
          }
          state.errorCode = "";
          state.error = "";
          state.addUserDropdowns = {
            countriesList: countriesList,
          };
        }
      })
      .addCase(fetchAddHubCountryDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.addUserDropdowns = {
          countriesList: {}
        };
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(createNewHub.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(createNewHub.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.hubCreationStatus = true;
          state.hubCreationMsg = `Hub ${payload.id} ${payload.name} has been added successfully`;
        }
      })
      .addCase(createNewHub.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.hubCreationStatus = false;
        state.hubCreationMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateHubDetails.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(updateHubDetails.fulfilled, (state, action) => {
        const {
          payload
        } = action;
        state.loading = false;
        if (payload.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload
            ? payload.message
            : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          state.errorCode = "";
          state.error = "";
          state.hubUpdateStatus = true;
          state.hubUpdateMsg = `Hub Details Updated`;
        }
      })
      .addCase(updateHubDetails.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.hubUpdateStatus = false;
        state.hubUpdateMsg = "";
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload
          ? errorPayload.message
          : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
  },
});

export default UsersSlice.reducer;
