import React, { createRef, useState } from "react";

import { Card } from "@material-ui/core";

import AssignDriverDialogContent from "./AssignDriverDialogContent";

interface AssignDriverDialogInterface {
  classes: any;
  countryCode: string;
  getUpdatedAssigneList: any;
  stateObj: any;
  driverValue: any;
  helperValue: any;
  vehicleValue: any;
  handleInputChange: (value: any, name: string) => void;
  toggleCreateSubroute: (routeId: string) => void;
  hubId: any;
  routeConfigDetails: any;
}

const AssignDriverDialog = (props: AssignDriverDialogInterface) => {
  const { classes, countryCode, getUpdatedAssigneList, stateObj, driverValue, helperValue, vehicleValue, handleInputChange, toggleCreateSubroute, hubId, routeConfigDetails } = props;
  const ref = createRef() as any;
  const [assignDriverState, setAssignDriverState] = useState<{ showPopUp: boolean }>({ showPopUp: true });

  const handleHideShowPopUp = () => {
    setAssignDriverState({ ...assignDriverState, showPopUp: !assignDriverState.showPopUp });
  };

  return (
    <Card className={assignDriverState.showPopUp ? classes.assignDriverHelperCardStyle : classes.assignDriverHelperClosedCardStyle} ref={ref}>
      <AssignDriverDialogContent
        classes={classes}
        countryCode={countryCode}
        getUpdatedAssigneList={getUpdatedAssigneList}
        stateObj={stateObj}
        driverValue={driverValue}
        helperValue={helperValue}
        vehicleValue={vehicleValue}
        handleInputChange={handleInputChange}
        showPopUp={assignDriverState.showPopUp}
        handleHideShowPopUp={handleHideShowPopUp}
        toggleCreateSubroute={toggleCreateSubroute}
        hubId={hubId}
        routeConfigDetails={routeConfigDetails}
      />
    </Card>
  );
};

export default React.memo(AssignDriverDialog);
