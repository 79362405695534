import React, { forwardRef, ReactElement, Ref, useCallback, useState, useEffect } from 'react';
import CustomDialog from '../common/Dialog';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../config/redux/reducers";
import { useStyles } from './UpdatePartialRejection.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Button, Grid, Typography } from '@material-ui/core';
import AppConstants from '../constants';
import { fixDecimals } from 'utils/helpers.utils';
import SelectBox from '../common/SelectBox';
import { fetchRejectionReasons } from './redux/carrefourDeliverySlice';
import { rejectionDataTransform } from './../mocks/carrefourHomeDelivery/response.transforms';
import { decodeHtmlEntities } from 'utils/textUtils';
interface UpdatePartialRejectionProps {
    open: boolean;
    consignment: any;
    closePopup: () => void;
    handleDialogAction?: (params: any) => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const UpdatePartialRejection = (props: UpdatePartialRejectionProps) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { rejectionReasonList, historyAndProducts } = useSelector(
        (state: AppState) => state.carrefourDelivery
    );
    const { countryCode } = useSelector(
        (state: AppState) => state.common
    );
    const { open, consignment, closePopup, handleDialogAction } = props;
    const generalReason = AppConstants.SELECT_NONE.value;
    const [rejections, setRejections] = useState([] as any);
    const [products, setProducts] = useState([] as any);
    let metadata: any = {};
    let deliveryInformation: any = {};

    if (consignment) {
        deliveryInformation = consignment.deliveryInformation;
        metadata = consignment.metadata;
    }

    const dialogPaperProps = {
        classes: {
            root: classes.dialogPaperPropsRoot
        },
        square: true
    }

    const handleDialogClose = useCallback(
        () => {
            closePopup();
        },
        [closePopup],
    );

    const dialogActionHandler = useCallback(
        (type: string, values?: any) => {
            if (type === 'save') {
                handleDialogAction && handleDialogAction({
                    type: type,
                    values: values
                });
            } else {
                closePopup();
            }
        },
        [closePopup, handleDialogAction],
    );

    const handleCommonReasonChange = ((event: any) => {
        let commonReason: any = [];
        rejectionReasonList.map((reason: any) => {
            let productsCopy: any = [...products];
            if (reason.value === event) {
                commonReason.push(reason);
                productsCopy.forEach((prod: any) => {
                    if (prod.itemDeliveryReason.length) {
                        prod.itemDeliveryReason = commonReason
                    }
                });
            }
            setProducts([...productsCopy]);
            return productsCopy;
        })
        setRejections(commonReason);
    });

    const handleItemReasonChange = ((event: any, fieldName: any) => {
        let itemReason: any = [];
        rejectionReasonList.map((reason: any) => {
            let productsCopyNew: any = [...products];
            if (reason.value === event) {
                itemReason.push(reason);
                productsCopyNew.forEach((prod: any) => {
                    if (prod.id === fieldName) {
                        prod.itemDeliveryReason = itemReason
                    }
                });
            }
            setProducts([...productsCopyNew]);
            return productsCopyNew;
        })
        setRejections(itemReason);
    });

    const handleUpdatedRejectedQty = ((event: any, fieldName: any) => {
        let updateProducts: any = [...products];
        updateProducts.forEach((prod: any) => {
            if (prod.id === fieldName) {
                prod.rejectedQty = event
            }
            setProducts([...updateProducts]);
        });
    });

    const getItems = () => {
        return products && products.length ? products.map((item: any, index: number) => (
            <Grid key={`${item.name.en} - ${index}`} className="item" item>
                <Grid className="itemContainer" container>
                    <Grid className="itemColumn item1" item xs={5}>
                        <Typography className="itemsContent">{item.substitutedProducts && item.substitutedProducts.length && item.substitutedProducts[0].name && item.substitutedProducts[0].name.en ? decodeHtmlEntities(item.substitutedProducts[0].name.en.toLowerCase()) : item.name && item.name.en ? decodeHtmlEntities(item.name.en.toLowerCase()) : ""}</Typography>
                    </Grid>
                    <Grid className="itemColumn item2" item xs={2}>
                        <Typography className="itemsContent">
                            <SelectBox
                                className="select"
                                value={item.selectedRejectedQty[0].value}
                                id="rejectedQty"
                                inputProps={{
                                    name: "rejectedQty",
                                    id: "rejectedQty"
                                }}
                                items={item.rejectedQtyList || []}
                                handleChange={(e) => handleUpdatedRejectedQty(e, item.id)}
                            ></SelectBox>
                        </Typography>
                    </Grid>
                    <Grid className="itemColumn item2" item xs={3}>
                        <Typography className="itemsContent">
                            <span className="green">{item.substitutedProducts && item.substitutedProducts.length ? item.substitutedProducts[0].shippedQty : item.shippedQty || ""}</span>/{`${item.quantity || ""} ${item.uom && item.uom === 'Grams' ? item.uom : ""}`}</Typography>
                    </Grid>
                    <Grid className="itemColumn item3" item xs={2}>
                        <Typography className="itemsContent">{`${fixDecimals(2, item.unitPrice ? item.unitPrice.toString() : "")} ${metadata && metadata.currency}`}</Typography>
                    </Grid>
                    {item.itemDeliveryReason && item.itemDeliveryReason.length ?
                        (
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item style={{ display: "flex", alignItems: "center" }}>
                                        <Typography style={{ fontSize: "10px", color: "#2D2D2D", fontWeight: 600 }}>Rejection Reason : </Typography>
                                    </Grid>
                                    <Grid item style={{ paddingLeft: "8px" }}>
                                        <SelectBox
                                            variant="standard"
                                            disableUnderline
                                            value={item.itemDeliveryReason && item.itemDeliveryReason[0] && item.itemDeliveryReason[0].value}
                                            id="reason"
                                            inputProps={{
                                                name: "reason",
                                                id: "reason"
                                            }}
                                            menuPaperProps={classes.menuPropsPaper}
                                            menuPropsList={classes.menuPropsList}
                                            classes={{
                                                root: classes.selectRoot,
                                                icon: classes.icon
                                            }}
                                            items={rejectionReasonList || []}
                                            handleChange={(e) => handleItemReasonChange(e, item.id)}
                                        ></SelectBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}
                </Grid>
            </Grid>
        )) : (
                <Grid style={{ padding: "12px" }}><Typography style={{ fontSize: "14px", fontWeight: 500 }}>No Items to display</Typography></Grid>
            )
    }

    const getTitleContent = () => {
        return (
            <Grid className={classes.titleContainer} container>
                <Grid item>
                    <Grid className="headingContainer" container>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography className="titleHeading">
                                        <span className="label">Update Rejected Items</span>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                                            <Typography style={{ fontSize: "12px", color: "#0E5AA7", fontWeight: 600 }}>Common Rejection Reason : </Typography>
                                        </Grid>
                                        <Grid item style={{ paddingLeft: "8px" }}>
                                            <SelectBox
                                                fullWidth
                                                showSelectNone={false}
                                                variant="standard"
                                                value={generalReason}
                                                id="reason"
                                                menuPaperProps={classes.menuPropsPaper}
                                                menuPropsList={classes.menuPropsList}
                                                classes={{
                                                    root: classes.generalReasonRoot,
                                                    icon: classes.generalReasonIcon
                                                }}
                                                items={rejectionReasonList || []}
                                                disableUnderline
                                                handleChange={(e) => handleCommonReasonChange(e)}
                                            ></SelectBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    }

    const getDetailsContent = () => {
        return (
            <Grid className={classes.contentContainer} container>
                <Grid container className="content">
                    <Grid container className="itemContainer">
                        <Grid className="itemColumn item1" item xs={5}>
                            <Typography className={classes.title}>Product Name</Typography>
                        </Grid>
                        <Grid className="itemColumn item2" item xs={2} style={{ display: "flex", flexDirection: "column" }}>
                            <Typography className={classes.title}>Rejected</Typography>
                            <Typography className={classes.title} style={{ paddingTop: "4px" }}>Qty</Typography>
                        </Grid>
                        <Grid className="itemColumn item2" item xs={3}>
                            <Typography className={classes.title}>Shipped/</Typography>
                            <Typography className={classes.title} style={{ paddingTop: "4px" }}>Ordered Qty</Typography>
                        </Grid>
                        <Grid className="itemColumn item3" item xs={2}>
                            <Typography className={classes.title}>Unit Price</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {getItems()}
                <Grid className="paymentContainer" container direction="column">
                    <Grid className="paymentItem" item>
                        <Grid container>
                            <Grid item>
                                <Typography>Grand Total<span className="vat">(inclusive of VAT)</span></Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                                <Typography>{metadata ? `${fixDecimals(2, metadata.totalAmount ? metadata.totalAmount.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="paymentItem" item>
                        <Grid container>
                            <Grid className="paymentItemColumn" item>
                                <Typography>Payment Method</Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                                <Typography>{metadata ? metadata.paymentType : ""}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="paymentItem" item>
                        <Grid container>
                            <Grid className="paymentItemColumn" item>
                                <Typography>Amount to be refunded</Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                                <Typography>{deliveryInformation && metadata ? `${fixDecimals(2, deliveryInformation.deliveryCost ? deliveryInformation.deliveryCost.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="paymentItem" item>
                        <Grid container>
                            <Grid className="paymentItemColumn" item>
                                <Typography>Revised Total</Typography>
                            </Grid>
                            <Grid className="paymentItemColumn right" item>
                                <Typography>{deliveryInformation && metadata ? `${fixDecimals(2, deliveryInformation.deliveryCost ? deliveryInformation.deliveryCost.toString() : "") || ""} ${(metadata && metadata.currency) || ""}` : ""}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        )
    }

    const getDialogActions = () => {
        return (
            <Grid className={classes.buttons} container>
                <Button className="userBtn secondary" variant="contained" onClick={() => { dialogActionHandler('cancel') }}>{AppConstants.BUTTONS.CANCEL}</Button>
                <Button className="userBtn primary" variant="contained" type="submit" onClick={() => { dialogActionHandler('save') }}>{AppConstants.BUTTONS.SAVE}</Button>
            </Grid>
        );
    };

    const fetchAllRejectionReasons = useCallback(
        (countryCode: any) => {
            dispatch(
                fetchRejectionReasons({
                    countryCode: countryCode
                })
            );
        }, [dispatch]
    );

    useEffect(() => {
        if (consignment != null) {
            fetchAllRejectionReasons(countryCode);
        }
    }, [consignment, countryCode, fetchAllRejectionReasons]);

    useEffect(() => {
        setProducts(rejectionDataTransform(historyAndProducts) as any);
    }, [historyAndProducts])

    return (
        <CustomDialog
            open={open}
            TransitionComponent={Transition}
            PaperProps={dialogPaperProps}
            title={getTitleContent()}
            actions={getDialogActions()}
            content={getDetailsContent()}
            handleClose={handleDialogClose}
        ></CustomDialog>
    )
}

export default UpdatePartialRejection;