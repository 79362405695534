import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {COLORS} from "../config/theme/baseTheme";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        titleContainer: {
            '& .headingContainer': {
                flexDirection: "column",
                '& .titleHeading': {
                    fontSize: 18,
                    fontWeight: 500,
                    '& .content': {
                        fontWeight: 600
                    }
                },
                '& .titleContent': {
                    fontSize: 12,
                    fontWeight: 600,
                    '&.link': {
                        cursor: "pointer"
                    }
                },
                "& .statusContainer": {
                    "& .statusIcon": {
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        '&.yellow': {
                            backgroundColor: COLORS.STATUS_YELLOW
                        },
                        '&.black': {
                            backgroundColor: COLORS.TEXT_PRIMARY
                        },
                        '&.green': {
                            backgroundColor: COLORS.PERSIAN_GREEN
                        },
                        '&.red': {
                            backgroundColor: COLORS.DARK_RED
                        },
                        '&.gray': {
                            backgroundColor: COLORS.GRAY_DARK
                        },
                    },
                    '& .statusMsg': {
                        paddingLeft: theme.spacing(1),
                        '& .status': {
                            fontSize: 12
                        }
                    }
                },
                '& .editIcon': {
                    paddingLeft: theme.spacing(1.5),
                    '& .icon': {
                        cursor: "pointer",
                        verticalAlign: "middle"
                    }
                }
            },
            '& .tagContainer': {
                cursor: "pointer",
                marginLeft: theme.spacing(2),
                alignSelf: "center"
            }
        },
        contentContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            padding: "0px",
            '& .tabValues': {
                padding: "0px",
                paddingTop: "16px"
            },
            '& .userDetails': {
                backgroundColor: "#F4FBFE",
            },
            '& .detailsHeader': {
                padding: theme.spacing(3),
                borderRadius: theme.spacing(1),
                '& .detailsHeaderItem': {
                    padding: theme.spacing(2.5, 0, 0, 2),
                    '&:first-child': {
                        padding: 0
                    },
                    '& .profileIcon': {
                        width: 100,
                        height: 100
                    },
                    '& .rightPanelContainer': {
                        flexDirection: "column",
                        '& .rightPanelItem': {
                            paddingBottom: theme.spacing(1.5),
                            '&:last-child': {
                                paddingBottom: 0
                            },
                            '& .endSessionBtn': {
                                minWidth: "245px",
                                width: "100%"
                            },
                            '& .fullname': {
                                fontSize: 24,
                                fontWeight: 600
                            },
                            '& .logo': {
                                width: 20,
                                height: 20
                            },
                            '& .contact': {
                                '& a': {
                                    fontSize: 14,
                                    fontWeight: 600,
                                    textDecoration: "none",
                                    color: theme.palette.text.primary
                                },
                                paddingLeft: theme.spacing(1),
                                '&.mobile': {
                                    paddingLeft: theme.spacing(1)
                                }
                            }
                        }
                    }
                }
            },
            '& .detailsContent': {
                flexDirection: "column",
                flexWrap: "nowrap",
                padding: theme.spacing(3, 0, 2, 0),
                '& .contentRow': {
                    paddingBottom: theme.spacing(3),
                    '& .contentRowContainer': {
                        justifyContent: "space-between"
                    },
                    '&:last-child': {
                        paddingBottom: 0
                    },
                    '& .contentColumn': {
                        flex: "2 0 0",
                        '&:last-child': {
                            flex: "1.5 0 0"
                        },
                        '& p': {
                            fontSize: 14,
                            lineHeight: "17px"
                        },
                        '& .label': {
                            paddingBottom: theme.spacing(1)
                        },
                        '& .content': {
                            fontWeight: 600,
                        },
                        '& .lastItem': {
                            display: "flex",
                            justifyContent: "center"
                        }
                    },
                    '&.twoItems': {
                        '& .contentColumn': {
                            '&:last-child': {
                                flex: "2.5 0 0"
                            }
                        }
                    },
                    '&.btnContainer': {
                        '& .contentColumn': {
                            flex: "1 0 auto",
                            '& .resetPasswordBtn': {
                                width: "100%"
                            },
                            '& .endSessionBtn': {
                                width: "300px"
                            },
                        }
                    }
                }
            },
            '& .hubItemsSection': {
                '& .content': {
                    width: "100%",
                    '& .itemsContainer': {
                        flexWrap: "nowrap",
                        '& .item': {
                            padding: theme.spacing(2, 0),
                            borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                            "&:first-child": {
                                padding: 0,
                                paddingBottom: theme.spacing(2)
                            },
                            '&:last-child': {
                                borderBottom: "none"
                            },
                            '& .itemContainer': {
                                justifyContent: "space-between",
                                '& p': {
                                    fontSize: 14,
                                    lineHeight: "17px",
                                    fontWeight: 600
                                },
                                '&.header p': {
                                    color: COLORS.DOVE_GRAY,
                                    fontWeight: 500,
                                }
                            }
                        }
                    }
                }
            }
        },
        tabs: {
            paddingTop: "0px",
            paddingLeft: "0px",
            minHeight: "38px",
            '& .tabLabel': {
                fontSize: "16px",
                fontWeight: 700
            }
        },
        timeline: {
            padding: "0 !important",
            margin: 0,
            '& .timelineItem': {
                minHeight: 50,
                '&:last-child': {
                    minHeight: "auto"
                },
                '&:before': {
                    content: "none"
                },
                '& .timelineDot': {
                    padding: 0,
                    margin: 0,
                    border: "none",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    '& .dotIcon': {
                        width: 18,
                        height: 18,
                        padding: 5,
                        '&.active': {
                            padding: 0
                        }
                    },
                },
                '& .primaryConnector': {
                    backgroundColor: COLORS.TRANSPARENT,
                    borderLeft: `1px dashed ${COLORS.SILVER}`
                },
                '& .timelineContent': {
                    padding: 0,
                    paddingLeft: theme.spacing(1),
                    '& .heading': {
                        paddingTop: 1,
                        fontSize: 14,
                        lineHeight: "14px",
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                        '& .order': {
                            fontSize: "12px"
                        },
                        '&.error': {
                            color: COLORS.DARK_RED,
                            '&.active': {
                                paddingBottom: 17
                            }
                        },
                        '&.active': {
                            // paddingBottom: 17
                        },
                        '& .dateTime': {
                            fontSize: 12,
                            lineHeight: "15px",
                            fontWeight: 500,
                            color: COLORS.DOVE_GRAY,
                            paddingLeft: theme.spacing(0.5),
                            verticalAlign: "bottom",
                        }
                    },
                    '& .metadataContainer': {
                        paddingBottom: theme.spacing(1.5),
                        '& .title': {
                            fontSize: 12,
                            lineHeight: "10px",
                            paddingTop: theme.spacing(1),
                            paddingBottom: theme.spacing(1),
                            fontWeight: 500,
                            color: COLORS.COPY
                        },
                        '& .description': {
                            fontSize: 10,
                            lineHeight: "10px",
                            color: COLORS.DOVE_GRAY_LIGHT
                        }
                    }
                }
            }
        },
        statusHeaderContainer: {
            '& .tag': {
                background: "#FFFFFF",
                border: "1px solid #319E60",
                borderRadius: theme.spacing(0.5),
                padding: theme.spacing(0.5, 1),
                fontWeight: 600,
                fontSize: 12,
                color: "#319E60",
                lineHeight: "14px",
                height: "24px",
                textTransform: "uppercase",
                '&.INFO_BLUE': {
                    borderColor: COLORS.INFO_BLUE,
                    color: COLORS.INFO_BLUE
                },
                '&.SEA_GREEN': {
                    borderColor: COLORS.SEA_GREEN,
                    color: COLORS.SEA_GREEN
                },
                '&.OCEAN_GREEN': {
                    borderColor: COLORS.OCEAN_GREEN,
                    color: COLORS.OCEAN_GREEN
                },
                '&.GOLDEN_BELL': {
                    borderColor: COLORS.GOLDEN_BELL,
                    color: COLORS.GOLDEN_BELL
                },
                '&.GREY_SCALE': {
                    borderColor: COLORS.GREY_SCALE,
                    color: COLORS.GREY_SCALE
                }
            }
        },
        accordionSummary: {
            '& .header': {
                fontSize: 14,
                lineHeight: "17px",
                fontWeight: 600
            },
            '& .content': {
                flexGrow: 1
            }
        },
        buttons: {
            width: "100%",
            '& .userBtn': {
                '&.secondary': {
                    marginRight: theme.spacing(2)
                },
                flexGrow: 1
            }
        },
        radioLabel: {
            fontSize: "14px"
        },
        dialogPaperPropsRoot: {
            margin: 0,
            marginLeft: "auto",
            maxHeight: "none",
            height: "100vh",
            width: 580
        },
        endSessionSelectionDialogTitle: {
            fontSize: 20,
            fontWeight: 600,
            padding: "6px 0px 0px 12px"
        },
        endSessionSelectionDialogPaperPropsRoot: {
            width: 428,
            height: 242,
            margin: "auto",
            borderRadius: theme.spacing(1)
        },
        content: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "15px",
            color: "#252525"
        },
        endSessionSelectionDialogContentContainer: {
            flexDirection: "column",
            flexWrap: "nowrap",
            '& .contentItem': {
                '&.heading': {
                    '& p': {
                        fontSize: 14,
                        fontWeight: 400
                    }
                },
                '&.hub': {
                    paddingTop: theme.spacing(3.75)
                }
            }
        },
        endSessionDialogButtonsContainer: {
            justifyContent: "center",
            paddingBottom: theme.spacing(1),
            '& .dialogBtn': {
                paddingLeft: theme.spacing(4.5),
                paddingRight: theme.spacing(4.5),
                '&.secondary': {
                    marginRight: theme.spacing(1)
                },
                '&.primary': {
                    marginLeft: theme.spacing(1)
                }
            }
        },
    }),
);