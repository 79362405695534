/**
 * Decodes HTML entities in a given string.
 *
 * This function creates a temporary textarea element, sets its innerHTML to the provided text,
 * and then retrieves the decoded value from the textarea.
 *
 * @param {string} text - The string containing HTML entities to decode.
 * @returns {string} - The decoded string.
 *
 * @example
 * const encodedString = "&lt;div&gt;Hello &amp; welcome!&lt;/div&gt;";
 * const decodedString = decodeHtmlEntities(encodedString);
 * console.log(decodedString); // <div>Hello & welcome!</div>
 */
export const decodeHtmlEntities = (text: string): string => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = text;
  return textarea.value;
};
