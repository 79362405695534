import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { isUndefined } from "utils/helpers.utils";
//Material UI Components
import {
  Grid,
  Typography,
  Button,
  Box,
  SvgIcon,
  TextField,
} from "@material-ui/core";

import AppConstants from "../constants";
import { useStyles } from "./stagingArea.styles";

//common components
import CustomDialog from "../common/Dialog";
import TextBox from "../common/TextBox";
import NoDataComponent from "./NoDataComponent";
import CloseIcon from "@material-ui/icons/Close";
import SearchableList from "./SearchableListItems";
import { ReactComponent as trashIcon } from "./../assets/icons/trash-grey.svg";
import { ReactComponent as done } from "./../assets/icons/check.svg";
import { ReactComponent as editIconNew } from "./../assets/icons/pencil-grey.svg";
import { ReactComponent as refreshicon } from "./../assets/icons/refresh-With-Text.svg";
import { ReactComponent as plus } from "./../assets/icons/plus-sign.svg";
import { useOutsideClickHandler } from "../utils/helpers.utils";
import { ReactComponent as searchIcon } from "./../assets/icons/search-icon.svg";
import ChipComponent from "./Chips";
import Notification from "./Notfication";
import SelectBox from "../common/SelectBox";
import Loader from "../common/Loader";
import useTimer from "./CustomTimer";

// redux
import { AppState } from "../config/redux/reducers";
import {
  fetchStagingArea,
  createStagingArea,
  updateStagingArea,
  deleteStagingArea,
  linkStagingArea,
  unLinkStagingArea,
} from "./redux/stagingAreaSlice";

const StagingArea = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { countryCode, hubRecordList, hubRecordListWithCodeAndName } = useSelector(
    (state: AppState) => state.common
  );
  const {
    error,
    stagingCreateResponse,
    stagingUpdateResponse,
    stagingDeleteResponse,
    stagingAreaList,
    deliveryAreaList,
    unLinkArea,
    linkArea,
    loading,
  } = useSelector((state: AppState) => state.stagingArea);
  const confirmDialogPaperProps = {
    classes: {
      root: classes.confirmDialogPaperPropsRoot,
    },
  };
  const [stagingAreaHub, setStagingAreaHub] = useState(
    isUndefined(
      hubRecordList[
        sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode
      ]
    )
      ? ""
      : hubRecordList[
          sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode
        ][0].value
  );

  const [editedItem, setEditedItem] = useState<any>();
  const [editedArea, setEditedArea] = useState<any>();
  const [notificationSuccessMsg, setNotificationSuccessMsg] = useState("");
  const [openNameInputDialog, setOpenNameInputDialog] = useState(false);
  const [openUnlinkAreaDialog, setOpenUnlinkAreaDialog] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [
    openDeleteStagingAreaDialog,
    setOpenDeleteStagingAreaDialog,
  ] = useState(false);
  const [searchVal, setSeachValue] = useState("");

  useEffect(() => { 
    setStagingAreaHub(
      isUndefined(
        hubRecordList[
          sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode
        ]
      )
        ? ""
        : hubRecordList[
            sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode
          ][0].value
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubRecordList]);

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      inputFormik.setErrors({ name: error });
      inputFormik2.setErrors({ name: error });
      setShowSuccessMsg(false);
      !openNameInputDialog && alertHandlerErr();
    }
    else{
      setShowErrorMsg(false);
    if (Object.keys(stagingCreateResponse).length > 0) {
      getStagingArea();
      setOpenNameInputDialog(false);
      inputFormik.resetForm()
      alertHandlerSucc();
      setNotificationSuccessMsg(
        AppConstants.STAGING_AREA_CONSTANTS.SUCCESS_MSG.create
      );
    }
    if (Object.keys(stagingUpdateResponse).length > 0) {
      setEditedItem("");
      getStagingArea();
      setNotificationSuccessMsg(
        AppConstants.STAGING_AREA_CONSTANTS.SUCCESS_MSG.update
      );
      alertHandlerSucc();
    }
    if (stagingDeleteResponse) {
      getStagingArea();
      setOpenDeleteStagingAreaDialog(false);
      setNotificationSuccessMsg(
        AppConstants.STAGING_AREA_CONSTANTS.SUCCESS_MSG.delete
      );
      alertHandlerSucc();
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    stagingCreateResponse,
    stagingUpdateResponse,
    stagingDeleteResponse,
  ]);

  useEffect(() => {
    if ( stagingAreaHub || unLinkArea || linkArea) {
      getStagingArea();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stagingAreaHub, unLinkArea, linkArea]);

  const getInitialValues = () => {
    return {
      name: "",
    };
  };
  const getInitialValues2 = () => {
    return {
      name: editedItem,
    };
  };

  const inputValidationSchema = yup.object({
    name: yup
      .string()
      .min(
        AppConstants.STAGING_AREA_CONSTANTS.VALIDATIONS.NAME.MIN.value,
        AppConstants.STAGING_AREA_CONSTANTS.VALIDATIONS.NAME.MIN.msg
      )
      .max(
        AppConstants.STAGING_AREA_CONSTANTS.VALIDATIONS.NAME.MAX.value,
        AppConstants.STAGING_AREA_CONSTANTS.VALIDATIONS.NAME.MAX.msg
      )
      .required(
        AppConstants.STAGING_AREA_CONSTANTS.VALIDATIONS.NAME.REQUIRED.msg
      )
      .matches(
        AppConstants.STAGING_AREA_CONSTANTS.VALIDATIONS.NAME.VALIDITY.value,
        AppConstants.STAGING_AREA_CONSTANTS.VALIDATIONS.NAME.VALIDITY.msg
      ),
  });
  const inputFormik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: inputValidationSchema,
    onSubmit: (values) => {
      dialogActionHandler("submit", values);
    },
  });
  const inputFormik2 = useFormik({
    initialValues: getInitialValues2(),
    validationSchema: inputValidationSchema,
    onSubmit: (values) => {
      dialogUpdateStagingName("submit", values);
    },
  });

  const handleHubChange = useCallback((value: any) => {
      setEditedItem("")
      setEditedArea("")
      setShowSuccessMsg(false)
      setShowErrorMsg(false)
      setStagingAreaHub(value);
  }, []);

  const handleCreateActions = useCallback(
    (params: any) => {
      const reqParams: any = {
        countryCode: countryCode,
        hubId: stagingAreaHub,
        stagingArea: params?.values?.name,
      };

      if (params.type === "submit") {
        dispatch(
          createStagingArea({
            params: reqParams,
          })
        );
      }
    },
    [stagingAreaHub]
  );

  const dialogActionHandler = useCallback(
    (type: string, values?: any) => {
      handleCreateActions({
        type: type,
        values: { ...values },
      });
    },
    [handleCreateActions]
  );

  const getStagingArea = () => {
    const params: any = {
      countryCode: countryCode,
      hubId: stagingAreaHub,
    };
    dispatch(
      fetchStagingArea({
        params: params,
      })
    );
  };

  const dialogUpdateStagingName = useCallback(
    (type: string, values?: any) => {
      if (type === "submit" && values.name !== editedItem) {
        const params: any = {
          countryCode: countryCode,
          hubId: stagingAreaHub,
          stagingArea: editedItem,
          updatedStagingArea: values?.name,
        };
        dispatch(
          updateStagingArea({
            params: params,
          })
        );
      } else {
        setEditedItem("");
      }
    },
    [editedItem]
  );

  const dialogDeleteStagingNamehandler = () => {
    const params: any = {
      countryCode: countryCode,
      hubId: stagingAreaHub,
      stagingArea: editedArea,
    };
    dispatch(
      deleteStagingArea({
        params: params,
      })
    );
  };

  const linkAreaToStagingname = useCallback(
    (delArea?: any) => {
      const params: any = {
        countryCode: countryCode,
        hubId: stagingAreaHub,
        stagingArea: editedItem,
        deliveryArea: delArea,
      };

      dispatch(
        linkStagingArea({
          params: params,
        })
      );
    },
    [dispatch, editedItem, stagingAreaHub]
  );

  const unlinkAreaFromStagingname = () => {
    const params: any = {
      countryCode: countryCode,
      hubId: stagingAreaHub,
      stagingArea: editedItem,
      deliveryArea: editedArea,
    };

    dispatch(
      unLinkStagingArea({
        params: params,
      })
    );
    setOpenUnlinkAreaDialog(false);
  };

  const wrapperRef = useRef(null);
  const handleClickOutsideTest = (event: any) => {
    setEditedItem("");
  };
  const handleDiscardDialogClose = useCallback(() => {
    setOpenNameInputDialog(false);
    inputFormik.resetForm()
    setOpenUnlinkAreaDialog(false);
    setOpenDeleteStagingAreaDialog(false);
  }, []);

  const timer = useTimer();
  const alertHandlerSucc = () => {
    setShowErrorMsg(false)
    setShowSuccessMsg(true);
    timer.fn(() => {
      setShowSuccessMsg(false);
    }, 7000);
  };

  const alertHandlerErr = () => {
    timer.clearTimeoutHandler()
    setShowSuccessMsg(false)
    setShowErrorMsg(true);
    timer.fn(() => {
      setShowErrorMsg(false);
    }, 7000);
  };

  useEffect(() => {
    setShowSuccessMsg(false);
    setShowErrorMsg(false);
  }, []);

  const handleSearch = useCallback((e?: any) => {
    setSeachValue(e.target.value);
  }, []);

  const discardDialogActionHandler = useCallback(
    (type: string) => {
      if (type === "cancel") {
        handleDiscardDialogClose();
      }
    },
    [handleDiscardDialogClose]
  );

  const openInputDilog = useCallback(() => {
    
    setOpenNameInputDialog(true);
  }, []);

  const openDeleteStagingAreaDilog = useCallback((item) => {
    if(stagingAreaHub ){
    setEditedArea(item);
    setEditedItem("")
    setOpenDeleteStagingAreaDialog(true);
    }
  }, [stagingAreaHub]);

  const onCheckboxSelect = useCallback(
    (value) => {
      linkAreaToStagingname(value);
    },
    [editedItem, stagingAreaHub]
  );

  const handleDeleteChip = useCallback((h: string) => {
    setEditedArea(h);
    setOpenUnlinkAreaDialog(true);
  }, []);

  const editStagingArea = (item: any) => {
    if(stagingAreaHub ){
    setEditedItem(item);
    inputFormik2.setFieldValue("name", item);
    }
  };

  const getChangeDialogTitleContent = () => {
    return <></>;
  };

  const getStagingNameDialogDetailsContent = () => {
    return (
      <form className={classes.buttons} onSubmit={inputFormik.handleSubmit}>
        <Grid className={classes.confirmDialogContentContainer} container>
          <Grid className="contentItem heading" item>
            <Typography>Provide a name For this staging Location</Typography>
            <Grid className="itemInput" item>
              <TextBox
                fullWidth
                variant="outlined"
                onChange={inputFormik.handleChange}
                value={inputFormik.values.name}
                error={
                  inputFormik.touched.name && Boolean(inputFormik.errors.name)
                }
                helperText={inputFormik.touched.name && inputFormik.errors.name}
                textBoxId="StagingName"
                name="name"
                type="text"
                placeholderText="Enter Name"
              ></TextBox>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  const getStagingNameDialogActions = () => {
    return (
      <form className={classes.formik} onSubmit={inputFormik.handleSubmit}>
        <Grid className={classes.confirmDialogButtonsContainer}>
          <Button
            className="dialogBtn secondary"
            variant="contained"
            onClick={() => {
              discardDialogActionHandler("cancel");
            }}
          >
            {"CANCEL"}
          </Button>
          <Button
            className="dialogBtn primary"
            variant="contained"
            type="submit"
            onClick={() => {
              discardDialogActionHandler("proceed");
            }}
          >
            {"PROCEED"}
          </Button>
        </Grid>
      </form>
    );
  };
  const unlinkAreaDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>Are you sure you want to unlink the area ?</Typography>
        </Grid>
        <Grid className="contentItem subHeading" item>
          <Typography>This change cannot be undone</Typography>
        </Grid>
      </Grid>
    );
  };

  const unlinkAreaDialogDetailsAction = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button
          className="dialogBtn secondary"
          variant="contained"
          onClick={() => {
            discardDialogActionHandler("cancel");
          }}
        >
          {"No, Keep The Area"}
        </Button>
        <Button
          className="dialogBtn primary"
          variant="contained"
          onClick={() => unlinkAreaFromStagingname()}
        >
          {"Proceed"}
        </Button>
      </Grid>
    );
  };

  const deleteStagingAreaDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>
            Do you want to proceed deleting this staging location?
          </Typography>
        </Grid>
        <Grid className="contentItem subHeading" item>
          <Typography>This change cannot be undone</Typography>
        </Grid>
      </Grid>
    );
  };

  const deleteStagingAreaDialogDetailsAction = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button
          className="dialogBtn secondary"
          variant="contained"
          onClick={() => {
            discardDialogActionHandler("cancel");
          }}
        >
          {"No, Keep The Area"}
        </Button>
        <Button
          className="dialogBtn primary"
          variant="contained"
          onClick={() => {
            dialogDeleteStagingNamehandler();
          }}
        >
          {"Proceed"}
        </Button>
      </Grid>
    );
  };

  const editMode = () => (
    <Box style={{ display: "flex" }}>
      <Typography className="editedHeader">{"Staging Location "}</Typography>
      <TextField
        variant="standard"
        onChange={inputFormik2.handleChange}
        value={inputFormik2.values.name}
        error={inputFormik2.touched.name && Boolean(inputFormik2.errors.name)}
        helperText={inputFormik2.touched.name && inputFormik2.errors.name}
        id="StagingName"
        name="name"
        type="text"
        className="textfld"
      ></TextField>
    </Box>
  );

  //useOutsideClickHandler(wrapperRef, handleClickOutsideTest);

  const RenderStagLocation = () => {
    return (
      <Box flex={1} className={`${classes.stagingAreaContent}`}>
        {stagingAreaList.map((item: any, index: number) => (
          <Grid
            key={item.stagingName +'_'+ index}
            className={
              editedItem === item.stagingName
                ? `${classes.titel} ${classes.stagingLoc} edited`
                : `${classes.titel} ${classes.stagingLoc}`
            }
          >
           
            <Box className="heading">
              {editedItem === item.stagingName ? (
                editMode()
            
              ) : (
                <Box style={{ display: "flex" }}>
                  <Typography>{"Staging Location "}</Typography>
                  <Typography>{item.stagingName}</Typography>
                </Box>
              )}
              <Grid className="icon" item>
                {editedItem === item.stagingName ? (
                  <form
                    onSubmit={inputFormik2.handleSubmit}
                    style={{ display: "initial" }}
                  >
                    <label>
                      <input type="submit" style={{ display: "none" }} />
                      <SvgIcon
                        className={`${classes.icon} done`}
                        component={done}
                        viewBox="0 0 17 12"
                      />
                    </label>
                  </form>
                ) : (
                  <SvgIcon
                    className={classes.icon}
                    component={editIconNew}
                    onClick={() => editStagingArea(item.stagingName)}
                  />
                )}
                <SvgIcon
                  className={classes.icon}
                  component={trashIcon}
                  onClick={() => openDeleteStagingAreaDilog(item.stagingName)}
                />
              </Grid>
            </Box>
            {item?.deliveryAreas && item?.deliveryAreas?.length > 0 ? (
              <>
                {" "}
                <Typography className="subHeading">
                  {
                    "Please check/uncheck available area from left pannel to add/delete to the list"
                  }
                </Typography>
                <Grid container direction="row" className={classes.pilles}>
                  <ChipComponent
                    item={item.deliveryAreas}
                    editable={ editedItem === item.stagingName ? true : false}
                    onDeleteCallback={handleDeleteChip}
                    DeleteIcon={CloseIcon}
                  />
                </Grid>
              </>
            ) : (
              <NoDataComponent
                title={"No results to show"}
                icon={searchIcon}
                actionText={"Add Areas"}
                actionCallback={() => editStagingArea(item.stagingName)}
              />
            )}
          </Grid>
        ))}
      </Box>
    );
  };

  const NotificationMessage = () => {
    return (
      <>
        {showErrorMsg && Object.keys(error)?.length > 0 && (
          <Notification
            variant="warning"
            title={error}
            actionCallback={removeErrorMsg}
          />
        )}
        {showSuccessMsg && notificationSuccessMsg && (
          <Notification
            variant="success"
            title={notificationSuccessMsg}
            actionCallback={removeSuccessMsg}
          />
        )}
      </>
    );
  };
  const removeErrorMsg = () => {
    setShowErrorMsg(false);
  };
  const removeSuccessMsg = () => {
    setShowSuccessMsg(false);
  };

  return (
    <div className={classes.root}>
      {loading && <Loader></Loader>}
      <Grid container justify="space-between">
        <Grid
          item
          xs={10}
          className={`${(showErrorMsg||showSuccessMsg) ? classes.notificatin : classes.heading}`}
        >
          {!(showSuccessMsg || showErrorMsg) && `Staging Location Mapping ( ${stagingAreaHub} )`}
          <NotificationMessage />
        </Grid>
        <Grid
          item
          xs={2}
          style={{ display: "flex", justifyContent: "flex-end" }}
          className={classes.searchHub}
        >
          <SelectBox
            handleChange={handleHubChange}
            className={classes.hubSearch}
            value={stagingAreaHub}
            label="Hub"
            enableSearch={true}
            id="dashboard-hub-select-box"
            items={hubRecordListWithCodeAndName[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || []}
          ></SelectBox>
        </Grid>
      </Grid>

      <Grid container justify="space-between">
        <Grid
          item
          xs={9}
          className={`${classes.tiles} ${classes.areaTile}`}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid container style={{ paddingTop: "16px" }}>
            <Grid
              container
              direction="column"
              className={classes.graphDivision}
            >
              <Grid container style={{ marginBottom: 8 }}>
                <Grid className={classes.heading}>Staging Locations</Grid>

                {stagingAreaHub && stagingAreaList?.length >0 && <Button
                  className={`${classes.titel} ${classes.addButton} primary`}
                  onClick={openInputDilog}
                >
                  {"Add Staging Location"}
                  <SvgIcon
                          className={`${classes.icon} plusIcon` }
                          component={plus}
                          viewBox="0 0 18 18"
                        />
                </Button>}
              </Grid>
              {stagingAreaList?.length > 0 ? (
               RenderStagLocation()
              ) : (
                <NoDataComponent
                  title={"No Staging Location Available"}
                  icon={searchIcon}
                  actionCallback={stagingAreaHub && openInputDilog}
                  actionText={"Create Staging Location"}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3} className={classes.tiles}>
          <Grid container style={{ paddingTop: "16px" }}>
            <Grid
              container
              direction="column"
              className={classes.graphDivision}
            >
              <Grid item>
                <Grid container>
                  <Grid item className={classes.searchContainer}>
                    <Grid container direction="row" justify="space-between">
                      <Grid item>
                        <Typography className={classes.heading}>
                          Available Delivery Area
                        </Typography>
                      </Grid>
                      <Grid item>
                        <SvgIcon
                          className={classes.icon}
                          component={refreshicon}
                          onClick={() => getStagingArea()}
                          viewBox="0 0 72 20"
                        />
                      </Grid>
                    </Grid>
                    {deliveryAreaList && deliveryAreaList?.length > 0 && (
                      <SearchableList
                        showCheckBox={editedItem ? true : false}
                        item={deliveryAreaList}
                        checkboxHandler={onCheckboxSelect}
                        searchValue={searchVal}
                        handleSearch={handleSearch}
                        listClass={classes.list}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomDialog
        open={openNameInputDialog}
        PaperProps={confirmDialogPaperProps}
        title={getChangeDialogTitleContent()}
        content={getStagingNameDialogDetailsContent()}
        actions={getStagingNameDialogActions()}
        handleClose={handleDiscardDialogClose}
      ></CustomDialog>
      <CustomDialog
        open={openUnlinkAreaDialog}
        PaperProps={confirmDialogPaperProps}
        title={getChangeDialogTitleContent()}
        content={unlinkAreaDialogDetailsContent()}
        actions={unlinkAreaDialogDetailsAction()}
        handleClose={handleDiscardDialogClose}
      ></CustomDialog>
      <CustomDialog
        open={openDeleteStagingAreaDialog}
        PaperProps={confirmDialogPaperProps}
        title={getChangeDialogTitleContent()}
        content={deleteStagingAreaDialogDetailsContent()}
        actions={deleteStagingAreaDialogDetailsAction()}
        handleClose={handleDiscardDialogClose}
      ></CustomDialog>
    </div>
  );
};

export default StagingArea;
