import React, { useEffect, useRef, useState } from "react";

import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  PopoverOrigin,
  Select,
  SelectProps,
  ListSubheader,
  TextField,
  InputAdornment,
  IconButton,
  SvgIcon,
} from "@material-ui/core";

import AppConstants from "../../constants";
import CustomHubMenuItems from "./CustomHubSelection/CustomHubMenuItems";

import { useStyles, anchorOriginDefault } from "./SelectBox.styles";
import { ReactComponent as downArrowIcon } from "./../../assets/icons/down-arrow.svg";
import { ReactComponent as searchIcon } from "./../../assets/icons/search-icon-small.svg";
import { isEqualArrays } from "../../utils/helpers.utils";

export interface SelectItemProps {
  name?: string;
  value: any;
  countryCode?: any;
}

interface CustomHubSelctBoxProps extends SelectProps {
  handleFormikChange?: (e: React.ChangeEvent<any>) => void;
  items: { [key: string]: Array<SelectItemProps> };
  helperText?: any;
  hideIconComponent?: boolean;
  menuPropsList?: string;
  menuPaperProps?: string;
  anchorOrigin?: PopoverOrigin | undefined;
  reset?: boolean;
  resetAll?: boolean;
  showSelectNone?: boolean;
  enableSearch?: boolean;
  classes?: any;
  customWidthSearchBar?: boolean;
  customCountryRecord?: any;
}

const CustomHubSelctBox = (props: CustomHubSelctBoxProps) => {
  const classes = useStyles();
  const anchorEl = useRef(null);

  const {
    handleFormikChange,
    value,
    multiple,
    id,
    label,
    items,
    hideIconComponent,
    menuPaperProps,
    menuPropsList,
    anchorOrigin,
    className,
    reset,
    resetAll,
    showSelectNone,
    error,
    helperText,
    fullWidth,
    enableSearch,
    customWidthSearchBar,
    customCountryRecord,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [ddnValue, setDdnValue] = useState<any>(value || AppConstants.SELECT_NONE.value);
  const [itemsDdn, setItemsDdn] = useState(items && Object.values(items).flat().map((item) => item.value));
  const [itemsPrevValues, setItemsPrevValues] = useState<SelectItemProps[]>([]);

  const checker = (arr: any, target: any) => arr.every((v: any) => target.includes(v.value));
  const checkerStringArr = (arr: any, target: any) => arr.every((v: string) => target.includes(v));

  const handleDdnChange = (event: any, key?: string, value?: string) => {
    event.stopPropagation();
    let newRecord = [] as any;
    if (key && value) {
      newRecord = [...ddnValue];
      if (newRecord.includes(value)) {
        const valueIndex = newRecord.indexOf(value);
        newRecord.splice(valueIndex, 1);
      } else {
        newRecord.push(value);
      }
    } else if (key && Object.keys(items).includes(key)) {
      if (checker(items[key], ddnValue)) {
        newRecord = ddnValue.filter((val: string) => !items[key].map((obj) => obj.value).includes(val));
      } else {
        const hubRecord = items[key];
        newRecord = [...ddnValue];
        hubRecord.forEach((obj: SelectItemProps) => {
          if (!newRecord.includes(obj.value)) {
            newRecord.push(obj.value);
          }
        });
      }
    } else {
      const result = checkerStringArr(itemsDdn, ddnValue);
      if (!result) {
        const missedValue = itemsDdn.filter((val) => !ddnValue.includes(val));
        newRecord = [...ddnValue, ...missedValue];
      }
    }
    setDdnValue(newRecord);
    handleFormikChange && handleFormikChange(newRecord);
  };

  const close = () => {
    setIsOpen(false);
    setSearchText("");
  };

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const getChips = (selected: any) => {     
    if (selected?.length === Object.values(items).flat()) {
      return AppConstants.SELECT_ALL.name;
    }
    if (selected?.length === 0) {
      return AppConstants.SELECT_NONE.name;
    }
    return (
      <div className={classes.chips}>
        {(selected as string[]).map((value, index) => {
          const item : any = Object.values(items).flat().find(i => i.value === value) !== undefined ? Object.values(items).flat().find(i => i.value === value) : {name: "", value: "" };
          return <Chip key={`Chip-${index}-${value}`} label={item.name} size="small" className={classes.chip} />;
        })}
      </div>
    );
  };

  useEffect(() => {
    if (!isEqualArrays(Object.values(items).flat(), itemsPrevValues, "value")) {      
      setItemsDdn([...Object.values(items).flat().map((item) => item.value)]);
      setDdnValue([...Object.values(items).flat().map((item) => item.value)]);
    }
    setItemsPrevValues(Object.values(items).flat());
  }, [items]);

  useEffect(() => {
    if (reset) {
      setDdnValue(itemsDdn);
    } else {
      setDdnValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (resetAll) {
      setDdnValue(itemsDdn);
    }
  }, [resetAll]);

  return (
    <FormControl variant="outlined" className={classes.formControl} error={error} fullWidth={fullWidth}>
      {label && <InputLabel id={`${id}-${label}`}>{label}</InputLabel>}
      <Select
        {...rest}
        displayEmpty
        fullWidth={fullWidth}
        className={`${className || ""} ${isOpen ? "open " : ""}${label ? "hasLabel " : ""}`}
        ref={anchorEl}
        labelId={label ? `${id}-${label}` : ""}
        multiple={multiple}
        value={ddnValue}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={close}
        onChange={(event: any) => handleDdnChange(event)}
        renderValue={getChips}
        IconComponent={hideIconComponent ? () => <></> : downArrowIcon}
        MenuProps={{
          disablePortal: true,
          classes: { list: menuPropsList || classes.menuPropsList, paper: menuPaperProps || classes.customMenuPropsPaper },
          autoFocus: false,
          anchorEl: anchorEl.current,
          getContentAnchorEl: null,
          anchorOrigin: anchorOrigin || anchorOriginDefault,
        }}
      >
        {enableSearch && (
          <ListSubheader className={`${classes.searchBox} ${customWidthSearchBar && classes.customWidthSearch}`} onClickCapture={stopImmediatePropagation} onKeyDown={(e) => e.stopPropagation()}>
            <TextField
              variant="outlined"
              size="small"
              value={searchText}
              autoFocus
              placeholder="Type to search..."
              fullWidth
              className="text-search"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" size="small">
                      <SvgIcon component={searchIcon} viewBox="0 0 16 16" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </ListSubheader>
        )}
        {(!searchText || searchText.length === 0) && multiple && (
          <MenuItem className="selectAll" key={AppConstants.SELECT_ALL.value} value={AppConstants.SELECT_ALL.value}>
            <Checkbox size="small" color="primary" checked={checkerStringArr(itemsDdn, ddnValue)} />
            <ListItemText primary={AppConstants.SELECT_ALL.name} />
          </MenuItem>
        )}
        <CustomHubMenuItems classes={classes} customCountryRecord={customCountryRecord} items={items} multiple={multiple} searchText={searchText} handleDdnChange={handleDdnChange} ddnValue={ddnValue} />
      </Select>
    </FormControl>
  );
};

export default React.memo(CustomHubSelctBox);
